import React from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { emailLogInConfirm } from '../../redux/auth/actions'
import { chkEmail }  from '../../includes/functions'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

class Register extends React.Component {
  state = {
    email: window.localStorage.getItem('emailForSignIn'),
    type: this.props.match.params.type,
    message: null,
    btnText: 'processing...',
    btnDisabled: true
  }
  
  componentDidMount = () => {
    this.logInUser()
  }

  handleChange = (e) => {
    this.setState({[e.target.id]: e.target.value, message: null})
  }
  
  handleSubmit = (e) => {
    e.preventDefault()
    if (!chkEmail(this.state.email)) {
      return this.setState({ message: 'Please enter your email address to confirm.', btnText: 'Confirm', btnDisabled: false })
    }
    this.logInUser()
  }
  
  logInUser = () => {
    window.localStorage.removeItem('emailForSignIn')
    let userEmail = this.state.email
    
    // check url for user email address
    let qs = queryString.parse(this.props.location.search)
    let email = (qs.email) ? qs.email : null

    // if user email supplied use it
    if (email) {
      userEmail = email 
    }

    this.props.emailLogInConfirm(userEmail, window.location.href)
    .catch((err) => {
      this.setState({ message: err, btnText: 'Confirm', btnDisabled: false }) 
    })    
  }

  render() {
    const { message, btnText, btnDisabled } = this.state
    return (
      <Container maxWidth='xl' style={{ padding: 10 }}>
        <Grid container justify='center' spacing={2}>
          <Grid item xs={12} sm={5}>
            <Paper style={{ padding: 20 }} >
              <Text variant='h5' gutterBottom>
                CONFIRM EMAIL ADDRESS
              </Text>
              <Text variant='caption' component='p'>
                Enter your email address to confirm
              </Text>
              <Text style={{ marginTop: 10, color: 'red', height: 50 }} variant='caption' component='p' align='center'>
                { message }
              </Text>
              <form onSubmit={ this.handleSubmit }>
                <TextField
                  type='text'
                  id='email'
                  label='Email'
                  placeholder='Enter your email address'
                  margin='normal'
                  fullWidth={true}
                  onChange={ this.handleChange }
                />
                <Button disabled={ btnDisabled } variant='contained' color='primary'  type='submit'>
                  { btnText }
                </Button>
              </form>
           </Paper>
          </Grid>
        </Grid>  
      </Container>
    )
  }
}
const mapDispatchToProps = {
  emailLogInConfirm
}
export default connect(null, mapDispatchToProps)(Register)
