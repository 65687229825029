import React from 'react'
import { withRouter } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Badge from '@material-ui/core/Badge'
import Divider from '@material-ui/core/Divider'
import Hidden from '@material-ui/core/Hidden'
import IconFilterList from '@material-ui/icons/FilterList'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Text from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'

function SectionTopNav(props){
	const [anchorEl, setAnchorEl] = React.useState(null)

	const handleChange = (value) => {
		setAnchorEl(null)
		let path = '/member/support/' + value
		props.history.push({ pathname: path })
	}
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}    
	const handleClose = () => {
		setAnchorEl(null)
	}
	const handleFullMenu = () => {
		setAnchorEl(null)
		props.sideNavToggle()
	}
	const section = props.section
	return (
		<Toolbar variant="dense" style={{ paddingLeft:5, display: 'flex', borderBottom: '2px solid #ccc'}}>
			<Hidden smUp>
				<Button size="small" onClick={handleClick}>
               <Badge style={{ marginRight: 10 }} badgeContent={props.openTicketsCount} color='secondary'>
               	<IconFilterList style={{marginRight:5}}/> {section.replace(/-/g, ' ').replace(/\+/g, ' & ')}
            	</Badge>
            </Button>
			</Hidden>
			<Menu
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem selected={section === 'New-Ticket'} onClick={() => {handleChange('New-Ticket')}}>New Ticket</MenuItem>
				<MenuItem selected={section === 'Open'} onClick={() => {handleChange('Open')}}>Open</MenuItem>
				<MenuItem selected={section === 'Closed'} onClick={() => {handleChange('Closed')}}>Closed</MenuItem>
				<Divider/>
				{props.ticketGroups.map((option) => (
               <MenuItem 
                   key={option}
                   selected={option === section}
                   onClick={() => {handleChange(option)}}
               >{option.replace(/-/g, ' ')}</MenuItem>
           	))}
           	<Divider />
				<MenuItem onClick={handleFullMenu}>View Full Menu</MenuItem>
			</Menu>
			<Hidden xsDown>
				<Text variant='h6' color='primary' style={{paddingLeft:5}}>{section.replace(/-/g, ' ').toUpperCase()}</Text>
			</Hidden>
		</Toolbar>
	)
}
export default withRouter(SectionTopNav)
