import React from 'react'
import moment from 'moment'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

import AppBar from '@material-ui/core/AppBar'
//import Button from '@material-ui/core/Button'
import Toolbar from '@material-ui/core/Toolbar'

import IconButton from '@material-ui/core/IconButton'
import IconHome from '@material-ui/icons/Apps'
import IconRightArrow from '@material-ui/icons/KeyboardArrowRight'
import IconEvent from '@material-ui/icons/Event'

class GroupScannerEvent extends React.Component {
  	
  	render() {	
		const date = 1569290657
		//let startDate = moment.unix(date).format('Do MMM')

	    return (
	        <Container maxWidth='lg' style={{ padding: 10, marginBottom: 150 }}>
	        <Grid container justify='center' spacing={2}>

	            {/* [START]: App Bar ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */}
	            <Grid item xs={12} sm={12} md={10} >
                <AppBar position='static' color='default'>
                    <Toolbar variant="dense">
                        <IconButton onClick={() => {this.props.history.push({ pathname: '/group/scanner' })}}>
                            <IconHome color='primary'/>
                        </IconButton>
                        <IconRightArrow color='primary' style={{marginLeft:-10, marginRight:-10}}/>
                        <IconButton onClick={() => {this.props.history.push({ pathname: '/group/scanner/event-checkin' })}}>
                            <IconEvent color='primary'/>
                        </IconButton>
                        <IconRightArrow color='primary' style={{marginLeft:-10}}/>
                        <div style={{textAlign: 'center', lineHeight:1.15}}>{moment.unix(date).format('Do')}<br/><b>{moment.unix(date).format('MMM').toUpperCase()}</b></div>
                        <div style={{borderLeft: '2px solid #cc0000', marginLeft:6, paddingLeft:6}}>
                        <Text variant='h6' color='primary' style={{lineHeight:1.15}}>
                            <b>weLoveJouvert.com</b>
                        </Text>
                        </div>
                    </Toolbar>
                </AppBar> 
            </Grid>
	            {/* [END]: App Bar ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */}

	            <Grid item xs={12} sm={12} md={10} >
	                <Paper style={{ padding: 20 }} >
	                    <Text variant='h5' gutterBottom>
	                        GROUP SCANNER INFO
	                    </Text>
	                    <Text variant='caption' gutterBottom>
	                        Event Details
	                    </Text>
	                </Paper>
	            </Grid>
	        </Grid>
	        </Container>
	    )
	}
}
export default GroupScannerEvent
