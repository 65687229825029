import React from 'react'

import IconClose from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function Crews(props){
	const [showMessage, setShowMessage] = React.useState(true)
	
	if (props.roleId > 2) {
		return (
			<Paper style={{ padding: 20, marginTop:20, textAlign:'center'}} >
				<Text>Restricted Access</Text>
       		<Text style={{lineHeight:.8}} variant='caption' display='block' color='textSecondary'>
           		Only Managers, Admins and the group Owner have access to this page. 
         	</Text>
      	</Paper>
		)
	}

	return (
		<React.Fragment>
			{(showMessage) ? (
			<div style={{ display: 'flex', padding: 10, backgroundColor: 'rgba(255,193,7,.08)', borderLeft: '2px solid #ffc107' }}>
				<span style={{flexGrow: 1}}>
					<Text style={{marginBottom: 0, lineHeight:1.5}} variant='overline' display='block' gutterBottom>
						<div>
							This is your group Crews. Break your Team into smaller Crews to work on projects.
							Only Managers, Admins and the group Owner have access to this page.
						</div>
					</Text>
				</span>
				<span>
					<IconButton aria-label='close' size='small' onClick={() => {setShowMessage(false)}}>
						<IconClose style={{color: '#c0c0c0'}} />
					</IconButton>
				</span>
			</div> 
			) : ( null )}
		</React.Fragment>
	)
}
export default Crews
