import React from 'react'
import moment from 'moment'
import Swal from 'sweetalert2'
import {  withRouter } from 'react-router-dom'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import IconArrowBack from '@material-ui/icons/ArrowBackIos'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

function MemberSupportTicket(props) {
	const ticket = props.tickets[props.ticketId]
	const [post, setPost] = React.useState('')
	const [message, setMessage] = React.useState('')
	const [disabled, setDisabled] = React.useState(false)
	const [closeText, setCloseText] = React.useState('Close this Ticket')
	let pendingResponse = true

	const goToList = () => {
		let url = '/member/support/' + props.section
		props.history.push({ pathname: url })
	}
	if(!ticket){ goToList() }

	const handleChange = (e) => {
		setMessage('')
		setPost(e.target.value)
	}
	const updateTicket = () => {
		if(post === ''){
			return setMessage('Please enter your post.')
		}
		setDisabled(true)
		setMessage('Processing...')
		props.updateTicket(props.ticketId, post)
		.then(() => {
			setDisabled(false)
			setMessage('')
			setPost('')
		})
		.catch((error) => {
			setDisabled(false)
			setMessage(error)
		})
	}

	const closeTicket = () => {
		Swal.fire({
			title: 'Close Ticket',
			text: 'Are you sure you want to close this ticket?',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, close it!'
		}).then((result) => {
			if (result.value) {
				setDisabled(true)
				setCloseText('Closing Ticket...')
				props.closeTicket(props.ticketId)
				.then(() => {
					setDisabled(false)
				})
				.catch((error) => {
					setDisabled(false)
					setMessage(error)
				})
			}
		})	
	}    
	return (
		<React.Fragment>
			<div align="right" style={{marginTop:5}} >
				<Button onClick={goToList}>
					<IconArrowBack /> Back to List
				</Button>
			</div>
			<Paper style={{marginTop:5, padding: 5}} >
				<List>
					<ListItem>
						<ListItemAvatar>
							<Avatar alt={ticket.groupName} src={ticket.groupThumb} style={{borderRadius:5}} />
						</ListItemAvatar>
						<ListItemText>
							<Text variant='h6'>{ticket.groupName}</Text>
							<Text style={{lineHeight:.8}} variant='caption' display='block' color='textSecondary'>
								Submitted on {moment.unix(ticket.timestamp.seconds).format('MMM Do YYYY - h:mm a')}
							</Text>
							<Text style={{marginTop:5, lineHeight:1, color:'#546e7a'}}><b>{ticket.subject}</b></Text>
						</ListItemText>
						<ListItemSecondaryAction>
							{(ticket.closed) ? (
								<Text style={{lineHeight:1, color:'red'}}><b>CLOSED</b></Text>
							):(
								<Text style={{lineHeight:1, color:'#27e007'}}><b>OPEN</b></Text>
							)}
						</ListItemSecondaryAction>
					</ListItem>
				</List>
			</Paper>

			<Paper style={{marginTop:20, padding: 5}} >
				<List>
					{Object.keys(ticket.thread).map((i, key) => {
						let t = ticket.thread[i]
						pendingResponse = (ticket.memberId === t.postedById) ? true : false
						return (
							<div key={key}>
								<ListItem alignItems="flex-start" style={{paddingBottom:50}}>
									<ListItemAvatar>
										<Avatar alt={t.postedByName} src={t.postedByThumb} style={{borderRadius:5}} />
									</ListItemAvatar>
									<ListItemText>
										{t.postedByName}
										<Text style={{lineHeight:.8}} variant='caption' display='block' color='textSecondary'>
											{moment.unix(t.timestamp.seconds).format('MMM Do YYYY - h:mm a')}
										</Text>
										<Text style={{marginTop:15, lineHeight:1, color:'#546e7a'}}>{t.comment}</Text>
									</ListItemText>
								</ListItem>
								<Divider/>
							</div>
						)
					})}

					{(!ticket.closed && pendingResponse) ? (
						<div align="center" style={{padding:20}}>
							<Text variant="h6" style={{color:'red'}}><i>Pending Response</i></Text>
						</div>
					):( null )}

					{(!ticket.closed) ? (
					<div style={{padding:20, textAlign:'center'}}>
						<TextField
							style={{width:'100%', margin:'20px auto'}}
							id="post"
							label="Post"
							multiline
							rows={4}
							variant="outlined"
							value={post}
                		onChange={handleChange}
						/>
						<div style={{textAlign:'center'}}><Text variant="overline" style={{color:'red'}}>{message}</Text></div>
						<Button disabled={disabled} onClick={updateTicket} variant="contained" color="secondary" style={{marginBottom:20}}>Submit</Button>
						<Divider/>
					</div>
					):( 
						<div align="center" style={{padding:20}}>
							<Text variant="h6" color="textSecondary"><i>This ticket was closed by {ticket.closedByName}</i></Text>
							<Text style={{lineHeight:.8}} variant='caption' display='block' color='textSecondary'><i>
								{(ticket.closedTimestamp) ? moment.unix(ticket.closedTimestamp.seconds).format('MMM Do YYYY - h:mm a') : null}
							</i></Text>
						</div>
					)}
					<div style={{margin:5,textAlign:'right'}}>
						{(ticket.closed) ? (
							<Text style={{margin:10, color:'red'}}><b>CLOSED</b></Text>
						):(
							<Button onClick={closeTicket} disabled={disabled}>{closeText}</Button>
						)}
					</div>

				</List>
			</Paper>
		</React.Fragment>
	)
}
export default withRouter(MemberSupportTicket)
