import React from 'react'
import Swal from 'sweetalert2'
//import moment from 'moment'
//import MomentUtils from '@date-io/moment'
//import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers"

import { makeStyles } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
//import IconEvent from '@material-ui/icons/Event'
//import InputAdornment from '@material-ui/core/InputAdornment'
//import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Text from '@material-ui/core/Typography'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
//import IconClose from '@material-ui/icons/Close'
import IconPerson from '@material-ui/icons/Person'
import IconDelete from '@material-ui/icons/Delete'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
//import DialogTitle from '@material-ui/core/DialogTitle'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
//import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
//import ListItemText from '@material-ui/core/ListItemText';
//import ListSubheader from '@material-ui/core/ListSubheader';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
  	padding:0, margin:0, marginTop:10
  },
  mySwal: {
    zIndex: 10000
  }
}))

const initialState = {
	guestId: '',
	guests: 0,
	guestOfId: '',
	guestOfName: '',
	firstName: '',
	lastName: '',
	gender: '',
	highlight: '',
	note: '',
	email: '',
	cell: '',
	company: '',
	title: '',
	notify: false
}

function ManageGuestAdd(props){
	const classes = useStyles()
	const [unknownButton, setUnknownButton] = React.useState('add unknown guest')
	const [saveButton, setSaveButton] = React.useState('save')
	const [disableButton, setDisableButton] = React.useState(false)
	const [openMore, setOpenMore] = React.useState(false)
	const [message, setMessage] = React.useState('')
	const [values, setValues] = React.useState({
		guestId: (props.guest) ? props.guest.guestId : '',
		guests: (props.guest) ? props.guest.guests : 0,
		guestOfId: props.guestOfId,
		guestOfName: props.guestOfName,
		firstName: (props.guest) ? props.guest.firstName : '',
		lastName: (props.guest) ? props.guest.lastName : '',
		gender: (props.guest) ? props.guest.gender : '',
		highlight: (props.guest) ? props.guest.highlight : '',
		note: (props.guest) ? props.guest.note : '',		
		email: (props.guest) ? props.guest.email : '',
		cell: (props.guest) ? props.guest.cell : '',
		company: (props.guest) ? props.guest.company : '',
		title: (props.guest) ? props.guest.title : '',	
		notify:	 (props.guest) ? props.guest.notify : false	
  	})

  	const handleChange = name => event => {
    	setValues({ ...values, [name]: event.target.value });
  	}

  	const handleCancel = () => {
  		if(!props.guest){
  			setValues(initialState)
  		}  		
  		props.toggle(false)
  	}

  	const handleSaveUpdate = () => {
  		if (values.firstName === '' || values.lastName === '') {
  			return setMessage('First Name and Last Name Required.')	
  		}
  		setMessage('saving guest')
  		setDisableButton(true)
  		setUnknownButton('updating')
  		setSaveButton('updating')
  		props.guestlistAddEdit(props.eventId,values)
  		.then(() => {
  			setValues(initialState)
  			setMessage('')
  			setDisableButton(false)
  			setUnknownButton('add unknown guest')
  			setSaveButton('save')
  			props.toggle(false)
  		})
  		.catch((error) => {
  			console.log(error)
  		})
  	}
  	const handleSaveUnknown = () => {
  		let unknownValues = values
  		unknownValues.firstName = 'guest'
  		unknownValues.lastName = props.guestsTotal + 1
  		console.log(unknownValues)
  		setMessage('saving guest')
  		setDisableButton(true)
  		setUnknownButton('updating')
  		setSaveButton('updating')
  		props.guestlistAddEdit(props.eventId,values)
  		.then(() => {
  			setValues(initialState)
  			setMessage('')
  			setDisableButton(false)
  			setUnknownButton('add unknown guest')
  			setSaveButton('save')
  			props.toggle(false)
  		})
  		.catch((error) => {
  			console.log(error)
  		})
  	}
  	const handleDelete = () => {
  		Swal.fire({
          title: 'Delete this Guest?',
          text: 'You won\'t be able to revert this!',
          showCancelButton: true,
          confirmButtonColor: '#4CAF50',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Delete Guest',
          customClass: {container:classes.mySwal}
        }).then((result) => {
          	if (result.value) {
          		setDisableButton(true)
  				setMessage('deleting guest')
  				props.guestlistDelete(props.eventId,values.guestId,values.guestOfId)
                .then(() => {
	                setValues(initialState)
		  			setMessage('')
		  			setDisableButton(false)
		  			setUnknownButton('add unknown guest')
		  			setSaveButton('save')
		  			props.toggle(false)
	            })
	            .catch((error) => {
	            	setMessage('')
		  			setDisableButton(false)
	                Swal.fire({
	                    title: 'Sorry, Error!',
	                    text: error,
	                    confirmButtonColor: '#d33',
	                    customClass: {container:classes.mySwal}
	                })
	            })

          	}
        })
  	}

    return (
    	<Dialog open={props.open} onClose={() => {props.toggle(false)}} style={{margin:0, padding:0}}>
    		<AppBar style={{ position: 'relative', backgroundColor:'#cccccc' }} >
                <Toolbar>
                    <IconPerson style={{ marginRight: 5 }} />
                    <Text variant='subtitle2' color='inherit' style={{ flex: 1 }}>
                        {props.title}
                    </Text>
    				{(values.guestId !== '') ? (
	                    <IconButton color='inherit' disabled={disableButton} onClick={() => {handleDelete()}}>
	                        <IconDelete />
	                    </IconButton>
                    ) : (null)}
                </Toolbar>
            </AppBar>

    		<DialogContent style={{padding:10}}>
		      	<Grid container justify='center' spacing={2}>
		      		{(props.title === 'Add Guest' && props.guestOfId) ? (
		      		<Grid item xs={12} sm={12} md={12} style={{paddingTop:0, paddingBottom:0, textAlign: 'center'}}>
						<Button onClick={() => {handleSaveUnknown()}} variant='contained' color='primary' style={{margin:10, width: '70%'}} disabled={disableButton}>{unknownButton}</Button>
						<div style={{padding:1, margin:10, textAlign:'center', backgroundColor: '#f1f1f1'}}><Text variant='overline'>or enter guest details below</Text></div>
		      		</Grid>
		      		) : (null)}
		      		<Grid item xs={6} sm={6} md={6} style={{paddingTop:0, paddingBottom:0}}>
						<TextField
					      	fullWidth
					        label='First Name'
					        className={classes.textField}
					        value={values.firstName}
					        onChange={handleChange('firstName')}
					        margin='dense'
					        variant='outlined'
				      	/>
		      		</Grid>
		      		<Grid item xs={6} sm={6} md={6} style={{paddingTop:0, paddingBottom:0}}>
						<TextField
					      	fullWidth
					        label='Last Name'
					        className={classes.textField}
					        value={values.lastName}
					        onChange={handleChange('lastName')}
					        margin='dense'
					        variant='outlined'
				      	/>
		      		</Grid>
					<Grid item xs={6} sm={6} md={6} style={{paddingTop:0, paddingBottom:0}}>
						<TextField
					    	fullWidth
					        select
					        label='Gender'
					        className={classes.textField}
					        value={values.gender}
					        onChange={handleChange('gender')}
					        margin='normal'
					        variant='outlined'
					    >
					    	<MenuItem value=''>Gender</MenuItem>
					    	<MenuItem value='Female'>Female</MenuItem>
					        <MenuItem value='Male'>Male</MenuItem>						        
					    </TextField>							
		      		</Grid>
		      		<Grid item xs={6} sm={6} md={6} style={{paddingTop:0, paddingBottom:0}}>
						<TextField
					    	fullWidth
					        select
					        label='Highlight'
					        className={classes.textField}
					        value={values.highlight}
					        onChange={handleChange('highlight')}
					        margin='normal'
					        variant='outlined'
					    >
					        <MenuItem value=''>None</MenuItem>
					        <MenuItem value='VIP'>VIP</MenuItem>
					        <MenuItem value='Staff'>Staff</MenuItem>
					        <MenuItem value='Media'>Media</MenuItem>
					        <MenuItem value='Official'>Official</MenuItem>
					        <MenuItem value='Partner'>Partner</MenuItem>
					        <MenuItem value='Sponsor'>Sponsor</MenuItem>
					    </TextField>
		      		</Grid>
		      		<Grid item xs={12} sm={12} md={12} style={{paddingTop:0, paddingBottom:0}}>
			      		<TextField
					      	fullWidth
					        label='Note'
					        className={classes.textField}
					        value={values.note}
					        onChange={handleChange('note')}
					        margin='dense'
					        variant='outlined'
				      	/>
				    </Grid>
				    <Grid item xs={12} sm={12} md={12} style={{paddingTop:0}}>
				    	<Collapse in={openMore}>
				    {/* --------------------------------------------------------------------------- */}
					      	<TextField
						      	fullWidth
						        label='Email'
						        className={classes.textField}
						        value={values.email}
						        onChange={handleChange('email')}
						        margin='dense'
						        variant='outlined'
					      	/>
					      	<TextField
						      	fullWidth
						        label='Cell'
						        className={classes.textField}
						        value={values.cell}
						        onChange={handleChange('cell')}
						        margin='dense'
						        variant='outlined'
					      	/>
					      	<TextField
						      	fullWidth
						        label='Company'
						        className={classes.textField}
						        value={values.company}
						        onChange={handleChange('company')}
						        margin='dense'
						        variant='outlined'
					      	/>
					      	<TextField
						      	fullWidth
						        label='Title'
						        className={classes.textField}
						        value={values.title}
						        onChange={handleChange('title')}
						        margin='dense'
						        variant='outlined'
					      	/>
				    {/* --------------------------------------------------------------------------- */}
				    	</Collapse>
						<div style={{textAlign: 'center', padding: 10}}>
			            	<Text variant='overline' style={{ color: 'red' }}>
			              		{message}
			                </Text>
			            </div>
				    	<List style={{margin:0, padding:0, backgroundColor:'#f2f2f2'}}>
				    		<ListItem>
						          <IconButton onClick={() => {setOpenMore(!openMore)}}>
						          	{openMore ? <ExpandLess /> : <ExpandMore />}
						          </IconButton>
				    			
		            			<ListItemSecondaryAction>
		              				<Button size='small' onClick={handleCancel}>Cancel</Button>
        							<Button size='small' onClick={handleSaveUpdate} variant='contained' color='primary' disabled={disableButton}>{saveButton}</Button>
		            			</ListItemSecondaryAction>
				    		</ListItem>
				    	</List>
				    </Grid>

		      	</Grid>
			</DialogContent>
            <DialogActions><Button onClick={() => {props.toggle(false)}}>close</Button></DialogActions>
        </Dialog>
    )
}
export default ManageGuestAdd
