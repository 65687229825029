import React from 'react'
import { Route, Switch } from 'react-router-dom'

import PublicSidenav from './components/PublicSidenav'
import PublicTopnav from './components/PublicTopnav'
import Home from './views-public/Home'
import About from './views-public/About'
import Contact from './views-public/Contact'
import GetTheApp from './views-public/GetTheApp'
import HowItWorks from './views-public/HowItWorks'
import Login from './views-public/Login'
import Email from './views-public/Email'
import EmailConfirm from './views-public/EmailConfirm'
import PageNotFound from './views-public/PageNotFound'
import Templates from './views-public/templates'

class LayoutPublic extends React.Component {
	state = { sideNavOpen: false }

	sideNavToggle = () => {
		this.setState({sideNavOpen: !this.state.sideNavOpen})
	}

	render(){
		return (
			<React.Fragment>
				<PublicTopnav sideNavToggle={this.sideNavToggle} />
				<nav>
					<PublicSidenav sideNavToggle={ this.sideNavToggle } sideNavOpen={ this.state.sideNavOpen } />
				</nav>
				<main>
					<Switch>
						<Route path='/' exact component={Home}/>
						<Route path='/about' component={About}/>
						<Route path='/contact' component={Contact}/>
						<Route path='/gettheapp' component={GetTheApp}/>
						<Route path='/howitworks' component={HowItWorks}/>
						<Route path='/login' component={Login}/>
						<Route path='/email/confirm/:type?' component={EmailConfirm}/>
						<Route path='/email/:type' component={Email}/>
						<Route path='/templates' component={Templates}/>
						<Route path='/' component={PageNotFound}/>
					</Switch>
				</main>
			</React.Fragment>
		)
	}
}
export default LayoutPublic
