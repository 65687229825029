import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Text from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  	thumb: {
		width: 75,
		height: 75, 
		verticalAlign: 'top', 
		marginRight: 10,
		border: '1px solid #ccc', 
		borderRadius: 7
  	},
  		cardContent: {
		display: 'flex',
		padding: 5,
		
  	}
}))

function Step4(props) {
  	const classes = useStyles()
  	const [message, setMessage] = React.useState('Save & Create Group')
  	const [disabled, setDisabled] = React.useState(false)

  	const createGroup = () => {
	 	setMessage('Creating Group...')
	 	setDisabled(true)
	 	props.handleCreateGroup(function () {
		  	setMessage('Save & Create Group')
		  	setDisabled(false)		  	
	 	})
  	}

  	return (
		<div>
			<div align='center' style={{ maxWidth: 440, margin: '0 auto', marginBottom: 30, borderBottom: '1px solid #ccc' }}>
				<img src={props.group.headerImage} style={{ width: '100%' }} alt='' /><br />
				<Text variant='caption' style={{color: '#d1d1d1'}}>
				  	<i>This is the header image for your group.</i>
				</Text>  
			</div>
			<div>
				<Card>
					<CardContent className={classes.cardContent}>
						<span><img src={props.group.profileImage} className={classes.thumb} alt="Logo" /></span>
						<span>
							<b>{props.group.name}</b>
							<Text variant="caption" display="block" color="textSecondary" ><b>Group Type:</b> {props.group.type}</Text>
							<Text variant="caption" display="block" color="textSecondary" ><b>Status:</b> Approved</Text>
							<Text variant="caption" display="block" color="textSecondary" ><b>Role:</b> Owner</Text>
						</span>
					</CardContent>
				</Card>
				<div align='right'>
				 	<Text variant='caption' style={{color: '#d1d1d1'}}>
						<i>Group profile card.</i>
					</Text>
				</div> 
			</div>
			<div align='center' style={{ padding: 30 }}>
				<Button fullWidth style={{ maxWidth: 400, marginBottom: 15 }} disabled={disabled} variant='outlined' color='primary' onClick={props.handleBack}>Edit Group Info</Button>
				<Button fullWidth style={{ maxWidth: 400, marginBottom: 15 }} disabled={disabled} variant='contained' color='primary' onClick={createGroup}>
				  	{message}
				</Button>
			</div>
		</div>
	)
}
export default Step4
