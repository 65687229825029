import React from 'react'
import Swal from 'sweetalert2'
import TeamAbout from './TeamAbout'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Divider from '@material-ui/core/Divider'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function Landing({data, acceptRole}){
	const [showInfo, setShowInfo] = React.useState(false)
	const confirmReject = () => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this.",
			type: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
  			cancelButtonColor: "#d33",
  			confirmButtonText: "Yes, reject offer",
		}).then((result) => {
		  	if (result.value) {
				acceptRole(0)
		   }
		})
	}
	if(!data){return null}
	return (
		<React.Fragment>
			<Container maxWidth='xl' style={{ padding: 10, marginBottom:150 }}>
			<Grid container justify='center' spacing={2}>
				<Grid item xs={12} sm={8} md={8} >
					<Paper style={{ padding: 20 }} >
						<Grid container justify='center'>
							<Grid item xs={12} sm={5} style={{ padding: 5, textAlign:'center' }}>
								<img
									style={{ margin:'0 auto', width: '100%', maxWidth:300, padding: 5, borderRadius: 10, border: '1px solid #ccc' }} 
									src={data.groupThumb} 
									alt={data.groupName} 
								/>
							</Grid>
							<Grid item xs={12} sm={7} style={{ padding: 5, marginTop:20}}>
								<Text variant="h6">
									{data.groupName.toUpperCase()} would like you to join their team as a {data.role.toUpperCase()}.
								</Text>
								<div align="right">
									<Text variant="caption" style={{color:'#999'}}><i>Invited by {data.listedBy}</i></Text>
								</div>
								<Divider style={{margin:5}} />
								<div style={{marginTop:20}}>
									<Button onClick={() => { confirmReject() }} variant="outlined" size="small" color="primary" style={{margin:15}}>No Thanks</Button>
									<Button onClick={() => { acceptRole(1) }} variant="contained" size="small" color="secondary" style={{margin:15}}>Accept</Button>
								</div>
							</Grid>
						</Grid>
						<div align="right">
							<Button onClick= {() => {setShowInfo(true)} }><Text variant="caption" style={{color:'#999'}}>Learn More</Text></Button>
						</div>
					</Paper>
				</Grid>
			</Grid>
			</Container>

			<Dialog open={showInfo} onClose={() => {setShowInfo(false)}}>
				<DialogContent>
					<TeamAbout />
				</DialogContent>
				<DialogActions>
					<Button onClick={() => {setShowInfo(false)}} color='primary' autoFocus>
					   Close
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	)
}
export default Landing
