import React from 'react'

import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function GroupCommunicateView() {
	return (
		<Paper style={{ padding: 20, marginTop:20, marginBottom:150 }} >
			<Text variant='h5' gutterBottom>
				VIEW
			</Text>
		</Paper>
	)
}
export default GroupCommunicateView
