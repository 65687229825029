import React from 'react'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'

function GroupScannerSummary(props) {
	return (
		<Container maxWidth='lg' style={{ padding: 10, marginBottom: 150 }}>
		<Grid container justify='center' spacing={2}>

			{/* [START]: App Bar ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */}
			<Grid item xs={12} sm={12} md={10} >
				<AppBar position='static' color='default'>
					<Toolbar variant="dense">
						<Button>
							<Text variant='h6' color='primary' style={{ paddingRight: 15 }}>
								<b>Scanner</b>
							</Text>
						</Button>
					</Toolbar>
				</AppBar> 
			</Grid>
			{/* [END]: App Bar ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */}

			<Grid item xs={12} sm={12} md={10} >
				<Paper style={{ padding: 20 }} >
					<Text variant='caption' gutterBottom>
						<p>
							<Button size='large' onClick={() => {props.history.push({ pathname: '/group/scanner/event-checkin' })}}>Event CheckIn</Button>
						</p>
					</Text>
				</Paper>
			</Grid>
		</Grid>
		</Container>
	)
}
export default GroupScannerSummary
