import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import swal from 'sweetalert2'
import Loader from '../../../components/Loader'
import { chkEmail }  from '../../../../includes/functions'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Text from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

function AddMembersSingle(props) {
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [email, setEmail] = useState('')
	const [message, setMessage] = useState('')
	const [submitButtonText, setSubmitButtonText] = useState('Add Member')
	const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
	const [loading, setLoading] = useState(false)
	const [complete, setComplete] = useState(false)
	const [newUser, setNewUser] = useState('MEMBER ADDED')

	const handleChange = (set, value) => {
		set(value)
		setMessage('')
	}

	const addTeamMember = () => {
		if(firstName === '' || lastName === '' || email === '') {
			setMessage('Sorry, all fields are required.')
			return false
		}
		if (!chkEmail(email)) {
			setMessage('Please enter a valid email address.')
			return false 
		}

		setSubmitButtonText('updating...')
		setSubmitButtonDisabled(true)
		setLoading(true)
		let list = firstName + ',' + lastName + ',' + email
		props.addMembers('single', 'individual', list)
			.then((newUser) => {
				if (!newUser) {
					setNewUser('MEMBER ALREADY EXISTS')
				}
				setComplete(true)
				setLoading(false)
			})
			.catch((error) => {
				setLoading(false)
				setSubmitButtonDisabled(false)
				setSubmitButtonText('Add Member')
				swal.fire({
					position: 'top-end',
					type: 'error',
					title: 'sorry there was an error: ' + error,
					showConfirmButton: false,
					timer: 2000
				})
			})
	}

	const addAnotherMember = () => {
		setFirstName('')
		setLastName('')
		setEmail('')
		setMessage('')
		setSubmitButtonText('Add Member')
		setSubmitButtonDisabled(false)
		setLoading(false)
		setComplete(false)
	}

	const goToMembers = () => {
		let path = '/group/members/Members'
      props.history.push({ pathname: path })
	}

	return (
		<React.Fragment>
			<Loader loading={loading} />
			{(complete) ? (
				<div style={{ margin: '0 auto', textAlign:'center' }}>
					<Text variant='h6' gutterBottom>
						{newUser}
					</Text>				
					<Button color='primary' variant='outlined' onClick={() => {goToMembers()}} style={{margin:15}}>
						view members		
					</Button>
					<Button color='secondary' variant='contained' onClick={() => {addAnotherMember()}} style={{margin:15}}>
						add another members		
					</Button>
				</div>
			) : ( 
				<div style={{ margin: '0 auto' }}>
					<Text variant='h5' gutterBottom>
						ADD MEMBER
					</Text>
					<Grid container justify='center'>
						<Grid item xs={12} sm={6} style={{ padding: 5 }}>
							<TextField fullWidth
								label='First Name'
								name='firstName'
								value={firstName}
								onChange={(e) => {handleChange(setFirstName, e.target.value)}}
								margin='normal'
							/>
						</Grid>
						<Grid item xs={12} sm={6} style={{ padding: 5 }}>
							<TextField fullWidth
								label='Last Name'
								name='lastName'
								value={lastName}
								onChange={(e) => {handleChange(setLastName, e.target.value)}}
								margin='normal'
							/>
						</Grid>
						<Grid item xs={12} style={{ padding: 5 }}>
							<TextField fullWidth
								label='Email Address'
								name='email'
								value={email}
								onChange={(e) => {handleChange(setEmail, e.target.value)}}
								margin='normal'
							/>
						</Grid>
					</Grid>
					<div align='center' style={{ height: 25 }}>
						<Text variant='caption' style={{ color: 'red' }}>
							{message}
						</Text>
					</div>
					<div align='right'>
						<Button variant='contained' color='secondary' disabled={submitButtonDisabled} onClick={() => {addTeamMember()}}>
							{submitButtonText}
						</Button>
					</div>
				</div>
			)}	
		</React.Fragment>
	)
}
export default withRouter(AddMembersSingle)
