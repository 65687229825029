import React from 'react'

function SectionSideNav(props){
	return (
		<div>
			Side Column
		</div>
	)
}
export default SectionSideNav
