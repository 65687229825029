import React from 'react'
import AddMembersSingle from './components/AddMembersSingle'
import AddMembersMultiple from './components/AddMembersMultiple'
import AddMembersUpload from './components/AddMembersUpload'

import { makeStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IconButton from '@material-ui/core/IconButton'
import IconClose from '@material-ui/icons/Close'
import Text from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
   secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary,
	}
}))

function GroupMembersNew(props){
	const classes = useStyles()
	const [expanded, setExpanded] = React.useState(false)
	const [showMessage, setShowMessage] = React.useState(true)

	const handleChange = panel => (event, isExpanded) => {
	   setExpanded(isExpanded ? panel : false);
	}

	return (
		<React.Fragment>
			{(showMessage) ? (
				<div style={{ display: 'flex', padding: 10, backgroundColor: 'rgba(255,193,7,.08)', borderLeft: '2px solid #ffc107' }}>
					<span style={{flexGrow: 1}}>
						<Text style={{marginBottom: 0, lineHeight:1.5}} variant='overline' display='block' gutterBottom>
							this action <u><b>would not</b></u> send emails to the addresses you enter here. they would only
							be notified if they have, or when they create a Loyaltt account.
						</Text>
					</span>
					<span>
						<IconButton aria-label='close' size='small' onClick={() => {setShowMessage(false)}}>
						   <IconClose style={{color: '#c0c0c0'}} />
						</IconButton>
					</span>
				</div> 
			) : ( null )} 

			<div style={{marginTop:20}} >
				<ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
					<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
						<Text>
						   <b>Add One</b><br/>
							<span className={classes.secondaryHeading}>Add one member at a time. Requires their full name and email address.</span>
						</Text>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<AddMembersSingle addMembers={props.addMembers} />
					</ExpansionPanelDetails>
				</ExpansionPanel>

				<ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
					<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
						<Text>
							<b>Add Multiple</b><br/>
							<span className={classes.secondaryHeading}>Add multiple members at a time. Requires email addresses only.</span>
						</Text>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<AddMembersMultiple addMembers={props.addMembers} />
					</ExpansionPanelDetails>
				</ExpansionPanel>

				<ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
					<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
						<Text>
							<b>Upload List</b><br/>
							<span className={classes.secondaryHeading}>Upload a file containing your members information.</span>
						</Text>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<AddMembersUpload addMembers={props.addMembers} />
					</ExpansionPanelDetails>
				</ExpansionPanel>                
			</div>
		</React.Fragment>
	)
}
export default GroupMembersNew
