import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'

import AccessDenied from '../../components/AccessDenied'
import SubNav from './components/Subnav'
import SectionSideNav from './components/SectionSideNav'
import SectionTopNav from './components/SectionTopNav'
import Invoices from './Invoices'
import PaymentOptions from './PaymentOptions'
import Plugins from './Plugins'
import Profile from './Profile'
import Verification from './Verification'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'

const whoHasAccess = [1]
class GroupSettingsContainer extends React.Component {
	render() {
		if (!this.props.roleId) return null
		if(!whoHasAccess.includes(this.props.roleId)) {
			return (<AccessDenied page='groupSettings' />)
		}
		const section = (this.props.match.params.section) ? this.props.match.params.section : 'Profile'

		return (
			<React.Fragment>
				<Container maxWidth='lg' style={{ padding: 10, marginBottom: 150, minHeight:700 }}>
					<Grid container justify='center' spacing={2}>
						<Grid item xs={12} sm={12} md={10} >
							<SubNav/>
						</Grid>
						<Hidden xsDown>
							<Grid item sm={4} md={3}>
							  <SectionSideNav section={section} sideNavToggle={ this.props.sideNavToggle }/>
							</Grid>
						</Hidden>
						<Grid item xs={12} sm={8} md={7}>
							<SectionTopNav section={section} sideNavToggle={ this.props.sideNavToggle }/>
							<Switch>
								<Route path='/group/settings/Verification' render={props => <Verification {...props} 
								  />} 
								/>
								<Route path='/group/settings/Plugins' render={props => <Plugins {...props} 
								  />} 
								/>
								<Route path='/group/settings/Payment-Options' render={props => <PaymentOptions {...props} 
								  />} 
								/>
								<Route path='/group/settings/Invoices' render={props => <Invoices {...props} 
								  />} 
								/>
								<Route path='/group/settings' render={props => <Profile {...props} 
								  />} 
								/>
							</Switch>
						</Grid>
					</Grid>
				</Container> 
			</React.Fragment>
		)
	}
}
const mapStateToProps = (state) => ({
  roleId: state.auth.loginOptions[state.auth.loginOptionID].roleId
})
const mapDispatchToProps = {
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupSettingsContainer)
