import React from 'react'
import { withRouter } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function TeamError({history, tryAgain, message}){
	const setupAccount = () => {
		history.push({ pathname: '/member/setup' })
	}
	return (
		<Container maxWidth='xl' style={{ padding: 10, marginBottom:150 }}>
		<Grid container justify='center' spacing={2}>
			<Grid item xs={12} sm={8} md={8} >
				<Paper style={{ padding: 20 }} >
					<div style={{minHeight: 150}}>
						<Text variant="h4">Error</Text>
						<Text variant="h6">
							{message}
						</Text>
					</div>
					<Divider style={{margin:5}} />
					<div style={{marginTop:20}}>
						<Button onClick={setupAccount} variant="outlined" size="small" color="primary" style={{margin:15}}>Setup my LoyalTT Account</Button>
						<Button onClick={tryAgain} variant="contained" size="small" color="secondary" style={{margin:15}}>Try Again</Button>
					</div>
				</Paper>
			</Grid>
		</Grid>
		</Container>
	)
}
export default withRouter(TeamError)
