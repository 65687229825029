export const selectorLoadPosts = (posts, type) => {
	let filteredPosts = {}
	switch(type) {
		case 'posts':
			Object.keys(posts).forEach(function (i) {
				if (posts[i].type === 'post') {
					filteredPosts[i] = posts[i]
				}
			})
			break
		case 'polls':
			Object.keys(posts).forEach(function (i) {
				if (posts[i].type === 'poll') {
					filteredPosts[i] = posts[i]
				}
			})
			break
		case 'surveys':
			Object.keys(posts).forEach(function (i) {
				if (posts[i].type === 'survey') {
					filteredPosts[i] = posts[i]
				}
			})
			break
		case 'newsletters':
			Object.keys(posts).forEach(function (i) {
				if (posts[i].type === 'newsletter') {
					filteredPosts[i] = posts[i]
				}
			})
			break
		default:
			filteredPosts = {}
	  }
	return filteredPosts
}

// https://zellwk.com/blog/looping-through-js-objects/
// https://alligator.io/js/filter-array-method/