import React from 'react'

import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

class ProfilePayments extends React.Component {
	render() {  
		return (
		   <Paper style={{marginTop:20, padding: 20, textAlign:'center' }} >
				<Text variant='caption' gutterBottom>
					PROFILE - PAYMENTS<br/>
					Under Construction
				</Text>
			</Paper>
	   )
	}
}
export default ProfilePayments
  