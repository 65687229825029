import React from 'react'
import Swal from 'sweetalert2'
import moment from 'moment'
import ImageSelector from '../../components/ImageSelector'
import Loader from '../../components/Loader'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import CardActions from '@material-ui/core/CardActions'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

class ProfilePhotos extends React.Component {
	state = {
		currentImage: null, // ImageSelector variable        
		defaultImages: ['/images/profile/default.jpg'], // ImageSelector variable
		btnDisabled: true,
		loading: false
	}

	// [START]: ImageSelector Function :::::::::
	handleSelectImage = (src) => {
	   let disabled = (src === '/images/profile/default.jpg') ? true : false
		this.setState({btnDisabled:disabled, currentImage: src})
	}
	// [END]: ImageSelector Function :::::::::::

	addPhoto = () => {
		let _this = this
		let image = this.state.currentImage
		this.setState({loading:true})
		this.props.photoAdd(image)
		.then(() => {
			_this.setState({loading:false, btnDisabled:true, currentImage: null})
			Swal.fire({
				position: 'top-end',
				type: 'success',
				title: 'Photo Updated',
				showConfirmButton: false,
				timer: 2000
			})
		})
		.catch((error) => {
			_this.setState({loading:false, btnDisabled:true, currentImage:null})    
			Swal.fire({
				position: 'top-end',
				type: 'error',
				title: 'Sorry, there was an error updating your photo',
				showConfirmButton: false,
				timer: 2000
			})
		})
	}
	deletePhoto = (photoId) => {
		let _this = this
		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
			if (result.value) {
				this.setState({loading:true})
				this.props.photoDelete(photoId)
				.then(() => {
					_this.setState({loading:false})
				})      
				.catch((error) => {
					_this.setState({loading:false})    
					Swal.fire({
						position: 'top-end',
						type: 'error',
						title: 'Sorry, there was an error deleting the photo',
						showConfirmButton: false,
						timer: 2000
					})
				})
			}
		})
	}
	setPhoto = (photoId) => {
		let _this = this
		Swal.fire({
			title: 'Change Photo?',
			text: "Set this photo as your main Profile Photo",
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, change it!'
		}).then((result) => {
			if (result.value) {
				this.setState({loading:true})
				this.props.photoSet(photoId)
				.then(() => {
					_this.setState({loading:false})
				})      
				.catch((error) => {
					_this.setState({loading:false})    
					Swal.fire({
						position: 'top-end',
						type: 'error',
						title: 'Sorry, there was an error setting the photo',
						showConfirmButton: false,
						timer: 2000
					})
				})
			}
		})
	}
	render() {  
		const {currentImage, defaultImages, btnDisabled, loading} = this.state
		const {imageSelected, images} = this.props.userInfo
		return (
			<React.Fragment>
				<Loader loading={loading}/>
				<Grid container justify='flex-start' spacing={2} style={{marginTop:20}}>
					{Object.keys(images).map((i, index) =>{
						let disabled = (imageSelected === i) ? true : false
						return (
							<Grid key={index} item md={6} sm={6} xs={12}>
								<Card style={{padding:15}}>
									<CardMedia component="img" image={images[i].photo} />
									<CardContent style={{paddingLeft:0, paddingRight:0}}>
										<Text variant="body2" color="textSecondary" component="p">
											Listed On: {moment.unix(images[i].timestamp).format('Do MMM YYYY')}<br/>
											  
											{(images[i].verifiedBy) ? (     
												<span>
													Verified By:<br/>
													{Object.keys(images[i].verifiedBy).map((x) => {
													   return (
														 	<div> - {images[i].verifiedBy[x].groupName} <i>(by {images[i].verifiedBy[x].memberName})</i></div>
													   )
													})}
												</span>
											):( null )}
										</Text>
									</CardContent>
									<CardActions>
									   <Button disabled={disabled} variant="outlined" color="secondary" size="small" onClick={() => {this.deletePhoto(i)}}>Delete</Button>
										<Button disabled={disabled} variant="contained" color="secondary" size="small" onClick={() => {this.setPhoto(i)}}>Set as Primary</Button>
									</CardActions>
								</Card>
							</Grid>
						)
					})} 
					<Grid item md={6} sm={6} xs={12}>
						<Paper style={{padding: 15}}>
							<ImageSelector 
								imgBorder='1px solid #ccc'
								imgBorderRadius={10}
								imgPadding={5}
								imgRatio='1:1' 
								images={defaultImages}
								currentImage={currentImage}
								selectImage={this.handleSelectImage}
								hideSelector={true}
							/>
							<Button 
								fullWidth 
								onClick={this.addPhoto}
								style={{marginTop:15}}
								variant='contained' 
								color='secondary'
								disabled={btnDisabled}
								size='small'
							>
							  Add Image
							</Button>
						</Paper>
					</Grid>	
				</Grid>     
			</React.Fragment>
		)
	}
}
export default ProfilePhotos
  