import React from 'react'

function Info(){
    return (
        <div>
            Group Settings Dialog<br/>
            this should be quick reference slides highlighting the features of Group Settings.
        </div>
    )
}
export default Info
