import React from 'react'
import { withRouter } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'

function SectionSideNav(props){
   const handleClick = (value) => {
    	let path = '/group/communicate/' + value
      props.history.push({ pathname: path })
   }

   const filter = props.filter
	return (
		<div>
			<List component="nav" style={{backgroundColor:'#fff'}}
				subheader={
		        <ListSubheader component="div" id="nested-list-subheader">
		          	<i>Communicate Menu</i>
		        </ListSubheader>
		      }
			>
		   	<ListItem button selected={filter === ''} onClick={() => handleClick('')}>
			 		<ListItemText primary="- Summary" />
		  		</ListItem>
		  		<ListItem button selected={filter === 'Posts'} onClick={(event) => handleClick('List/Posts')}>
			 		<ListItemText primary="- Posts" />
		  		</ListItem>
		  		<ListItem button selected={filter === 'Polls'} onClick={(event) => handleClick('List/Polls')}>
			 		<ListItemText primary="- Polls" />
		  		</ListItem>
		  		<ListItem button selected={filter === 'Surveys'} onClick={(event) => handleClick('List/Surveys')}>
			 		<ListItemText primary="- Surveys" />
		  		</ListItem>
		  		<ListItem button selected={filter === 'Newsletters'} onClick={(event) => handleClick('List/Newsletters')}>
			 		<ListItemText primary="- Newsletters" />
		  		</ListItem>
		  		<Divider />
		  		<div align='right'>
		  			<Button onClick={props.sideNavToggle} size='small' style={{color:'#90a4ae'}}>view full menu</Button>
		  		</div>
		  	</List>
		</div>
	)
}
export default withRouter(SectionSideNav)
