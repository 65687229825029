import React from 'react'
import Text from '@material-ui/core/Typography'

function About(){
	return (
		<Text variant='caption' gutterBottom>
			<b>About</b><br/>
			Learn about LoyalTT Groups & Teams
		</Text>
	)
}
export default About
