import React from 'react'
import { withRouter } from 'react-router-dom'
import Info from './Info'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import IconInfo from '@material-ui/icons/InfoOutlined'
import Text from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'

class Subnav extends React.Component {
    state = {
       showInfo: false 
    }
    handleShowInfoToggle = () => {
       this.setState({ showInfo: !this.state.showInfo })
    }
    render() {
        return (
            <React.Fragment>
                <AppBar position='static' color='default' elevation={1}>
                    <Toolbar variant="dense" style={{paddingRight:0}}>
                        <Button onClick={() => {this.props.history.push({ pathname: '/group/communicate' }) }} >
                            <Text variant='h6' color='primary' style={{ paddingRight: 15 }}>
                                <b>GROUP COMMUNICATE</b>
                            </Text>
                        </Button>
                        <div style={{ flexGrow: 1, minWidth: 50, textAlign: 'right'}}>
                            <IconButton onClick= { this.handleShowInfoToggle } >
                                <IconInfo />
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                <Dialog open={this.state.showInfo} onClose={this.handleShowInfoToggle}>
                    <DialogContent>
                       <Info />
                    </DialogContent>
                    <DialogActions>
                       <Button onClick={this.handleShowInfoToggle} color='primary' autoFocus>
                            Close
                       </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }
}
export default withRouter(Subnav)
