import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { monitorDashboard, stopMonitoringDashboard, openNotificationToggle } from '../../../redux/memberCommunicate/actions'

import Loader from '../../components/Loader'
import GroupProfilePage from '../../components/GroupProfilePage/'
import SubNav from './components/Subnav'
import SectionTopNav from './components/SectionTopNav'
import SectionSideNav from './components/SectionSideNav'
import Points from './Points'
import Posts from './Posts'
import Savings from './Savings'
import Summary from './Summary'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'

class MemberDashboardContainer extends React.Component {
	state = {
		loading: true,
		section: 'Summary',
		filter: 'All',
		groupId: null
	}
   componentDidMount = () => {
	 	let _this = this
	 	this.props.monitorDashboard()
	 	.then(() => {
	 		_this.setState({loading:false})
	 		_this.sortFilter()
	 	})
   }
   componentDidUpdate = (prevProps) => {
	 	if (this.props.match.params !== prevProps.match.params) {
			this.sortFilter()
	 	}
   }
   componentWillUnmount = () => {
	 	this.props.stopMonitoringDashboard()
   }
	sortFilter = () => {
		let section = (this.props.match.params.section) ? this.props.match.params.section : 'Summary'
		let filter = (this.props.match.params.filter) ? this.props.match.params.filter : 'All'		
		let groupId = (this.props.match.params.groupId) ? this.props.match.params.groupId : null
		this.setState({ section:section, filter:filter, groupId:groupId })
	}
	handleOpenGroup = (groupId) => {
		let url = '/member/dashboard/' + this.state.section + '/' + this.state.filter + '/' + groupId
		this.props.history.push({ pathname: url })
	}	
	handleCloseGroup = () => {
		let url = '/member/dashboard/' + this.state.section + '/' + this.state.filter
		this.props.history.push({ pathname: url })        
	}	
	render() {
		const {loading, section, filter, groupId} = this.state
		const {openNotificationToggle} = this.props
		const { notifications, posts, points, savings, sideNavToggle} = this.props
		return (
			<React.Fragment>	
				{(groupId) ? (
            	<GroupProfilePage open={Boolean(groupId)} toggleGroup={this.handleCloseGroup} groupId={groupId} />    
            ) : ( null)}

				<Loader loading={loading} />
				<Container maxWidth='lg' style={{ padding: 10, marginBottom: 150, minHeight:700 }}>
					<Grid container justify='center' spacing={2}>
						<Grid item xs={12} sm={12} md={10} >
							<SubNav/>
						</Grid>
						<Hidden xsDown>
							<Grid item sm={4} md={3}>
							  <SectionSideNav section={section} filter={filter} sideNavToggle={sideNavToggle}/>
							</Grid>
						</Hidden>
						<Grid item xs={12} sm={8} md={7}>
							<SectionTopNav section={section} filter={filter} sideNavToggle={sideNavToggle}/>
							<Switch>
								<Route path='/member/dashboard/Savings' render={props => <Savings {...props} 
										savings={savings}
									/>} 
								/>
								<Route path='/member/dashboard/Points' render={props => <Points {...props} 
										points={points}
									/>} 
								/>
								<Route path='/member/dashboard/Posts' render={props => <Posts {...props} 
										posts={posts}
										filter={filter}
									/>} 
								/>
								<Route path='/member/dashboard' render={props => <Summary {...props} 
										handleOpenGroup={this.handleOpenGroup}
										openNotificationToggle={openNotificationToggle}
										notifications={notifications}
										posts={posts}
									/>} 
								/>
							</Switch>
						</Grid>
					</Grid>
				</Container>
			</React.Fragment>
		)
	}
}
const mapStateToProps = (state) => ({
	notifications: state.memberCommunicate.notifications,
	posts: state.memberCommunicate.posts,
	points: state.memberCommunicate.points,
	savings: state.memberCommunicate.savings,
})
const mapDispatchToProps = {
	monitorDashboard,
	stopMonitoringDashboard,
	openNotificationToggle
}
export default connect(mapStateToProps, mapDispatchToProps)(MemberDashboardContainer)
			