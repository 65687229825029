import React from 'react'

function Info(){
    return (
        <div>
            Profile Info Dialog<br/>
            this should be quick reference slides highlighting the features of the user's profile.
        </div>
    )
}
export default Info
