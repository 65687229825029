import React from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import OpenInNew from '@material-ui/icons/OpenInNew'

class ServiceAndPolicies extends React.Component {
    state = {
      open: false,
      value: 0
    }

    handleOpen = (tab) => {
        let val = (tab === 3) ? 0 : tab;
        this.setState({  open: true, value: val })
    }
    handleClose = () => {
        this.setState({ open: false })
    }
    handleChange = (event, value) => {
        this.setState({ value })
    }

    render() {
        let tabName = ['Terms of Service','Privacy Policy','Refund Policy','I agree with the TOS']
        return (
            <React.Fragment>
            <Button style={{fontSize: 12, padding:0, margin:0}} size='small' onClick={() => {this.handleOpen(this.props.tab)}}>{tabName[this.props.tab]} <OpenInNew style={{fontSize: 16}}/></Button>
            <Dialog
                fullScreen={this.props.fullScreen}
                open={this.state.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.handleClose}
            >
                <DialogContent style={{ padding:0, margin: 0}}>
                    <AppBar position="static" color="default">
                        <Tabs
                        variant="fullWidth" 
                        value={this.state.value}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        >
                        <Tab label="Terms of Service" />
                        <Tab label="Privacy Policy" />
                        <Tab label="Refund Policy" />
                        </Tabs>
                    </AppBar>
                    <div style={{padding: 10}}>
                        {(this.state.value === 0) ? (
                            <TOS />
                        ) : ( null )}
                        {(this.state.value === 1) ? (
                            <PrivacyPolicy />
                        ) : ( null )}
                        {(this.state.value === 2) ? (
                            <RefundPolicy />
                        ) : ( null )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color='primary'>Close</Button>
                </DialogActions>
            </Dialog>
            </React.Fragment>
        )
    }
}
export default withMobileDialog()(ServiceAndPolicies)

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />
})

const TOS = () => {
  return (
        <div>
            <p><b>Terms of Service </b></p>
            <p><b><u>Terms</u></b></p>
            <p>By accessing this web site, you are agreeing to be bound by these web site Terms and Conditions of Use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this web site are protected by applicable copyright and trade mark law.</p>
            
            <p><b><u>Use License</u></b></p>
            <ol>
            <li>Permission is granted to temporarily download one copy of the materials (information or software) on LoyalTT.com web site for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not: </li>
                <ul>
                <li>modify or copy the materials;</li>
                <li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
                <li>attempt to decompile or reverse engineer any software contained on LoyalTT.com web site;</li>
                <li>remove any copyright or other proprietary notations from the materials; or</li>
                <li>transfer the materials to another person or "mirror" the materials on any other server.</li>
                </ul>
            <li>This license shall automatically terminate if you violate any of these restrictions and may be terminated by LoyalTT.com at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.</li>
            </ol>


            <p><b><u>Disclaimer</u></b></p>
            <p>The materials on LoyalTT.com web site are provided "as is". LoyalTT.com makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, LoyalTT.comdoes not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its Internet web site or otherwise relating to such materials or on any sites linked to this site.</p>

            <p><b><u>Limitations</u></b></p>
            <p>In no event shall LoyalTT.com or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption,) arising out of the use or inability to use the materials on LoyalTT.com Internet site, even if LoyalTT.com or a LoyalTT.com authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>
            
            <p><b><u>Revisions and Errata</u></b></p>
            <p>The materials appearing on LoyalTT.com web site could include technical, typographical, or photographic errors. LoyalTT.comdoes not warrant that any of the materials on its web site are accurate, complete, or current. LoyalTT.commay make changes to the materials contained on its web site at any time without notice. LoyalTT.comdoes not, however, make any commitment to update the materials.</p>
            
            <p><b><u>Links</u></b></p>
            <p>LoyalTT.com has not reviewed all of the sites linked to its Internet web site and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by LoyalTT.com of the site. Use of any such linked web site is at the user's own risk.</p>
            

            <p><b><u>Site Terms of Use Modifications</u></b></p>
            <p>LoyalTT.commay revise these terms of use for its web site at any time without notice. By using this web site you are agreeing to be bound by the then current version of these Terms and Conditions of Use.</p>
            
            <p><b><u>Governing Law</u></b></p>
            <p>Any claim relating to LoyalTT.comweb site shall be governed by the laws of the State of Port of Spain without regard to its conflict of law provisions.</p>
            <p>General Terms and Conditions applicable to Use of a Web Site.</p>

        </div>
    )
}

const PrivacyPolicy = () => {
    return (
        <div>
            <p><b>Security</b></p>
            <p>Several methods of security are used including, encryption, firewalls and other technology and security procedures to help protect the accuracy and security of your personal information and prevent unauthorized access or improper use. For example, you will note that while using some features of the web site and online services, you will need to submit a password or some other type of authenticating information.</p>
            <p><b>Privacy Statement</b></p>
            <p>Your privacy is respected and we work hard to safeguard the privacy of your personal data. We realize that the Internet raises questions concerning the nature, use and confidentiality of information collected about consumers. We want you to know that we apply our long-standing commitment to safeguarding privacy to our Internet activities.</p>
            <p><b>Personal Information</b></p>
            <p>Your information is not shared and we do not disclose or sell any personally information (such as your name, address, telephone number or e-mail address) collected online on via the web site with other unaffiliated companies or organizations. In the future, should we decide to share such data with third parties for marketing, it will provide notification and the opportunity for its users to indicate whether they would prefer that the Company not provide such information about them to third parties. Because this information is a critical part of our business, it would be treated like our other assets in the context of a merger, sale or other corporate reorganization or legal proceeding.</p>
            <p><b>How We Use Information</b></p>
            <p>Information we collect is used principally to provide you with the service(s) and product(s) you have ordered and to let you know about other offerings which may be of interest to you. Postal and e-mail address information, for example, enables communication about services offered and ordered, as well as the proper provisioning and billing of those services. Information will be provided in response to subpoenas and other legal demands, and where we believe that disclosing this information is necessary to identify, contact or bring legal action against individuals who may be endangering public safety or interfering with property or services, or with our customers’ or others’ use of them.</p>
            <p><b>How We Collect Information</b></p>
            <p>Information is collected online by asking you for it, for example, when sign up for service. A customer’s name, address (postal and e-mail) and telephone number are the most important pieces of information, but we might request other information, based on, for example, individual organization marketing services.</p>
            <p><b>Password Protected/Secure Area Access</b></p>
            <p>Accessibility and use of any password protected or secure areas of the website, is restricted to authorized users only. Unauthorized users that attempt to access any of these restricted areas of the website may be subject to prosecution.</p>
            <p><b>Services Offered by Company</b></p>
            <p>When you apply and register on the website you accept the terms and conditions that are applicable to that particular service. We do not warrant that that any service descriptions and/or content that is contained in the website is accurate, current, complete, reliable, or error-free.</p>
            <p><b>Termination</b></p>
            <p>Access may be terminated to the website along with any services with or without any cause, at any time. Any termination is to be accompanied by a written or electronic notice. We are not liable to you or any third party for service termination. Upon termination of any and all access to the website and services, your right to use the website and/or services is immediately revoked.</p>
            <p><b>Internet Abuse</b></p>
            <p>You are prohibited from engaging in any form of Internet or website abuse, including but not limited to the following: Distributing, publishing, or sending any kind of unsolicited or unwelcome email to any number of network users (commonly referred to as “junk mail” or “spam”), including, without limitation, mass promotions, unauthorized or unsolicited commercial advertising, and informational announcements, anywhere on the Internet. Posting a single article or substantially similar articles to an excessive number of newsgroups or mailing lists. Repeated or deliberate posting of articles that are off-topic according to the charter of the newsgroup or mail list where such articles are posted. Posting commercial advertising in a conference or newsgroup, unless it is specifically permitted to be posted within that group; or (e) violating the CAN-SPAM Act.</p>
        </div>
    )
}

const RefundPolicy = () => {
    return (
        <div>
            <p><b>Refund Policy</b></p>
            <p>There are no refunds or exchanges on eTickets.</p>
            <p><b>Cancellation Policy</b></p>
            <p>
                If the event is cancelled, we will refund the amount you paid for your tickets 
                (minus all processing fees) using the same payment method used for purchase. 
                If the event is postponed, the new date and time will be posted and all tickets would be honored. 
            </p>
            <p>
                The event promoter reserves the right to refuse entry to any ticket holder for any reason at their 
                discretion. Venue and Talent are subject to change without notice and are not grounds for a refund. 
                Anything advertised by the promoter is not guaranteed by LoyalTT.com and is not grounds for refund.
            </p>
        </div>
    )
}
