import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { logOut } from '../../../redux/auth/actions'
import { getTeamRole, acceptTeamRole } from '../../../redux/member/actions'

import SubNav from './components/Subnav'
import Team from './Team'

class MemberJoinContainer extends React.Component {
	state = {
		page: (this.props.match.params.page) ? this.props.match.params.page : ''
	}
	render() {
		const {page} = this.state
		const {logOut, getTeamRole, acceptTeamRole} = this.props
		return (
			<React.Fragment>
				<SubNav page={page}/>
				<Switch>
					<Route path='/member/join/team/:id?' render={props => <Team {...props} 
							logOut={logOut}
							getTeamRole={getTeamRole}
							acceptTeamRole={acceptTeamRole}
						/>} 
					/>
				</Switch>
			</React.Fragment>
		)
	}
}
const mapDispatchToProps = {
	logOut,
	getTeamRole, 
	acceptTeamRole
}
export default connect(null, mapDispatchToProps)(MemberJoinContainer)
