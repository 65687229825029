import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import 'typeface-roboto'

import App from './router/App'
import './styles/styles.scss'
import * as serviceWorker from './serviceWorker'
import store from './redux/store'
import theme from './styles/theme-1'

render(
  <BrowserRouter>
    <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

// when setting up service worker PWA follow these tutorials
// https://developers.google.com/web/fundamentals/codelabs/your-first-pwapp/
// https://codelabs.developers.google.com/codelabs/add-to-home-screen/#0
// https://www.netguru.com/codestories/few-tips-that-will-make-your-pwa-on-ios-feel-like-native
// dealing with cache - us caches.delete() and window.location.reload(true) to force reload in your code
// dealing with cache - https://medium.com/progressive-web-apps/pwa-create-a-new-update-available-notification-using-service-workers-18be9168d717
// dealing with cache - https://deanhume.com/displaying-a-new-version-available-progressive-web-app/