export const selectorLoadTeam = (members) => {
	let team = {
		Owner: {
			activeCount: 0,
			pendingCount: 0,
			totalCount: 0,
			active:{},
			pending:{}
		},
		Admin: {
			activeCount: 0,
			pendingCount: 0,
			totalCount: 0,
			active:{},
			pending:{}
		},
		Manager: {
			activeCount: 0,
			pendingCount: 0,
			totalCount: 0,
			active:{},
			pending:{}
		},
		Editor: {
			activeCount: 0,
			pendingCount: 0,
			totalCount: 0,
			active:{},
			pending:{}
		},
		CSR: {
			activeCount: 0,
			pendingCount: 0,
			totalCount: 0,
			active:{},
			pending:{}
		},
		Attendant: {
			activeCount: 0,
			pendingCount: 0,
			totalCount: 0,
			active:{},
			pending:{}
		},
		Sales_Rep: {
			activeCount: 0,
			pendingCount: 0,
			totalCount: 0,
			active:{},
			pending:{}
		},
	}

	if (Object.keys(members).length) {
		Object.keys(members).forEach(function (i) {
			let roles = members[i].roles
			if (Object.keys(roles).length) {
				Object.keys(roles).forEach(function (x) {
					let role = roles[x].role
					if (members[i].roles[x].accepted) {
						team[role].activeCount = team[role].activeCount + 1 
						team[role].totalCount = team[role].totalCount + 1
						team[role].active[i] = members[i]
					} else {
						team[role].pendingCount = team[role].pendingCount + 1 
						team[role].totalCount = team[role].totalCount + 1
						team[role].active[i] = members[i]
					}
				})
			}
		})
	}
	return team
}


// ::::::::::::::::::::::::::::::::::::::::::::::::::;
// ::::::::::::::::::::::::::::::::::::::::::::::::::;


export const selectorFilterMembers = (members, status) => {
	let filteredMembers = []
	if(members.length){
		switch(status) {
			case 'LoyalTT':
				filteredMembers = members.filter((member) => { return member.memberStatus === 'registered' })
				break
			case 'Emails':
				filteredMembers = members.filter((member) => { return member.memberStatus === 'unregistered' })
				break
			default:
				filteredMembers = []
		}
	}
	return filteredMembers
}

export const selectorFilterMemberStatus = (members) => {
	let status = []
	if(members.length){
		let loyaltt = members.filter((member) => { return member.memberStatus === 'registered' })
		status = [...new Set(loyaltt.map(member => member.status.charAt(0).toUpperCase() + member.status.slice(1)))]
		status.unshift('All')
	}
	return status
}
