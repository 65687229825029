import { GROUP_POSTS_LOAD, GROUP_POSTS_RESET_REDUCER } from '../types'

const initialState = {
		groupId: undefined,
		lastUpdated: 0,
		posts: {}
}

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case GROUP_POSTS_LOAD:
			return { 
					...state, 
					groupId: action.payload.groupId,
					lastUpdated: action.payload.timestamp,
					posts: action.payload.posts
			}
		case GROUP_POSTS_RESET_REDUCER:
			return initialState
	default:
		return state
	}
}
