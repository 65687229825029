import React from 'react'
import { withRouter } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
//import InboxIcon from '@material-ui/icons/Inbox'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
//import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'

function SectionSideNav(props){
	const handleClick = (value) => {
		let path = '/member/memberships/' + value
		props.history.push({ pathname: path })
	}

	const section = props.section
	return (
		<div>
			<List component="nav" aria-label="main mailbox folders"
				style={{backgroundColor:'#fff'}}
				subheader={
				  <ListSubheader component="div">
					 <i>Memberships Menu</i>
				  </ListSubheader>
				}
			>
				<ListItem button selected={section === 'Active'} onClick={() => handleClick('Active')}>
					<ListItemText primary="- Active" />
				</ListItem>

				<ListItem button selected={section === 'Inactive'} onClick={(event) => handleClick('Inactive')}>
					<ListItemText primary="- Inactive" />
				</ListItem>

				<ListItem button selected={section === 'Roles'} onClick={(event) => handleClick('Roles')}>
					<ListItemText primary="- Roles" />
				</ListItem>

				<ListItem button selected={section === 'Create-A-Group'} onClick={(event) => handleClick('Create-A-Group')}>
					<ListItemText primary="- Create A Group" />
				</ListItem>
				<Divider />
		  		<div align='right'>
		  			<Button onClick={props.sideNavToggle} size='small' style={{color:'#90a4ae'}}>view full menu</Button>
		  		</div>
			</List>
		</div>
	)
}
export default withRouter(SectionSideNav)
