import React from 'react'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Toolbar from '@material-ui/core/Toolbar'

import IconButton from '@material-ui/core/IconButton'
import IconHome from '@material-ui/icons/Apps'
import IconRightArrow from '@material-ui/icons/KeyboardArrowRight'

function GroupScannerEventsList(props) {
    return (
        <Container maxWidth='lg' style={{ padding: 10, marginBottom: 150 }}>
        <Grid container justify='center' spacing={2}>

            {/* [START]: App Bar ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */}
            <Grid item xs={12} sm={12} md={10} >
                <AppBar position='static' color='default'>
                    <Toolbar variant="dense">
                        <IconButton onClick={() => {props.history.push({ pathname: '/group/scanner' })}}>
                            <IconHome color='primary'/>
                        </IconButton>
                        <IconRightArrow color='primary' style={{marginLeft:-10}}/>
                        <Text variant='h6' color='primary'>
                            <b>EVENT CHECK-IN</b>
                        </Text>
                    </Toolbar>
                </AppBar> 
            </Grid>
            {/* [END]: App Bar ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */}

            <Grid item xs={12} sm={12} md={10} >
                <Paper style={{ padding: 20 }} >
                    <Text variant='h5' gutterBottom>
                        Events List
                    </Text>
                    <Text variant='caption' gutterBottom>
                        <p>
                            <Button onClick={() => {props.history.push({ pathname: '/group/scanner/event-checkin/event/123' })}}>Event 123</Button>
                        </p>
                    </Text>
                </Paper>
            </Grid>
        </Grid>
        </Container>
    )
}

export default GroupScannerEventsList
