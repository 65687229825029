import React from 'react'
import { withRouter } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Badge from '@material-ui/core/Badge'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'

function SectionSideNav(props){
	const handleClick = (value) => {
		let path = '/member/support/' + value
		props.history.push({ pathname: path })
	}

	const section = props.section
	return (
		<div>
			<List component="nav"
				style={{backgroundColor:'#fff'}}
				subheader={
				  <ListSubheader component="div" id="nested-list-subheader">
					 <i>Support Desk Menu</i>
				  </ListSubheader>
				}
			>
				<ListItem button selected={section === 'New-Ticket'} onClick={() => handleClick('New-Ticket')}>					
					<ListItemText primary="- New Ticket" />
				</ListItem>

				<ListItem button selected={section === 'Open'} onClick={(event) => handleClick('Open')}>
					<ListItemText primary="- Open" />
					<Badge style={{ marginRight: 10 }} badgeContent={props.openTicketsCount} color='secondary'><div /></Badge>
				</ListItem>

				<ListItem button selected={section === 'Closed'} onClick={(event) => handleClick('Closed')}>
					<ListItemText primary="- Closed" />
				</ListItem>
				<Divider/>
				{props.ticketGroups.map((option) => (               
					<ListItem button key={option} selected={option === section} onClick={() => {handleClick(option)}}>
						<ListItemText primary={'- ' + option.replace(/-/g, ' ')} />
					</ListItem>
           	))}
           	<Divider />
		  		<div align='right'>
		  			<Button onClick={props.sideNavToggle} size='small' style={{color:'#90a4ae'}}>view full menu</Button>
		  		</div>
			</List>
		</div>
	)
}
export default withRouter(SectionSideNav)
