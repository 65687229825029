import React from 'react'

import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function GroupSettingsView() {
	return (
		<Paper style={{ padding: 20, marginTop:20, marginBottom:150 }} >
			<Text variant='h6' gutterBottom>
				PROFILE
			</Text>
			<Text variant='caption' gutterBottom>
				<b>ABOUT</b><br/>
				<p>This section shows the general info for the group. You can change it's profile picture,
				edit it's descriptions etc.</p>
				
				<p><b>CONTENT</b></p>
				- group settings<br/>
				- faqs<br/>
				- location<br/>

				<p><b>ACCESS</b></p>
				- GROUP SETTINGS is accessable by Level 1 only (owners and admins)<br/>
			</Text>
		</Paper>
	)
}
export default GroupSettingsView
