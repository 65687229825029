import React from 'react'
import { connect } from 'react-redux'

import SubNav from './components/Subnav'
import SectionSideNav from './components/SectionSideNav'
import SectionTopNav from './components/SectionTopNav'
import Dashboard from './Dashboard'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'

class GroupDashboardContainer extends React.Component {
	render() {
		const {login} = this.props
		if (!login) return null
		
		return (
			<React.Fragment>
				<Container maxWidth='lg' style={{ padding: 10, marginBottom: 150, minHeight:700 }}>
					<Grid container justify='center' spacing={2}>
						<Grid item xs={12} sm={12} md={10} >
							<SubNav/>
						</Grid>
						<Hidden xsDown>
							<Grid item sm={4} md={3}>
							  <SectionSideNav/>
							</Grid>
						</Hidden>
						<Grid item xs={12} sm={8} md={7}>
							<SectionTopNav login={login}/>
							<Dashboard />
						</Grid>
					</Grid>
				</Container> 
			</React.Fragment>
		)
	}
}
const mapStateToProps = (state) => ({
	login: state.auth.loginOptions[state.auth.loginOptionID]
})
const mapDispatchToProps = {
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupDashboardContainer)
