import { GROUP_SUPPORT_LOAD_TICKETS, GROUP_SUPPORT_LOAD_TEAM, GROUP_SUPPORT_RESET_REDUCER } from '../types'

const initialState = {
	groupId: undefined,
	lastUpdated: 0,
	supportTickets: {},
	team: []
}

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case GROUP_SUPPORT_LOAD_TICKETS:
			return { 
				...state, 
				groupId: action.payload.groupId,
				lastUpdated: action.payload.timestamp,
				supportTickets: action.payload.supportTickets
			}
		case GROUP_SUPPORT_LOAD_TEAM:
			return { 
				...state, 
				team: action.payload
			}
		case GROUP_SUPPORT_RESET_REDUCER:
			return initialState
	default:
		return state
	}
}
