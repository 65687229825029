import { MEMBER_NOTIFICATIONS_LOAD, MEMBER_POSTS_LOAD, MEMBER_POINTS_LOAD, MEMBER_SAVINGS_LOAD } from '../types'

const initialState = {
	lastUpdated: 0,
	notifications: {},
	posts: {},
	points: {},
	savings: {}
}

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case MEMBER_NOTIFICATIONS_LOAD:
			return { 
				...state, 
				lastUpdated: action.payload.timestamp,
				notifications: action.payload.notifications
			}
		case MEMBER_POSTS_LOAD:
			return { 
				...state, 
				lastUpdated: action.payload.timestamp,
				posts: action.payload.posts
			}
		case MEMBER_POINTS_LOAD:
			return { 
				...state, 
				lastUpdated: action.payload.timestamp,
				points: action.payload.points
			}
		case MEMBER_SAVINGS_LOAD:
			return { 
				...state, 
				lastUpdated: action.payload.timestamp,
				savings: action.payload.savings
			}
	default:
		return state
	}
}
