import React from 'react'

import SubNav from './components/Subnav'
import Summary from './Summary'

class MemberSettingsContainer extends React.Component {
  	render() {
    	return (
      		<React.Fragment>
        		<SubNav />
        		<Summary />
      		</React.Fragment>
    	)
  	}
}
export default MemberSettingsContainer
