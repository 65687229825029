import { GROUP_MEMBERS_LOAD, GROUP_MEMBERS_RESET_REDUCER } from '../types'

const initialState = {
	groupId: undefined,
	lastUpdated: 0,
	members: {},
	lists: {}
}

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case GROUP_MEMBERS_LOAD:
			return { 
				...state, 
				groupId: action.payload.groupId,
				lastUpdated: action.payload.timestamp,
				members: action.payload.members
			}
		case GROUP_MEMBERS_RESET_REDUCER:
			return initialState
		  default:
			  return state
	}
}
