import React from 'react'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Text from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'

class Subnav extends React.Component {
	render() {
		return (
			<Container maxWidth='lg' style={{ padding: 10 }}>
			<Grid container justify='center' spacing={2}>
				<Grid item xs={12} sm={12} md={10} >
					<AppBar position='static' color='default'>
						<Toolbar variant="dense">
							<Button>
								<Text variant='h6' color='primary' style={{ paddingRight: 15 }}>
									<b>Join {this.props.page}</b>
								</Text>
							</Button>
						</Toolbar>
					</AppBar> 
				</Grid>
			</Grid>
			</Container>
		)
	}
}
export default Subnav
