import React from 'react'
import { withRouter } from 'react-router-dom'
import IdCard from './IdCard'

import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import Hidden from '@material-ui/core/Hidden'
import IconDashboard from '@material-ui/icons/Dashboard'
import IconID from '@material-ui/icons/Portrait'
import LocationOnIcon from '@material-ui/icons/CropFree'

class BottomNav extends React.Component {
    state = {
        selectedTab: false,
        previousTab: false,
        openID: false,
        footerLinks: [],
        selectedOption: {}
    }
    
    componentDidMount = () => {
        this.handleSetTabs(this.props)
    }
    
    componentDidUpdate = (prevProps) => {
        if (this.props !== prevProps) {
            this.handleSetTabs(this.props)
        }        
    }
  
    handleSetTabs = (props) => {
        let footerLinks = (props.selectedOption.login === 'group') ? ['', '/group/dashboard', '/group/scanner' ] : ['', '/member/dashboard', '/member/scanner' ]
        let location = props.location.pathname
        let index = footerLinks.indexOf(location)
        if (index < 1) {
            this.setState({ previousTab: false, selectedTab: false, footerLinks: footerLinks, selectedOption: props.selectedOption })
        } else {
            this.setState({ previousTab: index, selectedTab: index, footerLinks: footerLinks, selectedOption: props.selectedOption })
        }
    }
    
    handleTabChange = (event, value) => {
        this.setState({ selectedTab: value })
        if (value) {
            this.props.history.push({ pathname: this.state.footerLinks[value] })
        } else {
            this.handleOpenIDOpen()
        }
    }
    
    handleOpenIDOpen = () => {
        this.setState({ openID: true, selectedTab: 0 })
    }
    
    handleOpenIDClose = () => {
        this.setState({ openID: false, selectedTab: this.state.previousTab })
    }

    render() {
        return (
            <Hidden lgUp>
                <IdCard
                openID={ this.state.openID }
                handleOpenIDClose={ this.handleOpenIDClose }
                selectedOption={ this.state.selectedOption }
                />
                <BottomNavigation
                    style={{ position: 'fixed', bottom: 0, width: '100%' }}
                    value={ this.state.selectedTab }
                    onChange={ this.handleTabChange }
                    showLabels
                >
                    <BottomNavigationAction label='ID Card' icon={<IconID />} />
                    <BottomNavigationAction label='Dashboard' icon={<IconDashboard />} />
                    <BottomNavigationAction label='QR Scanner' icon={<LocationOnIcon />} />
                </BottomNavigation>
            </Hidden>
        )
    }
}
export default withRouter(BottomNav)
