import React from 'react'
import { connect } from 'react-redux'
import { startMonitoringSupport, stopMonitoringSupport, openTicket, updateTicket, closeTicket } from '../../../redux/memberSupport/actions'
import { selectorLoadTickets, selectorMemberGroups, selectorTicketGroups } from '../../../redux/memberSupport/selectors'

import Loader from '../../components/Loader'
import SubNav from './components/Subnav'
import SectionTopNav from './components/SectionTopNav'
import SectionSideNav from './components/SectionSideNav'
import New from './New'
import List from './List'
import Ticket from './Ticket'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'

class MemberSupportContainer extends React.Component {
   state = {
  		loading:true,
	 	section: 'Open',
	 	ticketId: null,
	 	tickets: {},
	 	memberGroups: {},
	 	ticketGroups: []
   }
   componentDidMount = () => {
	 	let _this = this
	 	this.props.startMonitoringSupport()
	 	.then(() => {
	 		_this.setState({loading:false})
	 		_this.sortFilter()
	 	})
   }
   componentDidUpdate = (prevProps) => {
	 	if (this.props.match.params !== prevProps.match.params) {
			this.sortFilter()
	 	}
   }
   componentWillUnmount = () => {
	 	this.props.stopMonitoringSupport()
   }
   sortFilter = () => {
	 	let tickets = {}
	 	let section = (this.props.match.params.section) ? this.props.match.params.section : 'Open'
	 	let ticketId = (this.props.match.params.ticketId) ? this.props.match.params.ticketId : null
	 	let memberGroups = this.props.memberGroups
	 	let ticketGroups = this.props.ticketGroups
		let allTickets = this.props.allTickets || {}
		
		if (ticketId && allTickets.hasOwnProperty(ticketId)) {	 
		  	tickets = { [ticketId]: allTickets[ticketId] }
		} else {
	  		ticketId=null
		 	switch(section) {
				case 'New-Ticket':
					tickets = {} 
					break
				case 'Open':
					tickets = this.props.openTickets
					break
				case 'Closed':
					tickets = this.props.closedTickets
					break
				default:
					Object.keys(allTickets).map((i, key) => {
						if(allTickets[i].groupName === section.replace(/-/g, ' ')){
							tickets[i] = allTickets[i]
						}
						return null
					})
			}
		} 
		this.setState({section:section, ticketId:ticketId, tickets:tickets, memberGroups:memberGroups, ticketGroups:ticketGroups })
  	}
  
  	render() {
	 	const {loading, section, ticketId, tickets, memberGroups, ticketGroups} = this.state
	 	const {categories, sideNavToggle} = this.props
	 	const {allTickets, openTicket, updateTicket, closeTicket} = this.props
		const openTicketsCount = Object.keys(this.props.openTickets).length

	 	return (
	 		<React.Fragment>
	 			<Loader loading={loading} />
				<Container maxWidth='lg' style={{ padding: 10, marginBottom: 150, minHeight:700 }}>
					<Grid container justify='center' spacing={2}>
						<Grid item xs={12} sm={12} md={10} >
							<SubNav/>
						</Grid>
						<Hidden xsDown>
							<Grid item sm={4} md={3}>
							  <SectionSideNav section={section} ticketGroups={ticketGroups} openTicketsCount={openTicketsCount} sideNavToggle={sideNavToggle}/>
							</Grid>
						</Hidden>
						<Grid item xs={12} sm={8} md={7}>
							<SectionTopNav section={section} ticketGroups={ticketGroups} openTicketsCount={openTicketsCount} sideNavToggle={sideNavToggle}/>
							{ (section === 'New-Ticket') ? (
							 	<New memberGroups={memberGroups} categories={categories} openTicket={openTicket}/>
						  	) : (
							 	(ticketId) ? (
									<Ticket section={section} tickets={allTickets} ticketId={ticketId} updateTicket={updateTicket} closeTicket={closeTicket} />
							 	) : (
									<List section={section} tickets={tickets} />
							 	)
						  	)}
						</Grid>
					</Grid>
				</Container>
			</React.Fragment>
	 	)
  	}
}
const mapStateToProps = (state) => ({
  	openTickets: selectorLoadTickets(state.memberSupport.supportTickets, false),
  	closedTickets: selectorLoadTickets(state.memberSupport.supportTickets, true),
  	allTickets: state.memberSupport.supportTickets,
  	categories: state.memberSupport.categories,
  	ticketGroups: selectorTicketGroups(state.memberSupport.supportTickets),
  	memberGroups: selectorMemberGroups(state.auth.memberships)
})
const mapDispatchToProps = {
  	startMonitoringSupport, 
  	stopMonitoringSupport,
  	openTicket,
  	updateTicket,
  	closeTicket
}
export default connect(mapStateToProps, mapDispatchToProps)(MemberSupportContainer)
