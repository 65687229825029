import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import countryList from '../../../includes/countryList'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

class ProfileView extends React.Component {
	handleGoToEdit = () => {
		let path = '/member/profile/Edit-Profile'
		this.props.history.push({ pathname: path })
	}
	render(){
		const {setPassword} = this.props
		const thumb = this.props.userInfo.thumb || ''
		const email = this.props.userInfo.email || ''
		const name = this.props.userInfo.name || ''
		const gender = this.props.userInfo.gender || ''
		const nationality = this.props.userInfo.nationality || ''
		const location = this.props.userInfo.location || ''
		const dateOfBirth = (this.props.userInfo.dob) ? moment(this.props.userInfo.dob).format('Do MMM YYYY') : ''
		const nationalityName = (nationality) ? countryList.filter((e) => e.code === nationality)[0].name : ''
		const locationName = (location) ? countryList.filter((e) => e.code === location)[0].name : ''
		 
		if (!email) { return null } 
		return (
			<Paper square style={{marginTop:20}}>
				<div style={{padding:20, marginBottom:50}}>
					<div align='center' style={{ maxWidth: 300, margin:'0 auto'}}>
						<img style={{ overflow: 'hidden', display: 'block', width: '100%', padding: 5, borderRadius: 10, border: '1px solid #ccc' }} 
							src={thumb} 
							alt={thumb} 
						/>
					</div>
					<Grid container justify='center' alignItems='flex-end'>
						<Grid item xs={12} sm={12} style={{ padding: 5, textAlign:'center' }}>
							<Button onClick={() => {setPassword(true)}}>Set Password</Button>
						</Grid>
						<Grid item xs={12} sm={6} style={{ padding: 5, marginTop:20 }}>
							<TextField
								style={{margin:0}}
								fullWidth
								label='Email Address'
								defaultValue={email}
								margin='normal'
								InputProps={{ readOnly: true }}
								variant='filled'
							/>
						</Grid>
						<Grid item xs={12} sm={6} style={{ padding: 5 }}>
							<TextField
								style={{margin:0}}
								fullWidth
								label='Name'
								defaultValue={name}
								margin='normal'
								InputProps={{ readOnly: true }}
								variant='filled'
							/>
						</Grid>
						<Grid item xs={12} sm={6} style={{ padding: 5 }}>
							<TextField
								style={{margin:0}}
								fullWidth
								label='Gender'
								defaultValue={gender}
								margin='normal'
								InputProps={{ readOnly: true }}
								variant='filled'
							/>
						</Grid>
						<Grid item xs={12} sm={6} style={{ padding: 5 }}>
							<TextField
								style={{margin:0}}
								fullWidth
								label='Date of Birth'
								defaultValue={dateOfBirth}
								margin='normal'
								InputProps={{ readOnly: true }}
								variant='filled'
							/>
						</Grid>
						<Grid item xs={12} sm={6} style={{ padding: 5 }}>
							<TextField
								style={{margin:0}}
								fullWidth
								label='Nationality'
								defaultValue={nationalityName}
								margin='normal'
								InputProps={{ readOnly: true }}
								variant='filled'
							/>
						</Grid>
						<Grid item xs={12} sm={6} style={{ padding: 5 }}>
							<TextField
								style={{margin:0}}
								fullWidth
								label='Location'
								defaultValue={locationName}
								margin='normal'
								InputProps={{ readOnly: true }}
								variant='filled'
							/>
						</Grid>
					</Grid>
					<div style={{marginTop:20}}><Button fullWidth variant='contained' color='secondary' onClick={this.handleGoToEdit}>edit profile</Button></div>
				</div>
			</Paper>
		)
	}
}
export default withRouter(ProfileView)
