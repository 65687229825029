import React from 'react'
import ManageGuestListAdd from './ManageGuestListAdd'
import ManageGuestListImport from './ManageGuestListImport'

import { withStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
//import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import IconPersonAdd from '@material-ui/icons/PersonAdd'
import IconSubdirectoryArrowRight from '@material-ui/icons/SubdirectoryArrowRight'
import IconSearch from '@material-ui/icons/Search'
import IconSettings from '@material-ui/icons/SortByAlpha'
import InputAdornment from '@material-ui/core/InputAdornment'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
//import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
//import ListSubheader from '@material-ui/core/ListSubheader'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

const useStyles = (theme) => ({
  textField: {
  	padding:0, margin:0, marginTop:10
  },
  VIP:{
  	color: 'white',
  	backgroundColor: '#ff8080',
  	padding: '1px 5px',
  	textColor: 'white',
  	borderRadius: 5,
  	marginRight: 5
  },
  Official:{
  	color: 'white',
  	backgroundColor: '#ff8080',
  	padding: '1px 5px',
  	textColor: 'white',
  	borderRadius: 5,
  	marginRight: 5
  }
})

class ManageGuestList extends React.Component {
	state = {
		addGuest: false,
		importGuest: false,
		guest: undefined,
		guestOfId: undefined,
		guestOfName: undefined,
		guestsTotal: 0,
		title: 'Add Guest',
		guestWindowHeight: 300
	}
//	componentDidUpdate(prevProps, prevState){
//		if (this.props.match.params !== prevProps.match.params) {
//	}



	editGuest = (guest) => {
		this.setState({
			title: 'Edit Guest', 
			guest: guest, 
			guestOfId: guest.guestOfId, 
			guestOfName: guest.guestOfName, 
			addGuest: true
		})
	}
	
	newGuest = (guestOfId,guestOfName,guestsTotal) => {
		this.setState({
			title: 'Add Guest',
			guest: undefined,
			guestOfId: guestOfId,
			guestOfName: guestOfName,
			guestsTotal: guestsTotal,
			addGuest: true
		})
	}
	
	toggleAddGuest = () => {
		this.setState({addGuest: !this.state.addGuest})
	}
	
	toggleImportGuest = () => {
		this.setState({importGuest: !this.state.importGuest})
	}
	
	setGuestWindowHeight = (height) => {
		this.setState({guestWindowHeight: height})
	}
	
	render() {
		const {addGuest, importGuest, guest, guestOfId, guestOfName, guestsTotal, title, guestWindowHeight} = this.state
		const {classes, eventId, guests, guestlistAddEdit, guestlistDelete, guestlistImport} = this.props
	    const guestCount = (guests) ? Object.keys(guests).length : 0
	    return (
	    	<React.Fragment>
	    		{(addGuest) ? (
		    		<ManageGuestListAdd 
		    			open={addGuest} toggle={this.toggleAddGuest} title={title} 
		    			eventId={eventId} guest={guest} guestOfId={guestOfId} guestOfName={guestOfName} guestsTotal={guestsTotal}
		    			guestlistAddEdit={guestlistAddEdit} guestlistDelete={guestlistDelete}
		    		/>
		    	) : (null)}
		    	{(importGuest) ? (
		    		<ManageGuestListImport 
		    			open={importGuest} 
		    			toggle={this.toggleImportGuest} 
		    			guestlistImport={guestlistImport}
		    		/>
	    		) : (null)}
	        	<Paper style={{marginTop:0, marginBottom:0, width:'100%'}}>
	        		<List style={{width: '100%', backgroundColor:'#f1f1f1', paddingTop:0, paddingBottom:0}}>
	        			<ListItem>
	        				GUESTLIST&nbsp;&nbsp; 
	        				<Text className={classes.secondaryHeading}> <i> {(guestCount === 1) ? '(1 Guest)' : '('+ guestCount + ' Guests)' }</i></Text>
							<ListItemSecondaryAction>
	              				<IconButton edge='end' size='small' style={{color: '#cccccc'}}><IconSettings/></IconButton>
	              				<IconButton edge='end' onClick={() => {this.newGuest()}}><IconPersonAdd/></IconButton>
	            			</ListItemSecondaryAction>
	        			</ListItem>
	        		
						<div style={{paddingLeft:20, paddingRight:20, paddingBottom:5}}>
						<TextField
							fullWidth
							InputProps={{
					          startAdornment: (
					            <InputAdornment position="start" style={{color: '#ccc'}}>
					              <IconSearch />
					            </InputAdornment>
					          ),
					        }}
					        id="outlined-dense"
					        label="Search"
					        margin="dense"
					        variant="outlined"				        
					     />
						</div>
					</List>

	        		<List dense={true} style={{margin:0, width: '100%', position: 'relative', overflow: 'auto', maxHeight: guestWindowHeight}}>
	        			{(!guests.length) ? (
	        				<ListItem style={{padding:20, textAlign:'center'}}>
	        					<ListItemText>NO GUESTS LISTED</ListItemText></ListItem>
	        			) : (
	        				guests.map((guest) => {
								let initials = guest.firstName.toString().substr(0, 1).toUpperCase() + guest.lastName.toString().substr(0, 1).toUpperCase()
		        				let highlight = (guest.highlight !== '') ? <span className={classes[guest.highlight]}>{guest.highlight}</span> : ''
		        				let guestCount = (guest.guests) ? ((guest.guests === 1) ? '+1 Guest' : '+' + guest.guests + ' Guests') : ''
								
								if (guest.guestOfId !== guest.guestId) {        				
			        				return (
										<div key={guest.guestId}>
						        			<div>
							        			<ListItem button onClick={() => {this.editGuest(guest)}}>
							        				<IconSubdirectoryArrowRight style={{margin:10, color:'#ccc'}} />
							        				<ListItemAvatar>
									                    <Avatar style={{backgroundColor:'#ffcccc', width: 35, height:35}}>{initials}</Avatar>
									                </ListItemAvatar>
							        				<ListItemText 
							        					primary={guest.firstName + ' ' + guest.lastName} 
							        					secondary={<span>{highlight}{guestCount}</span>} 
							        				/>
							            			<ListItemSecondaryAction>
							              				<IconPersonAdd style={{color:'#ccc'}}/>
							            			</ListItemSecondaryAction>
							        			</ListItem>
						        			</div>
						        			<Divider />
					        			</div>
				        			)
		        				} else {
		        					return (
										<div key={guest.guestId}>
						        			<ListItem button onClick={() => {this.editGuest(guest)}}>
						        				<ListItemAvatar>
								                    <Avatar style={{backgroundColor:'#ffb3b3'}}>{initials}</Avatar>
								                </ListItemAvatar>
						        				<ListItemText 
						        					primary={guest.firstName + ' ' + guest.lastName} 
						        					secondary={<span>{highlight}{guestCount}</span>} 
						        				/>
						            			<ListItemSecondaryAction>
						              				<IconButton edge='end' onClick={() => {this.newGuest(guest.guestId, guest.firstName+' '+guest.lastName, guest.guests)}}><IconPersonAdd/></IconButton>
						            			</ListItemSecondaryAction>
						        			</ListItem>
						        			<Divider />
					        			</div>
			        				)
		        				}
	        				})
	        			)}
	    			</List>

	    			<List style={{width: '100%', backgroundColor:'#f1f1f1'}}>
	        			<ListItem>
		        			<Button onClick={() => {this.toggleImportGuest(true)}}>import / export</Button>
		        			<ListItemSecondaryAction>
		      					{(guestWindowHeight === 300) ? (
		      						<Button onClick={() => {this.setGuestWindowHeight(800)}}>show more</Button>
		      					) : (
		      						<Button onClick={() => {this.setGuestWindowHeight(300)}}>show less</Button>
		      					)}	      					
		    				</ListItemSecondaryAction>
		    			</ListItem>
	        		</List>
	        	</Paper>
	        </React.Fragment>
	    )
	}
}
export default withStyles(useStyles)(ManageGuestList)
