import React from 'react'
import Notifications from '../../components/Notifications'
import Posts from '../../components/Posts'

function MemberDashboardSummary({notifications, posts, handleOpenGroup, openNotificationToggle}) {
	let notificationsArray = []
	let postsArray = []

	// create array of unviewed notifications
	Object.keys(notifications).forEach(function (i) {
      if (!notifications[i].viewed) {
         let temp = notifications[i]
         temp.id = i
         notificationsArray.push(temp)
      }
   })
   // sort then select first 3
	notificationsArray = notificationsArray.sort(function(a, b){
      if (a.timestamp > b.timestamp) return -1;
      if (a.timestamp < b.timestamp) return 1;
      return null
   }).slice(0,3)

	// create array of posts
	Object.keys(posts).forEach(function (i) {
         let temp = posts[i]
         temp.id = i
         postsArray.push(temp)
   })
   // sort then select first 4
	postsArray = postsArray.sort(function(a, b){
      if (a.timestamp > b.timestamp) return -1;
      if (a.timestamp < b.timestamp) return 1;
      return null
   }).slice(0,4)

	return (
		<div style={{ marginTop: 20, marginBottom: 100 }}>
			<Notifications notifications={notificationsArray} handleOpenGroup={handleOpenGroup} openNotificationToggle={openNotificationToggle}/>
			<Posts posts={postsArray}/>
		</div>
	)
}
export default MemberDashboardSummary
