import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Summary from './Summary'
import EventCheckin from './EventCheckin'

function GroupScannerContainer() {
    return (
        <Switch>
    
          	<Route path='/group/scanner/event-checkin' render={props => <EventCheckin {...props} />} />
          	<Route path='/group/scanner' render={props => <Summary {...props} />} />
        </Switch>
    )
}
export default GroupScannerContainer
