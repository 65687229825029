import React from 'react'
import { withRouter } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Hidden from '@material-ui/core/Hidden'
import IconFilterList from '@material-ui/icons/FilterList'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Text from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'

function SectionTopNav(props){
	const [anchorEl, setAnchorEl] = React.useState(null)

	const handleChange = (value) => {
		setAnchorEl(null)
		let path = '/group/members/' + value
		props.history.push({ pathname: path })
	}
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}    
	const handleClose = () => {
		setAnchorEl(null)
	}
	const handleFullMenu = () => {
		setAnchorEl(null)
		props.sideNavToggle()
	}
	const section = props.section
	return (
		<Toolbar variant="dense" style={{ paddingLeft:5, display: 'flex', borderBottom: '2px solid #ccc'}}>
			<Hidden smUp>
				<Button size="small" onClick={handleClick}>
               <IconFilterList style={{marginRight:5}}/> {section.replace(/-/g, ' ').replace(/\+/g, ' & ')}
            </Button>
			</Hidden>
			<Menu keepMounted anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
				<MenuItem selected={section === 'Summary'} onClick={() => {handleChange('Summary')}}>Summary</MenuItem>
				<MenuItem selected={section === 'Members'} onClick={() => {handleChange('Members')}}>Members</MenuItem>
				<MenuItem selected={section === 'Lists+Filters'} onClick={() => {handleChange('Lists+Filters')}}>Lists & Filters</MenuItem>
				<MenuItem selected={section === 'Add-Members'} onClick={() => {handleChange('Add-Members')}}>Add Members</MenuItem>
				<Divider style={{height:3}}/>
				<MenuItem selected={section === 'Team'} onClick={() => {handleChange('Team')}}>Team</MenuItem>
				<MenuItem selected={section === 'Crews'} onClick={() => {handleChange('Crews')}}>Crews</MenuItem>
				<MenuItem selected={section === 'Notes'} onClick={() => {handleChange('Notes')}}>Notes</MenuItem>
				<Divider />
				<MenuItem onClick={handleFullMenu}>View Full Menu</MenuItem>
			</Menu>
			<Hidden xsDown>
				<Text variant='h6' color='primary' style={{paddingLeft:5}}>{section.replace(/-/g, ' ').replace(/\+/g, ' & ')}</Text>
			</Hidden>
		</Toolbar>
	)
}
export default withRouter(SectionTopNav)
