import React from 'react'
import { connect } from 'react-redux'
import { createGroup } from '../../../redux/member/actions'

import GroupProfilePage from '../../components/GroupProfilePage/'
import SubNav from './components/Subnav'
import SectionTopNav from './components/SectionTopNav'
import SectionSideNav from './components/SectionSideNav'
import New from './New'
import List from './List'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'

class MemberMembershipsContainer extends React.Component {
	state = {
		section: 'Active',
		groupId: undefined,
		memberships: []
	}
	componentDidMount = () => {
		this.setSort()
	}
	componentDidUpdate = (prevProps) => {
		if (this.props.match.params !== prevProps.match.params) {
			this.setSort()		
		}
	}  
	setSort = () => {
		let section = (this.props.match.params.section) ? this.props.match.params.section : 'Active'
		let groupId = (this.props.match.params.groupId) ? this.props.match.params.groupId : undefined
		let memberships = this.props.memberships
		let m = undefined

		switch (section) {
			case 'Roles':
				m = memberships.filter((membership) => { return membership.rolesCount > 0 })
				break
			case 'Inactive':
				m = memberships.filter((membership) => { return membership.status !== 'approved' })
				break
			default:
				m = memberships.filter((membership) => { return membership.status === 'approved' })
		}
		memberships = m.sort((a, b) => {
         if (a.groupName === b.groupName) 
            return 0
         return a.groupName < b.groupName ? -1 : 1
      })
		this.setState({ section:section, groupId:groupId, memberships:memberships })
	}

	handleOpenGroup = (groupId) => {
		let url = '/member/memberships/' + this.state.section + '/' + groupId
		this.props.history.push({ pathname: url })
	}	
	handleCloseGroup = () => {
		let url = '/member/memberships/' + this.state.section
		this.props.history.push({ pathname: url })        
	}				
	render() {		
		const {section, groupId, memberships} = this.state
		const {createGroup, sideNavToggle} = this.props

		return (
			<React.Fragment>
				{(groupId) ? (
            	<GroupProfilePage open={Boolean(groupId)} toggleGroup={this.handleCloseGroup} groupId={groupId} />    
            ) : ( null)}

				<Container maxWidth='lg' style={{ padding: 10, marginBottom: 150, minHeight:700 }}>
					<Grid container justify='center' spacing={2}>
						<Grid item xs={12} sm={12} md={10} >
							<SubNav/>
						</Grid>
						<Hidden xsDown>
							<Grid item sm={4} md={3}>
							  <SectionSideNav section={section} sideNavToggle={sideNavToggle}/>
							</Grid>
						</Hidden>
						<Grid item xs={12} sm={8} md={7}>
							<SectionTopNav section={section} sideNavToggle={sideNavToggle}/>
							{(section === 'Create-A-Group') ? (
								<New createGroup={createGroup} />
							) : (
								<List memberships={memberships} handleOpenGroup={this.handleOpenGroup} />
							)}
						</Grid>
					</Grid>
				</Container>
			</React.Fragment>
		)
	}
}
const mapStateToProps = (state) => ({
	memberships: state.auth.memberships
})
const mapDispatchToProps = {
	createGroup
}
export default connect(mapStateToProps, mapDispatchToProps)(MemberMembershipsContainer)
