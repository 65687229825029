import React from 'react'
import { withRouter } from 'react-router-dom'

import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function Reject({history, data, logOut}){
	const setupAccount = () => {
		history.push({ pathname: '/member/setup' })
	}
	return (
		<Container maxWidth='xl' style={{ padding: 10 }}>
		<Grid container justify='center' spacing={2}>
			<Grid item xs={12} sm={8} md={8} >
				<Paper style={{ padding: 20 }} >
					<Text variant="h4">COMPLETE</Text>
					<Text variant="h6">
						The invitation to join {data.groupName.toUpperCase()} as a {data.role.toUpperCase()} was cancelled.
					</Text>
					<Divider style={{margin:5}} />
					<div style={{marginTop:20}}>
						<Button onClick={logOut} variant="outlined" size="small" color="primary" style={{margin:15}}>Logout of LoyalTT</Button>
						<Button onClick={setupAccount} variant="contained" size="small" color="secondary" style={{margin:15}}>Setup My LoyalTT Account</Button>
					</div>
				</Paper>
			</Grid>
		</Grid>
		</Container>
	)
}
export default withRouter(Reject)
