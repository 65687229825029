import React from 'react'

import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function GroupSettingsEdit() {
	return (
		<Paper style={{ padding: 20, marginTop:20, marginBottom:150 }} >
			<Text variant='h6' gutterBottom>
				PAYMENT OPTIONS
			</Text>
			<Text variant='caption' gutterBottom>
				- Payment Options accepted by group<br/>
				- default to OSL Credit Card Processing<br/>
				- user could setup paypal, strip, etc
			</Text>
		</Paper>
	)
}
export default GroupSettingsEdit
