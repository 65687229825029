import React from 'react'
import { chkPassword }  from '../../../../includes/functions'

import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Info from '@material-ui/icons/InfoOutlined'
import InputAdornment from '@material-ui/core/InputAdornment'
import Text from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

class Password extends React.Component {    
    state = {
        showPassword: false,
        showInfo: false,
        message: '',
        old: '',
        password: '',
        confirm: ''
    }

    handleChange = (e) => {
        this.setState({[e.target.id]: e.target.value, message: null})
    }

    closeDialog = () => {
        this.props.setPassword(false)
    }

    savePassword = () => {
        if (this.props.getOldPassword && !chkPassword(this.state.old)) {
            return this.setState({ message:'Please enter your Current Password.' })
        }
        if (!chkPassword(this.state.password)) {
            return this.setState({ message:'Please enter a valid Password.' })
        }
        if (this.state.password !== this.state.confirm) {
            return this.setState({ message:'Password and Confirm must match.' })
        }
        this.props.updatePassword(this.state.old, this.state.password)
        .catch((error) => {
            this.setState({ message:error })
        })
    }

    render() {
        const {showPassword, showInfo, message, 
            //old, password, confirm
        } = this.state
        const {showPwBox, getOldPassword} = this.props
        return (
            <Dialog style={{zIndex:10}} scroll='body' open={showPwBox} onClose={this.closeDialog} disableBackdropClick={true} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title" style={{paddingBottom:0}}>Set Password</DialogTitle>
                <DialogContent>
                    {(getOldPassword) ? (
                        <div>
                            <DialogContentText style={{margin:0}}>
                            To set your password you must either enter your current password or re-login through your email.
                            </DialogContentText>
                        </div>
                    ) : (
                        <DialogContentText style={{margin:0}}>
                        Set an optional password to log into your account. If not you can always login to your account 
                        through your email.
                        </DialogContentText>
                    )}
                    <form>
                    <Grid container justify='center'>
                        {(getOldPassword) ? (
                            <Grid item xs={12} sm={12} style={{ padding: 5 }}>
                                <TextField
                                    id='old'
                                    type={showPassword ? 'text' : 'password'}
                                    label='Current Password'
                                    onChange={this.handleChange}
                                    fullWidth={true}
                                    margin='normal'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                            <IconButton
                                                aria-label='Toggle password visibility'
                                                onClick={() => {this.setState({ showPassword: !showPassword })}}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        ) : (
                            null
                        )}
                        <Grid item xs={12} sm={6} style={{ padding: 5 }}>
                            <TextField
                                id='password'
                                type={showPassword ? 'text' : 'password'}
                                label='New Password'
                                onChange={this.handleChange}
                                fullWidth={true}
                                margin='normal'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                        <IconButton
                                            aria-label='Toggle password visibility'
                                            onClick={() => {this.setState({showPassword: !showPassword})}}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ padding: 5 }}>
                            <TextField
                                id='confirm'
                                type={showPassword ? 'text' : 'password'}
                                label='Confirm'
                                onChange={this.handleChange}
                                fullWidth={true}
                                margin='normal'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                        <ClickAwayListener onClickAway={() => {this.setState({ showInfo: false })}}>
                                            <span>
                                                <Tooltip
                                                PopperProps={{ disablePortal: false }}
                                                onClose={() => {this.setState({showInfo: false})}}
                                                open={showInfo}
                                                disableFocusListener
                                                disableHoverListener
                                                disableTouchListener
                                                placement='top-end'
                                                title='password must be at least 6 characters long.'
                                                > 
                                                <IconButton aria-label='password must be at least 6 characters long.' onClick={() => {this.setState({ showInfo: !showInfo })}}>
                                                    <Info />
                                                </IconButton>
                                                </Tooltip>
                                            </span>
                                        </ClickAwayListener>
                                        </InputAdornment>
                                    )
                                }}
                            />                    
                        </Grid>
                    </Grid>
                    </form>
                    <div align='center' style={{paddingTop:5}}>
                        <Text variant='caption' style={{ color: 'red' }}>{message}</Text>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {this.savePassword()}} variant='contained' color='secondary'>Save Password</Button>
                    <Button onClick={this.closeDialog} color='primary'>Cancle</Button>
                </DialogActions>
            </Dialog>
        )
    }
}
export default Password
