import React from 'react'
import queryString from 'query-string'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { startMonitoringMembers, stopMonitoringMembers, addMembers, addTeamMember } from '../../../redux/groupMembers/actions'
import { selectorLoadTeam } from '../../../redux/groupMembers/selectors'

import AccessDenied from '../../components/AccessDenied'
import Loader from '../../components/Loader'
import MemberProfilePage from '../../components/MemberProfilePage/'
import SubNav from './components/Subnav'
import SectionSideNav from './components/SectionSideNav'
import SectionTopNav from './components/SectionTopNav'

import AddMembers from './AddMembers'
import Crews from './Crews'
import ListsAndFilters from './ListsAndFilters'
import Members from './Members'
import Notes from './Notes'
import Summary from './Summary'
import Team from './Team'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'

const whoHasAccess = [1,2,6]
class GroupMembersContainer extends React.Component {
	state = {
		loading: true,
		section: 'Summary',
		filter: 'All',
		memberId: undefined,
		members: {},
		selectedRole: '' // used by Team
	}
	componentDidMount = () => {
	 	let _this = this
	 	this.props.startMonitoringMembers()
	 	.then(() => {
	 		_this.sortFilter()
	 	})	
	}
	componentDidUpdate = (prevProps) => {
	 	if (this.props.match.params !== prevProps.match.params) {
			this.sortFilter()
	 	}
	}
	componentWillUnmount = () => {
		this.props.stopMonitoringMembers()
	}
	sortFilter = (pageChanged) => {
		let section = (this.props.match.params.section) ? this.props.match.params.section : 'Summary'
		let filter = (this.props.match.params.filter) ? this.props.match.params.filter : 'All'
		let memberId = (this.props.match.params.memberId) ? this.props.match.params.memberId : undefined
		let members = this.props.allMembers

		// [START]: Check for QueryString :::::::::::::
			let url = window.location.href.split('?')[1]
			let qs = queryString.parse(url)
			let selectedRole = (qs.r) ? qs.r : ''
		// [END]: Check for QueryString :::::::::::::::

		this.setState({ loading:false, section:section, filter:filter, memberId:memberId, members:members, selectedRole:selectedRole })
	}

   handleOpenMember = (memberId) => {
      let url = '/group/members/' + this.state.section + '/' + this.state.filter + '/' + memberId
      this.props.history.push({ pathname: url })
   }
    
   handleCloseMember = () => {
      let url = '/group/members/' + this.state.section + '/' + this.state.filter
      if (this.state.selectedRole) {
      	url = url + '?r=' + this.state.selectedRole
      } 
      this.props.history.push({ pathname: url })        
   }

	render() {
		const {loading, section, filter, memberId, members, selectedRole } = this.state
		const {roleId, teamMembers} = this.props

		if(!whoHasAccess.includes(this.props.roleId)) {
			return (<AccessDenied />)
		}

		return (
			<React.Fragment>
				<Loader loading={loading} />
				<MemberProfilePage open={Boolean(memberId)} handleCloseMember={this.handleCloseMember} memberId={memberId} />
				<Container maxWidth='lg' style={{ padding: 10, marginBottom: 150, minHeight:700 }}>
					<Grid container justify='center' spacing={2}>
						<Grid item xs={12} sm={12} md={10} >
							<SubNav section={section}/>
						</Grid>
						<Hidden xsDown>
							<Grid item sm={4} md={3}>
							  <SectionSideNav section={section} sideNavToggle={ this.props.sideNavToggle }/>
							</Grid>
						</Hidden>
						<Grid item xs={12} sm={8} md={7}>
							<SectionTopNav section={section} sideNavToggle={ this.props.sideNavToggle }/>
							<Switch>
								<Route path='/group/members/Add-Members' render={props => <AddMembers {...props}
										addMembers={this.props.addMembers}          
									/>} 
								/>
								<Route path='/group/members/Crews' render={props => <Crews {...props} 
										roleId={roleId}
										filter={filter}          
									/>} 
								/>
								<Route path='/group/members/Lists+Filters' render={props => <ListsAndFilters {...props} 
										filter={filter}          
									/>} 
								/>
								<Route path='/group/members/Members' render={props => <Members {...props} 
										roleId={roleId}
										filter={filter}
										members={members}
										handleOpenMember={this.handleOpenMember}
									/>} 
								/>
								<Route path='/group/members/Team' render={props => <Team {...props} 
										roleId={roleId}
										filter={filter}
										selectedRole={selectedRole}
										teamMembers={teamMembers}       
										addTeamMember={this.props.addTeamMember}
									/>} 
								/>
								<Route path='/group/members/Notes' render={props => <Notes {...props}
										roleId={roleId}
									/>}
								/>
								<Route path='/group/members' render={props => <Summary {...props} />} />
							</Switch>						
						</Grid>
					</Grid>
				</Container>
			</React.Fragment>
		)
	}
}
const mapStateToProps = (state) => ({
	roleId: state.auth.loginOptions[state.auth.loginOptionID].roleId,
	allMembers: state.groupMembers.members,
	teamMembers: selectorLoadTeam(state.groupMembers.members),
//	/loyalttMembers: selectorFilterMembers(state.groupMembers.members, 'LoyalTT'),/
//	emailMembers: selectorFilterMembers(state.groupMembers.members, 'Emails'),
//	statusFilter: selectorFilterMemberStatus(state.groupMembers.members),
})
const mapDispatchToProps = {
	startMonitoringMembers, 
	stopMonitoringMembers,
	addMembers,
	addTeamMember
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupMembersContainer)
