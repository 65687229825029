import React from 'react'
//import swal from 'sweetalert2'

import Loader from '../../components/Loader'
import TeamAccept from './components/TeamAccept'
import TeamLanding from './components/TeamLanding'
import TeamReject from './components/TeamReject'
import TeamError from './components/TeamError'

//import Container from '@material-ui/core/Container'
//import Grid from '@material-ui/core/Grid'
//import Paper from '@material-ui/core/Paper'
//import Text from '@material-ui/core/Typography'

class MemberJoinTeam extends React.Component {
	state = {
		loading:true,
		step: 'landing',
		message: '',
		data: undefined,    //fetched membership data
		id: undefined  
	}
	componentDidMount = () => {
	   this.setVars()
	}
	setVars = () => {
		let _this = this
		let id = (this.props.match.params.id) ? this.props.match.params.id : ''

		this.props.getTeamRole(id)
		.then((data) => {
			console.log('data',data)
			_this.setState({loading:false, id:id, data:data})
		})
		.catch((error) => {
			_this.setState({loading:false, id:id, step:'error', message:error})
		})
	}
	acceptRole = (accept) => {
		let _this = this
		let id = this.state.id
		this.setState({loading:true})
		this.props.acceptTeamRole(id, accept)
		.then((data) => {
			if (accept) {
				_this.setState({loading:false, step:'accept'})
			} else{
				_this.setState({loading:false, step:'reject'})
			}
		})
		.catch((error) => {
			_this.setState({loading:false, step:'error', message:error})
		})
	}
	handleTryAgain = () => {
		this.setState({step:'landing'})
		this.setVars()
	}
	render() {
		const {logOut} = this.props
		const {loading, step, message, data} = this.state
		return (
			<div>
				<Loader loading={loading}/>
				{(step === 'landing') ? (
					<TeamLanding data={data} acceptRole={this.acceptRole}/>
				):( null )}
	
				{(step === 'error') ? (
					<TeamError tryAgain={this.handleTryAgain} message={message}/>
				):( null )}

				{(step === 'reject') ? (
					<TeamReject data={data} logOut={logOut}/>
				):( null )}
				
				{(step === 'accept') ? (
					<TeamAccept data={data} logOut={logOut}/>
				):( null )}
			</div>
		)
	}
}
export default MemberJoinTeam
