import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
//import Button from '@material-ui/core/Button'
//import IconButton from '@material-ui/core/IconButton'
import IconEdit from '@material-ui/icons/RateReview'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'


import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
//import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';

import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles(theme => ({
		thumb: {
				width: 65,
				height: 65, 
				verticalAlign: 'top', 
				marginRight: 10,
				border: '1px solid #ccc', 
				borderRadius: 7
		}
}))

const Registered = ({status}) => {
	let word = (status === 'registered') ? 'REGISTERED' : 'NOT REGISTERED'
	let color = (status === 'registered') ? '#4caf50' : '#fbc02d'
	return (
		<Text style={{ lineHeight:1.4}} variant='caption' display='block' color='textSecondary' >
			<b>LoyalTT: </b>
			<span style={{backgroundColor:color, padding:'1px 5px', borderRadius:5, color:'white', fontSize:10, fontWeight:'bold'}}>{word}</span>
		</Text>
	)
}

const Status = ({status}) => {
	let word = status.toUpperCase()
	let color = '#fbc02d'
	if (status === 'approved') {
			color = '#4caf50'
	}
	return (
		<Text style={{ lineHeight:1.4}} variant='caption' display='block' color='textSecondary' >
			<b>Group Status: </b>
			<span style={{color:color, fontSize:10, fontWeight:'bold'}}>{word}</span>
		</Text>
	)
}

const Roles = ({roles}) => {
	let rolesArray = []
	Object.keys(roles).map((i, key) =>{
		return rolesArray.push(roles[i]['role'].replace(/_/g, ' '))
	})
	if (rolesArray.length) {
		let list = rolesArray.join(', ')
		return (
			<Text style={{ lineHeight:1.4}} variant='caption' display='block' color='textSecondary' >
				<b>Roles: </b>
				{list}      
			</Text>
		)
	} else {
		return null
	}
}

const RoleStatus = ({status}) => {
	let word = status.toUpperCase()
	let color = '#fbc02d'
	if (status === 'active') {
			color = '#4caf50'
	}
	return (
		<Text style={{ lineHeight:1.4}} variant='caption' display='block' color='textSecondary' >
			<b>Role Status: </b>
			<span style={{color:color, fontSize:10, fontWeight:'bold'}}>{word}</span>
		</Text>
	)
}

const MemberCard = (props) => {
	const style = useStyles()
	const {members, handleOpenMember} = props
	const showRoles = (props.showRoles) ? props.showRoles : false
	const showRoleStatus = (props.showRoleStatus) ? props.showRoleStatus : false

	if(!members) return null
	return (
		(Object.keys(members).length) ? (
			<Paper style={{marginTop:20 }} >
			<List dense>
		      {Object.keys(members).map((i, key) =>{
		         return (
		         	<div key={key}>
				         <ListItem button style={{padding:10}} onClick={() => { handleOpenMember(members[i].memberId) }}>
				            <ListItemAvatar>
				              <Avatar alt={members[i].memberName} src={members[i].memberThumb} className={style.thumb}/>
				            </ListItemAvatar>

				            
				            <ListItemText style={{overflow:'hidden'}}>
                         <Text><b>{members[i].memberName}</b></Text>
                         <Text style={{color:'#b0bec5',fontSize:12,fontStyle:'italic',lineHeight:1}}>{members[i].memberEmail}</Text>
                         

                        {(showRoles) ? (
									<Roles roles={members[i].roles}/>
								):( null )}
								{(showRoleStatus) ? (
									<RoleStatus status={props.roleStatus}/>
								):( null )}

                         <Hidden mdUp>
                           <Registered status={members[i].memberStatus}/>
										<Status status={members[i].status}/>
                         </Hidden>
                       </ListItemText>
                       
                       	<Hidden smDown>
                         	<ListItemText style={{overflow:'hidden'}}>
                           	<Registered status={members[i].memberStatus}/>
										<Status status={members[i].status}/>
								  	</ListItemText>
                       	</Hidden>
				            



				            <ListItemSecondaryAction>
				            	<IconEdit style={{color: '#c0c0c0'}} />
								</ListItemSecondaryAction>
				         </ListItem>
				         <Divider/>
			         </div>
		         )
		      })}
		   </List>
		   </Paper>
		) : (
			<Paper style={{marginTop:20, padding:10, textAlign:'center' }} >
				<Text variant='overline' display='block'>Filter Returned No Members</Text>
			</Paper>
		)
	)
}
export default MemberCard
