import React from 'react'
import moment from 'moment'
import {  withRouter } from 'react-router-dom'

import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import IconArrowForward from '@material-ui/icons/ArrowForwardIos'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function GroupSupportList({history, section, filter, tickets}) {
	const statusColor = {'unassigned':'#999', 'open':'#27e007', 'closed':'red', 'archived':'#000'}

	const openTicket = (id) => {
		let url = '/group/support/' + section + '/' + filter + '/' + id
		history.push({ pathname: url })
	}
	
	return (
		<div style={{marginTop:20, marginBottom:150, textAlign:'right'}}>
		{(Object.keys(tickets).length) ? (
			<List style={{width:'100%', backgroundColor:'#fff', padding:5}}>
			{Object.keys(tickets).map((i, key) => {
				let status = tickets[i].status
				return (
					<div key={key}>
						<ListItem button alignItems="flex-start" onClick={() => {openTicket(i)}}>
							<ListItemAvatar>
							   <Avatar alt={tickets[i].memberName} src={tickets[i].memberThumb} style={{borderRadius:5}} />
							</ListItemAvatar>
							<ListItemText>
								<b>{tickets[i].memberName}</b>
								<Text style={{lineHeight:.8}} variant='caption' display='block' color='textSecondary'>
									{moment.unix(tickets[i].timestamp.seconds).format('MMM Do YYYY - h:mm a')}
								</Text>
								<Text style={{marginTop:5, lineHeight:1, color:'#546e7a'}}><b>{tickets[i].subject}</b></Text>
								<Text style={{lineHeight:1.5, color:statusColor[status]}}><b>{status.toUpperCase()}</b></Text>
							</ListItemText>
							<ListItemSecondaryAction>
							   <IconArrowForward />
							</ListItemSecondaryAction>
						</ListItem>
						<Divider/>                
					</div>
				)
			})}
			</List>
	  	) : (
			<Paper style={{marginTop:20, padding: 20 }} >
				<div style={{padding:20, textAlign:'center'}}>
					<Text variant='caption'>NO {section.toUpperCase()} TICKETS FOUND</Text>
				</div>
			</Paper>
	  	)}   
	  	</div>             
	)
}
export default withRouter(GroupSupportList)
