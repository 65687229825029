import React from 'react'
import moment from 'moment'
import {  withRouter } from 'react-router-dom'

import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import IconArrowForward from '@material-ui/icons/ArrowForwardIos'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function MemberNotificationList({history, section, notifications}) {
	const openNote = async (id) => {
		let url = '/member/notifications/' + section + '/' + id
		history.push({ pathname: url })
	}
	return (
	   (Object.keys(notifications).length) ? (
			<List style={{marginTop:20, width:'100%', backgroundColor:'#fff', padding:5, marginBottom:150}}>
			{Object.keys(notifications).map((i) => {
				let textColor = (notifications[i].viewed) ? 'black' : 'red'
				return (
				   <div key={i}>
						<ListItem button alignItems="flex-start" onClick={() => {openNote(i)}}>
							<ListItemAvatar>
								<Avatar alt={notifications[i].groupName} src={notifications[i].groupThumb} style={{borderRadius:5}} />
							</ListItemAvatar>
							<ListItemText>
								{notifications[i].groupName}
								<Text style={{lineHeight:.8}} variant='caption' display='block' color='textSecondary'>
									{moment.unix(notifications[i].timestamp.seconds).format('MMM Do YYYY')}
								</Text>
								<Text style={{marginTop:5, lineHeight:1, color:textColor}}><b>{notifications[i].subject}</b></Text>
							</ListItemText>
							<ListItemSecondaryAction>
								<IconArrowForward />
							</ListItemSecondaryAction>
						</ListItem>
						<Divider/>                
				   </div>
				)
			})}
			</List>
	   ) : (
			<Paper style={{marginTop:20, padding: 20 }} >
				<div style={{padding:20, textAlign:'center'}}>
					<Text variant='caption'>NO NOTIFICATIONS MATCH CURRENT FILTER</Text>
				</div>
			</Paper>
	   )
	)
}
export default withRouter(MemberNotificationList)
