import React from 'react'

import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function GroupSettingsEdit() {
	return (
		<Paper style={{ padding: 20, marginTop:20, marginBottom:150 }} >
			<Text variant='h6' gutterBottom>
				INVOICES
			</Text>
			<Text variant='caption' gutterBottom>
				- List of Paid and Outstanding Invoices
			</Text>
		</Paper>
	)
}
export default GroupSettingsEdit
