import React from 'react'

function Info(){
   return (
      <div>
      	<b>Membership Info</b><br/>
         <ul>
            <li>Quick reference slides highlighting the features of the MEMBERSHIPS section.</li>
            <li>One minute video overview of MEMBERSHIPS section.</li>
         </ul>
   	</div>
   )
}
export default Info
