import React from 'react'

function Info(){
	return (
	   <div>
			Group Communicate Dialog<br/>
			this should be quick reference slides highlighting the features of Group Communicate.
		</div>
	)
}
export default Info
