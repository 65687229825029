import React from 'react'

import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import IconArrowForward from '@material-ui/icons/ArrowForwardIos'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

const Status = ({status}) => {
  	let word = status.toUpperCase()
  	let color = '#fbc02d'
  	if (status === 'approved') {
		color = '#4caf50'
  	}
  	return (
	 	<Text style={{ lineHeight:1.4}} variant='caption' display='block' color='textSecondary' >
			<b>Membership: </b>
			<span style={{color:color, fontSize:10, fontWeight:'bold'}}>{word}</span>
	 	</Text>
  	)
}

const Roles = ({roles}) => {
  	let rolesArray = []
  	if(roles){
		Object.keys(roles).forEach(key => {
      	rolesArray.push(roles[key]['role'].replace(/_/g, ' '))
   	})
	}
  	if (rolesArray.length) {
	 	let list = rolesArray.join(', ')
	 	return (
			<Text style={{ lineHeight:1.4}} variant='caption' display='block' color='textSecondary' >
		  		<b>Roles: </b>
		  		{list}      
			</Text>
	 	)
  	} else {
	 	return null
  	}
}

function MemberMemberships({history, section, memberships, handleOpenGroup}) {
	return (
	   (memberships.length) ? (
			<List style={{marginTop:20, width:'100%', backgroundColor:'#fff', padding:5, marginBottom:150}}>
			{memberships.map((m) => {
				return (
				   <div key={m.docId}>
						<ListItem button alignItems="flex-start" onClick={() => {handleOpenGroup(m.groupId)}}>
							<ListItemAvatar>
								<Avatar alt={m.groupName} src={m.groupThumb} style={{borderRadius:5}} />
							</ListItemAvatar>
							<ListItemText>
								{m.groupName}
								<Text style={{ lineHeight:1.4}} variant='caption' display='block' color='textSecondary' >
									<b>Group Type: { m.groupType}</b>
								</Text>
								<Status status={m.status}/>
								<Roles roles={m.roles}/> 
							</ListItemText>
							<ListItemSecondaryAction>
								<IconArrowForward />
							</ListItemSecondaryAction>
						</ListItem>
						<Divider/>                
				   </div>
				)
			})}
			</List>
	   ) : (
			<Paper style={{marginTop:20, padding: 20 }} >
				<div style={{padding:20, textAlign:'center'}}>
					<Text variant='caption'>NO MEMBERSHIPS MATCH CURRENT FILTER</Text>
				</div>
			</Paper>
	   )
	)
}
export default MemberMemberships
