import { MEMBER_SUPPORT_LOAD_CATEGORIES, MEMBER_SUPPORT_LOAD_TICKETS } from '../types'

const initialState = {
	lastUpdated: 0,
	categories: [],
	supportTickets: {}
}

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case MEMBER_SUPPORT_LOAD_CATEGORIES:
			return { 
				...state, 
				categories: action.payload
			}
		case MEMBER_SUPPORT_LOAD_TICKETS:
			return { 
				...state, 
				lastUpdated: action.payload.timestamp,
				supportTickets: action.payload.supportTickets
			}
	default:
		return state
	}
}
