import React from 'react'

function Info(){
    return (
        <div>
            Group Dashboard Dialog<br/>
            this should be quick reference slides highlighting the features of Group Dashboard.
        </div>
    )
}
export default Info
