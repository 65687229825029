export const selectorLoadTickets = (tickets, status) => {
    let filteredTickets = {}
    Object.keys(tickets).forEach(function (i) {
        if (tickets[i].closed === status) {
            filteredTickets[i] = tickets[i]
        }
    })
    return filteredTickets
}

export const selectorTicketGroups = (tickets) => {
    let ticketsArray = Object.values(tickets) 
    let list = []
    ticketsArray.map(ticket => {
        return list.push(ticket.groupName.replace(/ /g, '-'))
    })
    list = [...new Set(list)]
    return list
}

export const selectorMemberGroups = (memberships) => {
    let list = {}
    memberships.map(function (group){
        return list[group.groupId] = group.groupName.replace(/ /g, '-')
    })
    return list
}
// https://zellwk.com/blog/looping-through-js-objects/
// https://alligator.io/js/filter-array-method/