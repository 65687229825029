import React from 'react'
import { withRouter } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'

function SectionSideNav(props){
   const handleClick = (value) => {
    	let path = '/group/support/' + value
      props.history.push({ pathname: path })
   }

   const section = props.section
	return (
		<div>
			<List component="nav" style={{backgroundColor:'#fff'}}
				subheader={
		        <ListSubheader component="div" id="nested-list-subheader">
		          	<i>Group Support Menu</i>
		        </ListSubheader>
		      }
			>
		   	<ListItem button selected={section === 'Unassigned'} onClick={() => handleClick('Unassigned')}>
			 		<ListItemText primary="- Unassigned" />
		  		</ListItem>

		  		<ListItem button selected={section === 'Open'} onClick={(event) => handleClick('Open')}>
			 		<ListItemText primary="- Open" />
		  		</ListItem>
		  		
		  		<ListItem button selected={section === 'Closed'} onClick={(event) => handleClick('Closed')}>
			 		<ListItemText primary="- Closed" />
		  		</ListItem>

		  		<ListItem button selected={section === 'Archived'} onClick={(event) => handleClick('Archived')}>
			 		<ListItemText primary="- Archived" />
		  		</ListItem>

		  		<ListItem button selected={section === 'Notes'} onClick={(event) => handleClick('Notes')}>
			 		<ListItemText primary="- Notes" />
		  		</ListItem>
		  		<Divider />
		  		<div align='right'>
		  			<Button onClick={props.sideNavToggle} size='small' style={{color:'#90a4ae'}}>view full menu</Button>
		  		</div>
		  	</List>
		</div>
	)
}
export default withRouter(SectionSideNav)
