import React from 'react'


import Button from '@material-ui/core/Button'
//import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import IconArrowForward from '@material-ui/icons/ArrowForwardIos'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
//import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function GroupCommunicateList({history, posts, filter}) {
	const handleClick = (type,value,id) => {
    	let path = '/group/communicate/' + type + '/' + value + '/' + id
      history.push({ pathname: path })
   }

	return (
		<React.Fragment>
		{(Object.keys(posts).length) ? (
			<List style={{marginTop:20, width:'100%', backgroundColor:'#fff', padding:5}}>
				{Object.keys(posts).map((i) => {
					return (
						<div key={i}>
							<ListItem button alignItems="flex-start" onClick={() => { handleClick('View',filter, posts[i].postId) }}>	
								<ListItemText>
									{posts[i].name}
								</ListItemText>
								<ListItemSecondaryAction>
									<IconArrowForward />
								</ListItemSecondaryAction>
							</ListItem>
							<Divider/>                
				   	</div>
					)
				})}
			</List>
		) : (
			<Paper style={{marginTop:20, padding: 20 }} >
				<div style={{padding:20, textAlign:'center'}}>
					<Text variant='caption'>NO {filter.toUpperCase()} FOUND</Text>
				</div>
			</Paper>
		)}	
		<div style={{marginTop:10, textAlign:'right'}}>
       	<Button onClick={() => { handleClick('New',filter,'') }}>Create New {filter.slice(0, -1)}</Button>
      </div>
		</React.Fragment>
	)
}
export default GroupCommunicateList
