import React from 'react'
import moment from 'moment'
import {  withRouter } from 'react-router-dom'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import IconArrowBack from '@material-ui/icons/ArrowBackIos'
import IconBookmarkOff from '@material-ui/icons/TurnedInNot'
import IconBookmarkOn from '@material-ui/icons/TurnedIn'
import IconButton from '@material-ui/core/IconButton'
import IconDeleteOutline from '@material-ui/icons/DeleteOutline'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function MemberNotificationNotification(props) {
	const {history, section, id, note} = props
	const {openNotificationToggle, saveNotificationToggle, deleteNotification} = props

	if (!note.viewed) {
		openNotificationToggle(id, true)
	}

	const goToList = () => {
		let url = '/member/notifications/' + section
		history.push({ pathname: url })
	}

	const saveNoteToggle = () => {
		saveNotificationToggle(id, !note.saved)
	}

	const deleteNote = () => {
		deleteNotification(id)
		goToList()
	}    

	return (
		<React.Fragment>
			<Paper style={{ padding: 5, marginTop:20}} >
				<List>
					<ListItem alignItems="flex-start">
						<ListItemIcon>
							<IconButton onClick={goToList}>
								<IconArrowBack />
							</IconButton>
						</ListItemIcon>  

						<ListItemSecondaryAction>
							<IconButton onClick={saveNoteToggle} style={{marginRight:5}}>
								{(note.saved) ? (
									<IconBookmarkOn />
								):(
									<IconBookmarkOff />
								)}
							</IconButton>
							{(!note.locked) ? (
								<IconButton onClick={deleteNote}>
									<IconDeleteOutline />
								</IconButton>
							):( null )}
						</ListItemSecondaryAction>
					</ListItem>
					<Divider/>

					<ListItem alignItems="flex-start">
						<ListItemAvatar>
							<Avatar alt={note.groupName} src={note.groupThumb} style={{borderRadius:5}} />
						</ListItemAvatar>
						<ListItemText>
							{note.groupName}
							<Text style={{lineHeight:.8}} variant='caption' display='block' color='textSecondary'>
								{moment.unix(note.timestamp.seconds).format('MMM Do YYYY')}
							</Text>
							<Text style={{marginTop:5, lineHeight:1, color:'#546e7a'}}><b>{note.subject}</b></Text>
						</ListItemText>
					</ListItem>
					<Divider/>

					<div style={{height:200, textAlign:'center'}}>
						A <b>{note.category}</b> template would be loaded here<br/>
						for: {id}<br/>
						template: {note.template}
					</div>
					<Divider/>

					<div style={{textAlign:'right'}}>
						<Button onClick={goToList}>Close</Button>
						<Button onClick={saveNoteToggle}>{(note.saved) ? 'unsave' : 'save'}</Button>
						{(!note.locked) ? (
							<Button onClick={deleteNote}>Delete</Button>
						):( null )}
					</div>
				</List>
			</Paper>
			<div align='center'><Text variant='overline' color='textSecondary' style={{lineHeight:1}}>Note: All viewed unsaved notifications are deleted after 30 days.</Text></div>

		</React.Fragment>
	)
}
export default withRouter(MemberNotificationNotification)
