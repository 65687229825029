import React from 'react'
import { connect } from 'react-redux'
import { startMonitoringNotifications, stopMonitoringNotifications, saveNotificationToggle, openNotificationToggle, deleteNotification } from '../../../redux/memberCommunicate/actions'
import { selectorFilterCategories, selectorSortNotes } from '../../../redux/memberCommunicate/selectors'

import Loader from '../../components/Loader'
import SubNav from './components/Subnav'
import SectionTopNav from './components/SectionTopNav'
import SectionSideNav from './components/SectionSideNav'
import List from './List'
import Notification from './Notification'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'

class MemberNotificationsContainer extends React.Component {
	state = {
		section: 'Current',
		notificationId: null,
		notifications: {},
		loading: true
	}
	componentDidMount = () => {
		let _this = this
		this.props.startMonitoringNotifications()
		.then(()=> {
			_this.setState({loading:false})
			_this.setSort()
		})
	}
	componentDidUpdate = (prevProps) => {
		if (this.props !== prevProps) {
			this.setSort()
		}
	}
	componentWillUnmount = () => {
		this.props.stopMonitoringNotifications()
	}

	setSort = () => {
		let section = (this.props.match.params.section) ? this.props.match.params.section : 'Current'
		let notificationId = (this.props.match.params.notificationId) ? this.props.match.params.notificationId : null
		let allNotifications = this.props.sortedNotifications
		let notifications = {}
		
		// if section not recognized set to Current
		if (section !== 'Saved' && !this.props.categories.includes(section)) {
			section = 'Current'
		}

		if (notificationId && allNotifications.hasOwnProperty(notificationId)) {	 
			notifications[notificationId] = allNotifications[notificationId]
		} else {
			notificationId = null
			switch (section) {
				case 'Current':
					notifications = allNotifications
					break
				case 'Saved':
					Object.keys(allNotifications).map((i) => {
						if (allNotifications[i].saved) {
							notifications[i] = allNotifications[i]
						}
						return null
					})
					break
				default:
					Object.keys(allNotifications).map((i) => {
						if (allNotifications[i].category.replace(/ /g, '-') === section) {
							notifications[i] = allNotifications[i]
						}
						return null
					})
			}
		}
		this.setState({ section:section, notificationId:notificationId, notifications:notifications})
	}

	render() {
		const {section, notificationId, notifications, loading} = this.state
		const {categories, sideNavToggle} = this.props
		const {openNotificationToggle, saveNotificationToggle, deleteNotification} = this.props

		if(!notifications) return null
		return (
			<React.Fragment>
				<Loader loading={loading}/>

				<Container maxWidth='lg' style={{ padding: 10, marginBottom: 150, minHeight:700 }}>
					<Grid container justify='center' spacing={2}>
						<Grid item xs={12} sm={12} md={10} >
							<SubNav/>
						</Grid>

						<Hidden xsDown>
							<Grid item sm={4} md={3}>
							  <SectionSideNav section={section} categories={categories} sideNavToggle={sideNavToggle}/>
							</Grid>
						</Hidden>
						<Grid item xs={12} sm={8} md={7}>
							<SectionTopNav section={section} categories={categories} sideNavToggle={sideNavToggle}/>
							{(notificationId) ? (
								<Notification 
									section={section}
									id={notificationId}
									note={notifications[notificationId]} 
									openNotificationToggle={openNotificationToggle} 
									saveNotificationToggle={saveNotificationToggle}
									deleteNotification={deleteNotification}
								/>
							):(
								<List section={section} notifications={notifications} />
							)}
						</Grid>
					</Grid>
				</Container>     
			  
			</React.Fragment>
		)
	}
}
const mapStateToProps = (state) => ({
	sortedNotifications: selectorSortNotes(state.memberCommunicate.notifications),
	categories: selectorFilterCategories(state.memberCommunicate.notifications)
})
const mapDispatchToProps = {
	startMonitoringNotifications, 
	stopMonitoringNotifications,
	openNotificationToggle,
	saveNotificationToggle,
	deleteNotification
}
export default connect(mapStateToProps, mapDispatchToProps)(MemberNotificationsContainer)
