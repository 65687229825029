import React from 'react'
import Swal from 'sweetalert2'
import {  withRouter } from 'react-router-dom'

import Loader from '../../components/Loader'

import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import Text from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

function MemberSupportNew({history, memberGroups, categories, openTicket}) {
	const [loading, setLoading]= React.useState(false)
	const [group, setGroup] = React.useState('')
	const [category, setCategory] = React.useState('')
	const [subject, setSubject] = React.useState('')
	const [post, setPost] = React.useState('')
	const [message, setMessage] = React.useState('')
	const [disabled, setDisabled]= React.useState(false)

	const goToTicket = (id) => {
		let url = '/member/support/Open/' + id
		history.push({ pathname: url })
	}

	const submitTicket = async () => {
		if(group === '' || category === '' || subject === '' || post === '') {
			return setMessage('Sorry, all fields are required.')
		}
//		let _this = this
		let ticket = {
			group: group,
			category: category,
			subject: subject,
			post: post
		}
		Swal.fire({
			title: 'Submit Ticket',
			text: 'Are you ready to submit this ticket?',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, submit!'
		}).then((result) => {
			if (result.value) {
				setLoading(true)
				setDisabled(true)
				setMessage('Processing Ticket...')
				openTicket(ticket)
				.then((id) => {
					setLoading(false)
					setMessage('Ticket Submitted')
					setTimeout(function(){ goToTicket(id) }, 1000)					
				})
				.catch((error) => {
					setLoading(false)
					setDisabled(false)
					setMessage(error)
				})
			}
		})	
	}
	return (
		<React.Fragment>
			<Loader loading={loading} />
			<Text variant="caption" color="textSecondary">
				Need Assistance? <br/>Select the group you would like assistance with and open a Support Ticket with them.
			</Text>
			<Paper style={{marginTop:20, padding: 20 }} >
		      <FormControl style={{width:'100%'}}>
		        	<InputLabel>Who do you need assistance from?</InputLabel>
		        	<Select value={group} onChange={(e)=>{setGroup(e.target.value)}}>
		        		{Object.keys(memberGroups).map((id) => {
						   return (
							 	<MenuItem key={id} value={id}>{memberGroups[id]}</MenuItem>
						   )
						})}
		        	</Select>
		      </FormControl>
		      <FormControl style={{width:'100%'}}>
		        	<InputLabel>What do you need assistance with?</InputLabel>
		        	<Select value={category} onChange={(e)=>{setCategory(e.target.value)}}>
		        		{categories.map(function(category, index){
		        			return (
		          			<MenuItem key={index} value={category}>{category}</MenuItem>
		        			)
		        		})}
		        	</Select>
		      </FormControl>
				<TextField							
					style={{width:'100%', marginBottom:20}}
					label="Subject"
					value={subject}
          		onChange={(e)=>{setSubject(e.target.value)}}
				/>
				<TextField
					style={{width:'100%'}}
					label="Post"
					value={post}
          		onChange={(e)=>{setPost(e.target.value)}}
					multiline
					rows={4}
					variant="outlined"
				/>
				<div style={{marginTop:10, textAlign:'center'}}><Text variant="overline" style={{color:'red'}}>{message}</Text></div>
				<div style={{marginTop:10, marginBottom:20, textAlign:'right'}}><Button disabled={disabled} onClick={submitTicket} variant="contained" color="secondary">Submit</Button></div>
			</Paper>
			
		</React.Fragment>
	)
}
export default withRouter(MemberSupportNew)
