import React from 'react'

import NewPost from './components/NewPost'
import NewPoll from './components/NewPoll'
import NewSurvey from './components/NewSurvey'
import NewNewsletter from './components/NewNewsletter'

//import Button from '@material-ui/core/Button'
//import IconClose from '@material-ui/icons/Close'
//import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
//import Text from '@material-ui/core/Typography'

const filters = {
    post: 'Post something to your members wall',
    poll: 'Poll your members',
    survey:'Conduct a group Survey',
    newsletter: 'Create a Newsletter to send to your members'
}

function GroupCommunicateNew({history, posts, filter, id, addEditPost}) {
	const [filterEl, setFilterEl] = React.useState(null)
	const [type, setType] = React.useState(filter.slice(0, -1).toLowerCase())
	const [details, setDetails] = React.useState((id) ? posts[id] : undefined)
	// Make sure filter is valid
	if (!['post', 'poll', 'survey', 'newsletter'].includes(type)) {
		setType('post')
	}

	const filterPosts = (f) => {
		setFilterEl(null)
		let path = '/group/communicate/New/' + f
      history.push({ pathname: path })
	}

	return (
		<React.Fragment>
			<Paper style={{marginTop:20, padding:10 }} >
	      	<List component="nav" style={{backgroundColor:'#f1f1f1', padding:2}}>
	            <ListItem button onClick={(event) => { setFilterEl(event.currentTarget) }} style={{padding:1, textAlign:'center'}}>
	               <ListItemText primary={'Creat New ' + filter.slice(0, -1)} secondary={filters[type]} />
	            </ListItem>
	         </List> 
	         <Menu 
	            dense='true' 
	            anchorEl={filterEl}
	            open={Boolean(filterEl)}
	            onClose={() => { setFilterEl(null) }}
	         >
	            <MenuItem value='Posts' selected={filter === 'Posts' } onClick={() => {filterPosts('Posts')}}>
	              <ListItemText style={{whiteSpace: 'normal', maxWidth:400}} primary='Post' secondary={filters['post']} />
	            </MenuItem>
	            <MenuItem value='Polls' selected={filter === 'Polls' } onClick={() => {filterPosts('Polls')}}>
	              <ListItemText style={{whiteSpace: 'normal', maxWidth:400}} primary='Poll' secondary={filters['poll']} />
	            </MenuItem>
	            <MenuItem value='Surveys' selected={filter === 'Surveys' } onClick={() => {filterPosts('Surveys')}}>
	              <ListItemText style={{whiteSpace: 'normal', maxWidth:400}} primary='Survey' secondary={filters['survey']} />
	            </MenuItem>
	            <MenuItem value='Newsletters' selected={filter === 'Newsletters' } onClick={() => {filterPosts('Newsletters')}}>
	              <ListItemText style={{whiteSpace: 'normal', maxWidth:400}} primary='Newsletter' secondary={filters['newsletter']} />
	            </MenuItem>
	         </Menu>
			</Paper>
			{(filter === 'Posts') ? (
				<NewPost type={type} details={details} addEditPost={addEditPost} />
			) : (null )}
			{(filter === 'Polls') ? (
				<NewPoll type={type} details={details} addEditPost={addEditPost} />
			) : (null )}
			{(filter === 'Surveys') ? (
				<NewSurvey type={type} details={details} addEditPost={addEditPost} />
			) : (null )}
			{(filter === 'Newsletters') ? (
				<NewNewsletter type={type} details={details} addEditPost={addEditPost} />
			) : (null )}	
		</React.Fragment>
	)
}
export default GroupCommunicateNew
