import React from 'react'

import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function NewSurvey(){
	return (
	   <Paper style={{marginTop:20, padding: 20 }} >
			<div style={{padding:20, textAlign:'center'}}>
				<Text variant='caption'>SURVEY TEMPLATE IS UNDER CONSTRUCTION</Text>
			</div>
		</Paper>
	)
}
export default NewSurvey
