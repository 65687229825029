export const AUTH_USER_INFO= 'auth:userInfo'
export const AUTH_LOGIN_USER = 'auth:loginUser'
export const AUTH_LOGOUT_USER = 'auth:logoutUser'
export const AUTH_LOAD_USER = 'auth:loadUser'
export const AUTH_CHANGE_LOGIN_OPTION = 'auth:changeLoginOption'
export const AUTH_LOAD_LOGIN_OPTIONS = 'auth:loadLoginOptions'
export const AUTH_LOAD_MEMBERSHIPS = 'auth:loadMemberships'

export const MEMBER_NOTIFICATIONS_LOAD = 'memberCommunicate:loadNotifications'
export const MEMBER_POSTS_LOAD = 'memberCommunicate:loadPosts'
export const MEMBER_POINTS_LOAD = 'memberCommunicate:loadPoints'
export const MEMBER_SAVINGS_LOAD = 'memberCommunicate:loadSavings'

// GROUP-POSTS
export const GROUP_POSTS_LOAD = 'groupCommunicate:loadPosts'
export const GROUP_POSTS_RESET_REDUCER = 'groupCommunicate:resetReducer'



// 27th May 2020:::::::::::::::::::



// SUPPORT TYPES FOR MEMBER & GROUP 
export const MEMBER_SUPPORT_LOAD_CATEGORIES = 'memberSupport:loadCategories'
export const MEMBER_SUPPORT_LOAD_TICKETS = 'memberSupport:loadTickets'
export const GROUP_SUPPORT_LOAD_TICKETS = 'groupSupport:loadTickets'
export const GROUP_SUPPORT_LOAD_TEAM = 'groupSupport:loadTeam'
export const GROUP_SUPPORT_RESET_REDUCER = 'groupSupport:resetReducer'

//31st March 2020 ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

// GROUP-TEAM
export const GROUP_TEAM_LOAD = 'groupTeam:loadTeam'
export const GROUP_TEAM_RESET_REDUCER = 'groupTeam:resetReducer'

// GROUP-MEMBERS
export const GROUP_MEMBERS_LOAD = 'groupMembers:loadMembers'
export const GROUP_MEMBERS_RESET_REDUCER = 'groupMembers:resetReducer'




// :::::::::::::::::::::::::::::::::
// :::::::::::::::::::::::::::::::::
// :::::::::::::::::::::::::::::::::




// EVENTS
export const GROUP_EVENTS_RESET_REDUCER = 'groupEvents:resetEvents'
export const GROUP_EVENTS_LOAD = 'groupEvents:loadEvents'
export const GROUP_EVENTS_TICKET_TYPES_LOAD = 'groupEvents:loadTicketTypes'
export const GROUP_EVENTS_ATTENDANCE_LOAD = 'groupEvents:loadAttendance'
export const GROUP_EVENTS_EVENT_RESET = 'groupEvents:resetEvent'