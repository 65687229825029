import { GROUP_SUPPORT_LOAD_TICKETS, GROUP_SUPPORT_LOAD_TEAM, GROUP_SUPPORT_RESET_REDUCER } from '../types'
import { customDelay }  from '../../includes/functions'

// [START]: Support Listener ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
let monitorSupport
export function startMonitoringSupport() {
	return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
		// delay this snapshot if userInfo not loaded as yet
		if(getstate().auth.userInfo == null){
			await customDelay(1500)
		}

		// set needed variables
		let ticketsRef
		let sectionGroupId = getstate().groupSupport.groupId
		let loginOptionID = getstate().auth.loginOptionID
		let loginOptions = getstate().auth.loginOptions
		let loginInfo = loginOptions[loginOptionID]
		let gId = loginInfo.id

		// reset section reducer if groupId doesnt match sectionGroupId
		if (sectionGroupId && sectionGroupId !== gId) {
			dispatch({type: GROUP_SUPPORT_RESET_REDUCER})
		}

		// load categories for groups
		dispatch(loadTeam(gId))

		// [START]: Setup Query Ref ::::::::::::::::::::::::::::::::::::::::::::::
		if (loginInfo.roleId === 1 || loginInfo.roleId === 2 || loginInfo.roleId === 4) {
			ticketsRef = await firebase.firestore().collection('supportTickets').where('groupId', '==', gId)
		} else {
			reject('You do not have permission to view this page.')
			return
		}
		// [END]: Setup Query Ref ::::::::::::::::::::::::::::::::::::::::::::::::

		monitorSupport = ticketsRef.onSnapshot((querySnapshot) => {
			let tickets = {}
			querySnapshot.forEach((doc) => {
				tickets[doc.id] = doc.data()
			})
			let payload = {
				groupId:gId,
				supportTickets: tickets,
				timestamp: Math.floor(Date.now() / 1000)
			}
			dispatch({type: GROUP_SUPPORT_LOAD_TICKETS, payload: payload})
			resolve()
		})	
	})
}
export function stopMonitoringSupport(){
	return () => {
		monitorSupport && monitorSupport()
	}
}
// [END]: Support Listener ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

export function loadTeam(gId) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
		const teamMembers = firebase.functions().httpsCallable('groupTeamMembers')
      teamMembers({ gId:gId })
      .then((returned) => {
      	dispatch({type: GROUP_SUPPORT_LOAD_TEAM, payload: returned.data})
         resolve()
      })
      .catch((err) => {
      	console.log('error retreving team members: ',err)
         reject(err.message)
      })
	})
}

export function assignTicket(ticketId,teamMember) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
		const groupId = getstate().groupSupport.groupId
		const assign = firebase.functions().httpsCallable('groupAssignTicket')
      assign({ groupId:groupId, ticketId:ticketId, teamMember:teamMember })
      .then((returned) => {
         resolve()
      })
      .catch((err) => {
         reject(err.message)
      })		
	})
}

export function updateTicket(ticketId, post) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
		const groupId = getstate().groupSupport.groupId
		const ticketUpdate = firebase.functions().httpsCallable('groupUpdateTicket')
      ticketUpdate({ groupId:groupId, ticketId:ticketId, post:post })
      .then((returned) => {
         resolve()
      })
      .catch((err) => {
         reject(err.message)
      })
	})
}

export function addTicketNote(ticketId, note) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
		const groupId = getstate().groupSupport.groupId
		const ticketUpdate = firebase.functions().httpsCallable('groupUpdateTicketNotes')
      ticketUpdate({ groupId:groupId, ticketId:ticketId, note:note })
      .then((returned) => {
         resolve()
      })
      .catch((err) => {
         reject(err.message)
      })
	})
}

export function closeTicket(ticketId) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
		const groupId = getstate().groupSupport.groupId
		const ticketClose = firebase.functions().httpsCallable('groupCloseTicket')
      ticketClose({ groupId:groupId, ticketId:ticketId })
      .then((returned) => {
         resolve()
      })
      .catch((err) => {
         reject(err.message)
      })
	})
}

export function archiveTicket(ticketId) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
		const groupId = getstate().groupSupport.groupId
		const ticketArchive = firebase.functions().httpsCallable('groupArchiveTicket')
      ticketArchive({ groupId:groupId, ticketId:ticketId })
      .then((returned) => {
         resolve()
      })
      .catch((err) => {
         reject(err.message)
      })		
	})
}

