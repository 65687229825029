import { MEMBER_SUPPORT_LOAD_CATEGORIES, MEMBER_SUPPORT_LOAD_TICKETS } from '../types'
import { customDelay }  from '../../includes/functions'

// [START]: Support Listener ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
let monitorSupport
export function startMonitoringSupport() {
	return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
		// delay this snapshot if userInfo not loaded as yet
		if(getstate().auth.userInfo == null){
			await customDelay(1500)
		}

		// load categories for groups
		dispatch(loadCategories())

		// userInfo loaded so go ahead with snapshot
		const mId = getstate().auth.userInfo.id 
		const noteRef = firebase.firestore().collection('supportTickets')
		monitorSupport = noteRef.where('memberId', '==', mId).onSnapshot((querySnapshot) => {
			let tickets = {}
			querySnapshot.forEach((doc) => {
				tickets[doc.id] = doc.data()
			})
			let payload = {
				supportTickets: tickets,
				timestamp: Math.floor(Date.now() / 1000)
			}
			dispatch({type: MEMBER_SUPPORT_LOAD_TICKETS, payload: payload})
			resolve()
		})
	
	})
}
export function stopMonitoringSupport(){
	return () => {
		monitorSupport && monitorSupport()
	}
}
// [END]: Support Listener ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

export function loadCategories() {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
		// check the collection supportCategories to see if the selected group has unique categories
		// will have to be checked every time the user changes group
		let categories = [
			'Service Releated Support',
			'Group Related Support',
			'Membership Related Support',
			'General Support'
		]
		dispatch({type: MEMBER_SUPPORT_LOAD_CATEGORIES, payload: categories})
	})
}

export function openTicket(ticket) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
		const ticketOpen = firebase.functions().httpsCallable('userOpenTicket')
      ticketOpen({ group:ticket.group, category:ticket.category, subject:ticket.subject, post:ticket.post})
      .then((returned) => {
         // ticket ID is returned
         resolve(returned.data)
      })
      .catch((err) => {
         reject(err.message)
      })
	})
}

export function updateTicket(ticketId, post) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
		const ticketUpdate = firebase.functions().httpsCallable('userUpdateTicket')
      ticketUpdate({ ticketId:ticketId, post:post })
      .then((returned) => {
         resolve()
      })
      .catch((err) => {
         reject(err.message)
      })
	})
}

export function closeTicket(ticketId) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
		const ticketClose = firebase.functions().httpsCallable('userCloseTicket')
      ticketClose({ ticketId:ticketId })
      .then((returned) => {
         resolve()
      })
      .catch((err) => {
         reject(err.message)
      })
	})
}
