import React from 'react'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function GroupCommunicateSummary({history}) {
	const handleClick = (value) => {
    	let path = '/group/communicate/New/' + value
      history.push({ pathname: path })
   }

	return (
		<Paper style={{ padding: 20, marginTop:20, marginBottom:150 }} >
			<Text variant='h5' gutterBottom>
				GROUP COMMUNICATE
			</Text>
			<Text variant='caption' gutterBottom>
			   <b>ABOUT</b><br/>
				<p>This is where the group communicates with its members.</p>
				
				<Button onClick={() => handleClick('Posts')}>Create Post</Button><br/>
				<Button onClick={() => handleClick('Polls')}>Create Poll</Button><br/>
				<Button onClick={() => handleClick('Survays')}>Create Survays</Button><br/>
				<Button onClick={() => handleClick('Newsletters')}>Create Newsletter</Button><br/>

				<p><b>ACCESS</b></p>
				 - Level 1 (Owner/Admin)<br/>
				 - Level 2 (Manager)<br/>
				 - Level 3 (Editor)<br/>

				<p><b>SHOW GROUP FEED</b></p> 
			</Text>
		</Paper>
	)
}
export default GroupCommunicateSummary
