import { MEMBER_NOTIFICATIONS_LOAD, MEMBER_POSTS_LOAD, MEMBER_POINTS_LOAD, MEMBER_SAVINGS_LOAD } from '../types'
import { customDelay }  from '../../includes/functions'

// [START]: Dashboard Listener ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
let monitorNotifications
let monitorPosts
let monitorPoints
let monitorSavings
export function monitorDashboard(){
	return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
		dispatch(startMonitoringNotifications())
		dispatch(startMonitoringPosts())
		dispatch(startMonitoringPoints())
		dispatch(startMonitoringSavings())
		resolve()
	})
}
export function stopMonitoringDashboard(){
	return () => {
		monitorNotifications && monitorNotifications()
		monitorPosts && monitorPosts()
		monitorPoints && monitorPoints()
		monitorSavings && monitorSavings()
	}
}
// [END]: Dashboard Listener :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

// [START]: Notifications Listener ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
export function startMonitoringNotifications() {
	return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {

		// delay this snapshot if userInfo not loaded as yet
		if(getstate().auth.userInfo == null){
			await customDelay(1500)
		}

		// userInfo loaded so go ahead with snapshot
		const mId = getstate().auth.userInfo.id
		const noteRef = firebase.firestore().collection('notifications')
		monitorNotifications = noteRef.where('memberId', '==', mId).onSnapshot((querySnapshot) => {
			let notifications = {}
			querySnapshot.forEach((doc) => {
				notifications[doc.id] = doc.data()
			})
			let payload = {
				timestamp: Math.floor(Date.now() / 1000),
				notifications: notifications
			}
			dispatch({type: MEMBER_NOTIFICATIONS_LOAD, payload: payload})
			resolve()
		})
	})
}
export function stopMonitoringNotifications(){
	return () => {
		monitorNotifications && monitorNotifications()
	}
}
// [END]: Notifications Listener ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

// [START]: Posts Listener ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
export function startMonitoringPosts() {
	return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {

		// delay this snapshot if userInfo not loaded as yet
		if(getstate().auth.userInfo == null){
			await customDelay(1500)
		}

		// get MemberID and monitor POSTS snapshot
		const mId = getstate().auth.userInfo.id
		const postsRef = firebase.firestore().collection('posts')
		monitorPosts = postsRef.where('memberId', '==', mId).onSnapshot((querySnapshot) => {
			let posts = {}
			querySnapshot.forEach((doc) => {
				posts[doc.id] = doc.data()
			})
			let payload = {
				timestamp: Math.floor(Date.now() / 1000),
				posts: posts
			}
			dispatch({type: MEMBER_POSTS_LOAD, payload: payload})
			resolve()
		})
	})
}
export function stopMonitoringPosts(){
	return () => {
		monitorPosts && monitorPosts()
	}
}
// [END]: Posts Listener ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

// [START]: Points Listener ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
export function startMonitoringPoints() {
	return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {

		// delay this snapshot if userInfo not loaded as yet
		if(getstate().auth.userInfo == null){
			await customDelay(1500)
		}

		// get MemberID and monitor POINTS snapshot
		const mId = getstate().auth.userInfo.id
		const pointsRef = firebase.firestore().collection('points')
		monitorPoints = pointsRef.where('memberId', '==', mId).onSnapshot((querySnapshot) => {
			let points = {}
			querySnapshot.forEach((doc) => {
				points[doc.id] = doc.data()
			})
			let payload = {
				timestamp: Math.floor(Date.now() / 1000),
				points: points
			}
			dispatch({type: MEMBER_POINTS_LOAD, payload: payload})
			resolve()
		})
	})
}
export function stopMonitoringPoints(){
	return () => {
		monitorPoints && monitorPoints()
	}
}
// [END]: Points Listener ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

// [START]: Savings Listener ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
export function startMonitoringSavings() {
	return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {

		// delay this snapshot if userInfo not loaded as yet
		if(getstate().auth.userInfo == null){
			await customDelay(1500)
		}

		// get MemberID and monitor SAVINGS snapshot
		const mId = getstate().auth.userInfo.id
		const savingsRef = firebase.firestore().collection('savings')
		monitorSavings = savingsRef.where('memberId', '==', mId).onSnapshot((querySnapshot) => {
			let savings = {}
			querySnapshot.forEach((doc) => {
				savings[doc.id] = doc.data()
			})
			let payload = {
				timestamp: Math.floor(Date.now() / 1000),
				savings: savings
			}
			dispatch({type: MEMBER_SAVINGS_LOAD, payload: payload})
			resolve()
		})
	})
}
export function stopMonitoringSavings(){
	return () => {
		monitorSavings && monitorSavings()
	}
}
// [END]: Savings Listener ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

// [START]: Notifications Functions :::::::::::::::::::::::::::::::::::::::::::::::::::::::::
export function openNotificationToggle(id, viewed) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
		let notifications = getstate().memberCommunicate.notifications
		if (notifications[id]) {
			notifications[id].viewed = viewed
		}
		let payload = {
			notifications: notifications,
			timestamp: Math.floor(Date.now() / 1000)
		}
		dispatch({type: MEMBER_NOTIFICATIONS_LOAD, payload: payload})

		const noteOpen = firebase.functions().httpsCallable('notificationOpen')
		noteOpen({ id:id, viewed:viewed })
		.then((returned) => {
			resolve()
		})
		.catch((err) => {
			reject(err.message)
		})
	})
}

export function saveNotificationToggle(id, saved) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
		let notifications = getstate().memberCommunicate.notifications
		if (notifications[id]) {
			notifications[id].saved = saved
		}
		let payload = {
			notifications: notifications,
			timestamp: Math.floor(Date.now() / 1000)
		}
		dispatch({type: MEMBER_NOTIFICATIONS_LOAD, payload: payload})
		
		const noteSave = firebase.functions().httpsCallable('notificationSave')
		noteSave({ id:id, saved:saved })
		.then((returned) => {
			resolve()
		})
		.catch((err) => {
			reject(err.message)
		})
	})
}

export function deleteNotification(id) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
		let notifications = getstate().memberCommunicate.notifications
		delete notifications[id]
		let payload = {
			notifications: notifications,
			timestamp: Math.floor(Date.now() / 1000)
		}
		dispatch({type: MEMBER_NOTIFICATIONS_LOAD, payload: payload})
		
		const noteDelete = firebase.functions().httpsCallable('notificationDelete')
		noteDelete({ id:id })
		.then((returned) => {
			resolve()
		})
		.catch((err) => {
			reject(err.message)
		})
	})
}
// [END]: Notifications Functions :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::