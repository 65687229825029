import React from 'react'
//import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { startMonitoringSupport, stopMonitoringSupport, assignTicket, updateTicket, closeTicket, archiveTicket, addTicketNote } from '../../../redux/groupSupport/actions'
import { selectorLoadGroupTickets } from '../../../redux/groupSupport/selectors'

import AccessDenied from '../../components/AccessDenied'
import Loader from '../../components/Loader'
import SubNav from './components/Subnav'
import SectionSideNav from './components/SectionSideNav'
import SectionTopNav from './components/SectionTopNav'
import List from './List'
import Ticket from './Ticket'
import Notes from './Notes'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'

const whoHasAccess = [1,2,4]
class GroupSupportContainer extends React.Component {
   state = {
  		loading:true,
	 	section: 'Unassigned',
	 	filter: 'All',
	 	ticketId: null,
	 	tickets: {}
   }
   componentDidMount = () => {
	 	let _this = this
	 	this.props.startMonitoringSupport()
	 	.then(() => {
	 		_this.setState({loading:false})
	 		_this.sortFilter()
	 	})
	 	.catch((error) => {
	 		console.log('alert error here')
	 	})
   }
   componentDidUpdate = (prevProps) => {
	 	if (this.props !== prevProps) {	
			this.sortFilter()
	 	}
   }
   componentWillUnmount = () => {
	 	this.props.stopMonitoringSupport()
   }
   sortFilter = () => {
	 	let tickets = {}
	 	let section = (this.props.match.params.section) ? this.props.match.params.section : 'Unassigned'
	 	let filter = (this.props.match.params.filter) ? this.props.match.params.filter : 'All'
	 	let ticketId = (this.props.match.params.ticketId) ? this.props.match.params.ticketId : null
		let allTickets = this.props.allTickets || {}

		if (ticketId && allTickets.hasOwnProperty(ticketId)) {	 
		  	tickets = { [ticketId]: allTickets[ticketId] }
		} else {
	  		ticketId=null
		 	switch(section) {
				case 'Unassigned':
					tickets = this.props.unassignedTickets
					break
				case 'Open':
					tickets = this.props.openTickets
					break
				case 'Closed':
					tickets = this.props.closedTickets
					break
				default:
					tickets = this.props.archivedTickets
			}
		} 
		this.setState({section:section, filter:filter, ticketId:ticketId, tickets:tickets })
  	}

	render() {
	 	const {loading, section, filter, ticketId, tickets} = this.state
	 	const {roleId, memberId, team, assignTicket, updateTicket, closeTicket, archiveTicket, addTicketNote} = this.props
		if (!roleId) return null
		if(!whoHasAccess.includes(roleId)) {
			return (<AccessDenied page='groupSupport' />)
		}
		
		return (
			<React.Fragment>
				<Loader loading={loading} />
				<Container maxWidth='lg' style={{ padding: 10, marginBottom: 150, minHeight:700 }}>
					<Grid container justify='center' spacing={2}>
						<Grid item xs={12} sm={12} md={10} >
							<SubNav/>
						</Grid>
						<Hidden xsDown>
							<Grid item sm={4} md={3}>
								<SectionSideNav section={section} sideNavToggle={this.props.sideNavToggle}/>
							</Grid>
						</Hidden>
						<Grid item xs={12} sm={8} md={7}>
							<SectionTopNav section={section} sideNavToggle={this.props.sideNavToggle}/>
							{ (section === 'Notes') ? (
							 	<Notes />
						  	) : (
							 	(ticketId) ? (
									<Ticket 
										roleId={roleId} section={section} filter={filter} tickets={tickets} ticketId={ticketId} team={team} memberId={memberId} 
										assignTicket={assignTicket} updateTicket={updateTicket} closeTicket={closeTicket} archiveTicket={archiveTicket} addTicketNote={addTicketNote}
									/>
							 	) : (
									<List section={section} filter={filter} tickets={tickets} />
							 	)
						  	)}
						</Grid>
					</Grid>
				</Container> 
			</React.Fragment>
		)
	}
}
const mapStateToProps = (state) => ({
	roleId: state.auth.loginOptions[state.auth.loginOptionID].roleId,
	memberId: state.auth.userInfo.id,
	allTickets: state.groupSupport.supportTickets,
	unassignedTickets: selectorLoadGroupTickets(state.groupSupport.supportTickets,'unassigned'),
	openTickets: selectorLoadGroupTickets(state.groupSupport.supportTickets,'open'),
	closedTickets: selectorLoadGroupTickets(state.groupSupport.supportTickets,'closed'),
	archivedTickets: selectorLoadGroupTickets(state.groupSupport.supportTickets,'archived'),
	team: state.groupSupport.team
})
const mapDispatchToProps = {
	startMonitoringSupport,
	stopMonitoringSupport, 
	assignTicket, 
	updateTicket, 
	closeTicket, 
	archiveTicket,
	addTicketNote
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupSupportContainer)
