import React from 'react'
import { withRouter } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Hidden from '@material-ui/core/Hidden'
import IconFilterList from '@material-ui/icons/FilterList'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Text from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'

function SectionTopNav({history, section, categories, sideNavToggle}){
	const [anchorEl, setAnchorEl] = React.useState(null)

	const handleChange = (value) => {
		setAnchorEl(null)
		let path = '/member/notifications/' + value
		history.push({ pathname: path })
	}
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}    
	const handleClose = () => {
		setAnchorEl(null)
	}
	const handleFullMenu = () => {
		setAnchorEl(null)
		sideNavToggle()
	}

	return (
		<Toolbar variant="dense" style={{ paddingLeft:5, display: 'flex', borderBottom: '2px solid #ccc'}}>
				<Hidden smUp>
					<Button size="small" onClick={handleClick}>
	               <IconFilterList style={{marginRight:5}}/> {section.replace(/-/g, ' ').replace(/\+/g, ' & ')}
	            </Button>
			</Hidden>
			<Menu
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem selected={section === 'Current'} onClick={() => {handleChange('Current')}}>Current</MenuItem>
				<MenuItem selected={section === 'Saved'} onClick={() => {handleChange('Saved')}}>Saved</MenuItem>
				<Divider/>
				{categories.map((category,i) => {
					return (
						<MenuItem key={i} selected={section === category} onClick={() => {handleChange(category)}}>{category.replace(/-/g, ' ')}</MenuItem>
					)
				})}
				<Divider />
				<MenuItem onClick={handleFullMenu}>View Full Menu</MenuItem>
			</Menu>
			<Hidden xsDown>
				<Text variant='h6' color='primary' style={{paddingLeft:5}}>{section.replace(/-/g, ' ').toUpperCase()}</Text>
			</Hidden>
		</Toolbar>
	)
}
export default withRouter(SectionTopNav)
