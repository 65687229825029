import React from 'react'

import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function GroupSettingsVerification() {
	return (
		<Paper style={{ padding: 20, marginTop:20, marginBottom:150 }} >
			<Text variant='h6' gutterBottom>
				VERIFICATION
			</Text>
			<Text variant='caption' gutterBottom>
				<ul>
					<li>Group Verification</li>
					<li>Simular to Blue Check Mark</li>
					<li>Documents may be required to verify ownership of group</li>
					<li>Proof that group is bonified group for real world entity</li>
				</ul>
			</Text>
		</Paper>
	)
}
export default GroupSettingsVerification
