import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'

import Event from './Event'
import EventsList from './EventsList'

function EventCheckin({props}){
	return (
		<Switch>
			<Route path='/group/scanner/event-checkin/event/:id?' render={props => <Event {...props} 
			  />} 
			/>
			<Route path='/group/scanner/event-checkin' render={props => <EventsList {...props} 
			  />} 
			/>
		</Switch>
	)
}
const mapStateToProps = (state) => {
  return {}
}
const mapDispatchToProps = {
}
export default connect(mapStateToProps, mapDispatchToProps)(EventCheckin)
