import { GROUP_POSTS_LOAD, GROUP_POSTS_RESET_REDUCER } from '../types'
import { customDelay }  from '../../includes/functions'

// [START]: Posts Listener ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
let monitorPosts
export function startMonitoringCommunications() {
    return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
        // delay this snapshot if userInfo not loaded as yet
        if(getstate().auth.userInfo == null){
            await customDelay(1500)
        }

        // set needed variables
        let postsRef
        let sectionGroupId = getstate().groupSupport.groupId
        let loginOptionID = getstate().auth.loginOptionID
        let loginOptions = getstate().auth.loginOptions
        let loginInfo = loginOptions[loginOptionID]
        let gId = loginInfo.id

        // reset section reducer if groupId doesnt match sectionGroupId
        if (sectionGroupId && sectionGroupId !== gId) {
            dispatch({type: GROUP_POSTS_RESET_REDUCER})
        }

        // [START]: Setup Query Ref ::::::::::::::::::::::::::::::::::::::::::::::
        if (loginInfo.roleId === 1 || loginInfo.roleId === 2 || loginInfo.roleId === 3) {
            postsRef = await firebase.firestore().collection('posts').where('groupId', '==', gId)
        } else {
            reject('You do not have permission to view this page.')
            return
        }
        // [END]: Setup Query Ref ::::::::::::::::::::::::::::::::::::::::::::::::

        monitorPosts = postsRef.onSnapshot((querySnapshot) => {
            let posts = {}
            querySnapshot.forEach((doc) => {
                posts[doc.id] = doc.data()
            })
            let payload = {
                groupId:gId,
                posts: posts,
                timestamp: Math.floor(Date.now() / 1000)
            }
            dispatch({type: GROUP_POSTS_LOAD, payload: payload})
            resolve()
        })    
    })
}
export function stopMonitoringCommunications(){
    return () => {
        monitorPosts && monitorPosts()
    }
}
// [END]: Posts Listener ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

export function addEditPost(post) {
    return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
        console.log('add post',post)
    })
}
