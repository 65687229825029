import React from 'react'
import { withRouter } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Hidden from '@material-ui/core/Hidden'
import IconFilterList from '@material-ui/icons/FilterList'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Text from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'

function SectionTopNav(props){
	const [anchorEl, setAnchorEl] = React.useState(null)

	const handleChange = (value) => {
		setAnchorEl(null)
		let path = '/group/communicate/' + value
		props.history.push({ pathname: path })
	}
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}    
	const handleClose = () => {
		setAnchorEl(null)
	}
	const handleFullMenu = () => {
		setAnchorEl(null)
		props.sideNavToggle()
	}
	const filter = props.filter
	return (
		<Toolbar variant="dense" style={{ paddingLeft:5, display: 'flex', borderBottom: '2px solid #ccc'}}>
			<Hidden smUp>
				<Button size="small" onClick={handleClick}>
               <IconFilterList style={{marginRight:5}}/> {filter.replace(/-/g, ' ')}
            </Button>
			</Hidden>
			<Menu keepMounted anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
				<MenuItem selected={filter === ''} onClick={() => {handleChange('')}}>Summary</MenuItem>
				<MenuItem selected={filter === 'Posts'} onClick={() => {handleChange('List/Posts')}}>Posts</MenuItem>
				<MenuItem selected={filter === 'Polls'} onClick={() => {handleChange('List/Polls')}}>Polls</MenuItem>
				<MenuItem selected={filter === 'Surveys'} onClick={() => {handleChange('List/Surveys')}}>Surveys</MenuItem>
				<MenuItem selected={filter === 'Newsletters'} onClick={() => {handleChange('List/Newsletters')}}>Newsletters</MenuItem>
				<Divider />
				<MenuItem onClick={handleFullMenu}>View Full Menu</MenuItem>
			</Menu>
			<Hidden xsDown>
			 	<Text variant='h6' color='primary' style={{paddingLeft:5}}>{filter.replace(/-/g, ' ')}</Text>
			</Hidden>
		</Toolbar>
	)
}
export default withRouter(SectionTopNav)
