import { AUTH_USER_INFO, AUTH_LOGIN_USER, AUTH_LOGOUT_USER, AUTH_LOAD_USER, AUTH_LOAD_LOGIN_OPTIONS, AUTH_CHANGE_LOGIN_OPTION, AUTH_LOAD_MEMBERSHIPS } from '../types'

const initialState = {
	lastUpdated: 0,
	loggedIn: false,
	userInfo: null,
	loginOptionID: 0,
	loginOptions: [{
		login: 'member',
		id: null,
		name: 'Anonymous',
		role: '',
		roleId: 0,
		thumb: '/images/profile/default.jpg',
		initials: 'A'
	}],
	memberships:[]
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
	case AUTH_USER_INFO:
		return { 
			...state,
			userInfo: action.payload
		}
	case AUTH_LOGIN_USER:
		return { 
			...state,
			loggedIn: true          
		}
	case AUTH_LOGOUT_USER:
		// this is also called in the reducers.js to reset the state completely
		return { 
			...initialState           
		}
	case AUTH_LOAD_USER:
		return { 
			...state, 
			loginOptions: state.loginOptions.map((option, i) => i === 0 ? action.payload : option)
		}
	case AUTH_LOAD_LOGIN_OPTIONS:
		return { 
			...state, 
			loginOptions: action.payload
		}
	case AUTH_CHANGE_LOGIN_OPTION:
		return { 
			...state, 
			loginOptionID: action.payload
		}	
	case AUTH_LOAD_MEMBERSHIPS: // this is loaded in /auth/actions/monitorMembershipsInfo()
      return { 
          ...state, 
          lastUpdated: action.payload.timestamp,
          memberships: action.payload.memberships
      }
	default:
		return state
  }
}
