import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

//import TemplateDefault from './TemplateDefault'
//import TemplateAddedToGroup from './TemplateAddedToGroup'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import clsx from 'clsx'
import Collapse from '@material-ui/core/Collapse'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IconButton from '@material-ui/core/IconButton'
import Text from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  	expand: {
    	transform: 'rotate(0deg)',
    	marginLeft: 'auto',
    	transition: theme.transitions.create('transform', {
      	duration: theme.transitions.duration.shortest,
    	}),
  	},
  	expandOpen: {
    	transform: 'rotate(180deg)',
  	}
}))

function Notifications({history, notifications, handleOpenGroup, openNotificationToggle}) {
	const classes = useStyles()
   const [expanded, setExpanded] = React.useState(false);
   
   const handleExpandClick = (note) => {
   	let action = (expanded === note) ? false : note
		setExpanded(action)
		if(!action){
			openNotificationToggle(note,true)
    	}
  	}
   const handleActionLink = (link) => {
   	history.push({ pathname: link })
   }
   const handleGroupClick = (type, id) => {
      if(type === 'group') {
      	handleOpenGroup(id)
      }
   }
   return (
	   (notifications.length) ? (
			<div style={{marginTop:20, width:'100%', padding:5}}>
				{notifications.map((note) => {
					return (
						<Card key={note.id} style={{ borderLeft: '2px solid #cc00cc', marginBottom: 15, borderRadius:0 }}>
				   		<CardHeader
					         avatar={<Avatar onClick={() => {handleGroupClick(note.type, note.groupId)}} alt={note.groupName} src={note.groupThumb} style={{borderRadius:5}} />}
					        	action={
					          	<IconButton 
					          	 	className={clsx(classes.expand, {
						            	[classes.expandOpen]: expanded === note.id,
						         	})}
								      onClick={() => {handleExpandClick(note.id)}}
								   >
					            	<ExpandMoreIcon />
					          	</IconButton>
					        	}
					        	title={<Text onClick={() => {handleGroupClick(note.type, note.groupId)}} variant='h6'>{note.groupName}</Text>}
					        	subheader={
					        		<div>
					        			<Text style={{lineHeight:.8}} variant='caption' display='block' color='textSecondary'>
					        				{moment.unix(note.timestamp.seconds).format('MMM Do YYYY')}
					        			</Text>
					        			<Text style={{color:'#000'}}>{note.subject}</Text>
					        		</div>
					        	}
					      />					      
					      
					      <Collapse in={expanded === note.id} timeout="auto" unmountOnExit>
			        			<CardContent>
			        				{/* put select:case for templates here*/}
			        				{note.message}
			        				{(note.actionLink) ? (
				        			<div align='center'><Button size='small' variant="outlined" color="secondary" onClick={() => { handleActionLink(note.actionLink) }}>{note.actionText}</Button></div>
			        				) : ( null )}
			        			</CardContent>
			        		</Collapse>
				   	</Card>
					)
				})}
			</div>
	   ) : ( null )	   	
   )
}
export default withRouter(Notifications)
