import React from 'react'
import swal from 'sweetalert2'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { updateMemberInfo, updateMemberPassword, photoAdd, photoDelete, photoSet } from '../../../redux/member/actions'

import Loader from '../../components/Loader'
import Password from './components/Password'
import Payments from './Payments'
import Photos from './Photos'
import ProfileEdit from './ProfileEdit'
import ProfileView from './ProfileView'
import SectionSideNav from './components/SectionSideNav'
import SectionTopNav from './components/SectionTopNav'
import SubNav from './components/Subnav'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'

class MemberProfileContainer extends React.Component {
	state = {
		showPwBox: false,
	 	getOldPassword: false,
	 	loading:false
  	}
  	updateInfo = (info) => {
		let _this = this
		this.setState({loading:true})
		return new Promise(function (resolve, reject) {
		  	_this.props.updateMemberInfo(info)
		  	.then(() => {
				_this.setState({loading:false})
				swal.fire({
					position: 'top-end',
					type: 'success',
					title: 'Profile Updated',
					showConfirmButton: false,
					timer: 2000
				})  
		  	})
		  	.catch((error) => {
				_this.setState({loading:false})
				swal.fire({
					position: 'top-end',
					type: 'error',
					title: error,
					showConfirmButton: false,
					timer: 2000
				})
		  	})
		  	.finally(() => {
			 	resolve()
		  	})
		})
  	}				
	updatePassword = (oldPassword, newPassword) => {
		let _this = this
		this.setState({loading:true})
		return new Promise(function (resolve, reject) {
		 	_this.props.updateMemberPassword(oldPassword, newPassword)    
		 	.then(() => {
			  	_this.setState({loading:false, showPwBox:false})
			  	swal.fire({
					position: 'top-end',
					type: 'success',
					title: 'Password Updated',
					showConfirmButton: false,
					timer: 2000
			  	})
		 	})
		 	.catch((error) => {
			  	if (error === 'please enter your current password') {
					_this.setState({loading:false, getOldPassword:true})    
			  	} else {
					_this.setState({loading:false})
			  	}                
			  	reject(error)
		 	})
		})
  	}
  	setPassword = (status) => {
		this.setState({showPwBox:status})
  	}

	render() {
		const {loading, showPwBox, getOldPassword} = this.state
		const {memberInfo, photoAdd, photoDelete, photoSet, sideNavToggle} = this.props
		const section = (this.props.match.params.section) ? this.props.match.params.section : 'View-Profile'

		if (!memberInfo) return null
		return (
			<React.Fragment>
				<Loader loading={loading}/>
				<Password showPwBox={showPwBox} getOldPassword={getOldPassword} setPassword={this.setPassword} updatePassword={this.updatePassword}/>
				
				<Container maxWidth='lg' style={{ padding: 10, marginBottom: 150, minHeight:700 }}>
					<Grid container justify='center' spacing={2}>
						<Grid item xs={12} sm={12} md={10} >
							<SubNav/>
						</Grid>
						<Hidden xsDown>
							<Grid item sm={4} md={3}>
							  <SectionSideNav section={section} sideNavToggle={sideNavToggle}/>
							</Grid>
						</Hidden>
						<Grid item xs={12} sm={8} md={7}>
							<SectionTopNav section={section} sideNavToggle={sideNavToggle}/>
							<Switch>
								<Route path='/member/profile/Payments' render={props => <Payments {...props} 
									 userInfo={this.props.memberInfo}
								  />} 
								/>
								<Route path='/member/profile/Photos' render={props => <Photos {...props} 
									 userInfo={this.props.memberInfo}
									 photoAdd={photoAdd}
									 photoDelete={photoDelete}
									 photoSet={photoSet}
								  />} 
								/>
								<Route path='/member/profile/Edit-Profile' render={props => <ProfileEdit {...props} 
									 userInfo={memberInfo} 
									 updateInfo={this.updateInfo}
								  />} 
								/>
								<Route path='/member/profile' render={props => <ProfileView {...props} 
									 userInfo={memberInfo}
									 setPassword={this.setPassword} 
								  />} 
								/>
				 			</Switch>							
						</Grid>
				  	</Grid>
			 	</Container>
			</React.Fragment>
 		)
	}
}
const mapStateToProps = (state) => ({
	 memberInfo: state.auth.userInfo
})
const mapDispatchToProps = {
	 updateMemberInfo,
	 updateMemberPassword,
	 photoAdd, 
	 photoDelete, 
	 photoSet
}
export default connect(mapStateToProps, mapDispatchToProps)(MemberProfileContainer)
