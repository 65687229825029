import React from 'react'
import MemberCard from '../../components/MemberCard'

import Button from '@material-ui/core/Button'
import IconClose from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

const filters = {
    All: 'Both Registered And Unregistered Members',
    LoyalTT: 'Members Registered With LoyalTT',
    Emails:'Members Not Registered With LoyalTT'
}

function GroupMembersMembers({history, roleId, filter, members, handleOpenMember}) {
	const [showMessage, setShowMessage] = React.useState(true)
	const [filterEl, setFilterEl] = React.useState(null)
	let filteredMembers = {}

	// Make sure filter is valid
	if (!['ALL', 'LOYALTT', 'EMAILS'].includes(filter.toUpperCase())) {
		filter = 'All'
	}

	// Set members according to filter
	switch (filter.toUpperCase()) {
		case 'LOYALTT':
			Object.keys(members).map((i) => {
				if (members[i].memberStatus === 'registered') {
					filteredMembers[i] = members[i]
				}
        return null
			})
			break	
		case 'EMAILS':
			Object.keys(members).map((i) => {
				if (members[i].memberStatus === 'unregistered') {
					filteredMembers[i] = members[i]
				}
        return null
			})
			break	
		default:
			filteredMembers = members
	}

	const filterMembers = (f) => {
		setFilterEl(null)
		let path = '/group/members/Members/' + f
      history.push({ pathname: path })
	}

  const handleNewMember = () => {
    let path = '/group/members/Add-Members'
    history.push({ pathname: path })
  }
	return (
		<React.Fragment>
         {(showMessage) ? (
         <div style={{ display: 'flex', padding: 10, backgroundColor: 'rgba(255,193,7,.08)', borderLeft: '2px solid #ffc107' }}>
            <span style={{flexGrow: 1}}>
               <Text style={{marginBottom: 0, lineHeight:1.5}} variant='overline' display='block' gutterBottom>
                  {(roleId === 6) ? (
                   	<div>
                   		Message to Sales Rep.
                  	</div>
                  ) : (
                  	<div>
                  		Message to Owners and Admin.
                  	</div>
                  )}
               </Text>
            </span>
            <span>
               <IconButton aria-label='close' size='small' onClick={() => {setShowMessage(false)}}>
                  <IconClose style={{color: '#c0c0c0'}} />
               </IconButton>
            </span>
         </div> 
         ) : ( null )}

         <Paper style={{marginTop:20, padding:10 }} >
         	<List component="nav" style={{backgroundColor:'#f1f1f1', padding:2}}>
               <ListItem button onClick={(event) => { setFilterEl(event.currentTarget) }} style={{padding:1, textAlign:'center'}}>
                  <ListItemText primary={'Current Filter: ' + filter + ' (' + Object.keys(filteredMembers).length + ')'} secondary={filters[filter]} />
               </ListItem>
            </List> 
            <Menu 
               dense='true' 
               anchorEl={filterEl}
               open={Boolean(filterEl)}
               onClose={() => { setFilterEl(null) }}
            >
               <MenuItem value='All' selected={filter === 'All' } onClick={() => {filterMembers('All')}}>
                 <ListItemText style={{whiteSpace: 'normal', maxWidth:400}} primary='All Members' secondary={filters['All']} />
               </MenuItem>
               <MenuItem value='LoyalTT' selected={filter === 'LoyalTT' } onClick={() => {filterMembers('LoyalTT')}}>
                 <ListItemText style={{whiteSpace: 'normal'}} primary='LoyalTT' secondary={filters['LoyalTT']} />
               </MenuItem>
               <MenuItem value='Emails' selected={filter === 'Emails' } onClick={() => {filterMembers('Emails')}}>
                 <ListItemText style={{whiteSpace: 'normal'}} primary='Emails' secondary={filters['Emails']} />
               </MenuItem>
            </Menu>
			</Paper>

		   <MemberCard members={filteredMembers} handleOpenMember={handleOpenMember} />
             <div style={{marginTop:10, textAlign:'right'}}>
        <Button onClick={() => { handleNewMember() }}>Add Members</Button>
      </div>
		</React.Fragment>
	)
}
export default GroupMembersMembers
