import React from 'react'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

class groupMembersSummary extends React.Component {
	render() {
		return (
			<Container maxWidth='lg' style={{ marginTop:20, marginBottom: 150 }}>
				<Grid container justify='center' spacing={2}>
					<Grid item md={6} sm={6} xs={6}>
						<Paper style={{ padding: 20, minHeight: 100 }} >
							MEMBERS<br/>
							LoyalTT 22<br/>
							Emails  8<br/>
							Total   30
						</Paper>
					</Grid>
					<Grid item md={6} sm={6} xs={6}>
						<Paper style={{ padding: 20, minHeight: 100 }} >
							STATUS<br/>
							Approved 19<br/>
							Other    3<br/>
							Total    22
						</Paper>
					</Grid>
				</Grid>                    
			</Container>
		)
	}
}
export default groupMembersSummary
