import React from 'react'

import Text from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'

const title = 'Page Loading'
const text = 'Loading content, please wait...'

function Loader({loading}){
    if (!loading) return null
    return (
      <div className='loading-ui-overlay'>
        <div className="loading-ui-wrapper">
          <div className="loading-ui-body">
            <CircularProgress color='secondary' style={{ margin: '5px auto'}} />
            <Text variant='h6'>{title}</Text>
            <Text variant='subtitle1'>{text}</Text>
          </div>
        </div>
      </div> 
    )
}
export default Loader