import React from 'react'

import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function GroupSupportNotes() {
	return (
		<Paper style={{ padding: 20, marginTop:20, marginBottom:150, textAlign:'center' }} >
			<Text variant='caption' gutterBottom>
				GROUP SUPPORT NOTES                    
			</Text>
		</Paper>
	)
}
export default GroupSupportNotes
