import React from 'react'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function GroupDashboard() {
	return (
		<div style={{ padding: 10, marginTop:20, marginBottom: 150 }}>
			<div>
				<Grid container justify='flex-start' spacing={2}>
					<Grid item md={4} sm={4} xs={4}>
						<Paper style={{ padding: 20, minHeight: 100 }} >120<br/>Total Members</Paper>
					</Grid>
					<Grid item md={4} sm={4} xs={4}>
						<Paper style={{ padding: 20, minHeight: 100 }} >12/30<br/>Tickets Sold</Paper>
					</Grid>
					<Grid item md={4} sm={4} xs={4}>
						<Paper style={{ padding: 20, minHeight: 100 }} >$22.50<br/>Sales</Paper>
					</Grid>
				</Grid>
			</div>

			<div style={{marginTop:20}}>
				<div style={{ padding: 10, backgroundColor: 'rgba(102,187,106,.08)', borderLeft: '2px solid #66bb6a', marginBottom: 15 }}>
					<Text variant='h6'>Notification Title</Text>
					<Text>notification description here</Text>
				</div>
				<div style={{ padding: 10, backgroundColor: 'rgba(102,187,106,.08)', borderLeft: '2px solid #66bb6a', marginBottom: 15 }}>
					<Text variant='h6'>Notification Title</Text>
					<Text>notification description here</Text>
				</div>
			</div>
		</div>
	)
}
export default GroupDashboard
