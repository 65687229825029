import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { startMonitoringCommunications, stopMonitoringCommunications, addEditPost } from '../../../redux/groupCommunicate/actions'
import { selectorLoadPosts } from '../../../redux/groupCommunicate/selectors'

import Loader from '../../components/Loader'
import AccessDenied from '../../components/AccessDenied'
import SubNav from './components/Subnav'
import SectionSideNav from './components/SectionSideNav'
import SectionTopNav from './components/SectionTopNav'

import Summary from './Summary'
import List from './List'
import New from './New'
import View from './View'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'

const whoHasAccess = [1,2,3] //Owner/Admin, Manager, Editor
class GroupCommunicateContainer extends React.Component {
	state = {
		loading: true,
		filter: '',
		id: null,
	 	posts: {}
   }
   componentDidMount = () => {
	 	let _this = this
	 	this.props.startMonitoringCommunications()
	 	.then(() => {
	 		_this.setState({loading:false})
	 		_this.sortFilter()
	 	})
   }
   componentDidUpdate = (prevProps) => {
	 	if (this.props.match.params !== prevProps.match.params) {
			this.sortFilter()
	 	}
   }
   componentWillUnmount = () => {
	 	this.props.stopMonitoringCommunications()
   }
	sortFilter = () => {
		let posts = {}
		let filter = (this.props.match.params.filter) ? this.props.match.params.filter : ''		
		let id = (this.props.match.params.id) ? this.props.match.params.id : null
		let allPosts = this.props.posts || {}
		
		if (id && allPosts.hasOwnProperty(id)) {	 
		  	posts = { [id]: allPosts[id] }
		} else {
	  		id=null
		 	switch(filter) {
				case 'Posts':
					posts = this.props.posts
					break
				case 'Polls':
					posts = this.props.polls
					break
				case 'Surveys':
					posts = this.props.surveys
					break
				case 'Newsletters':
					posts = this.props.newsletters
					break
				default:
					posts = allPosts
			}
		} 
		this.setState({ filter:filter, id:id, posts:posts })
	}

	render() {
		if (!this.props.roleId) return null
		if(!whoHasAccess.includes(this.props.roleId)) {
			return (<AccessDenied page='groupCommunicate' />)
		}
		const {loading, filter, id, posts} = this.state
		const {addEditPost} = this.props

		return (
			<React.Fragment>
				<Loader loading={loading} />
				<Container maxWidth='lg' style={{ padding: 10, marginBottom: 150, minHeight:700 }}>
					<Grid container justify='center' spacing={2}>
						<Grid item xs={12} sm={12} md={10} >
							<SubNav/>
						</Grid>
						<Hidden xsDown>
							<Grid item sm={4} md={3}>
								<SectionSideNav filter={filter} sideNavToggle={ this.props.sideNavToggle }/>
							</Grid>
						</Hidden>
						<Grid item xs={12} sm={8} md={7}>
							<SectionTopNav filter={filter} sideNavToggle={ this.props.sideNavToggle }/>
							<Switch>
								<Route path='/group/communicate/List' render={props => <List {...props} 
										filter={filter}
										posts={posts}
									/>} 
								/>
								<Route path='/group/communicate/New' render={props => <New {...props} 
										filter={filter}
										posts={posts}
										id={id}
										addEditPost={addEditPost}
									/>} 
								/>
								<Route path='/group/communicate/View' render={props => <View {...props} 
										filter={filter}
										posts={posts}
										id={id}
									/>} 
								/>
								<Route path='/group/communicate' render={props => <Summary {...props} 
										posts={posts}
									/>} 
								/>
							</Switch>
						</Grid>
					</Grid>
				</Container> 
			</React.Fragment>
		)
	}
}
const mapStateToProps = (state) => ({
	roleId: state.auth.loginOptions[state.auth.loginOptionID].roleId,
	allPosts: state.groupCommunicate.posts,
	posts: selectorLoadPosts(state.groupCommunicate.posts, 'posts'),
	polls: selectorLoadPosts(state.groupCommunicate.posts, 'polls'),
	surveys: selectorLoadPosts(state.groupCommunicate.posts, 'surveys'),
	newsletters: selectorLoadPosts(state.groupCommunicate.posts, 'newsletters')
})
const mapDispatchToProps = {
	startMonitoringCommunications, 
	stopMonitoringCommunications,
	addEditPost
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupCommunicateContainer)
