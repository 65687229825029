import React from 'react'
import { withRouter } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'

function SectionSideNav({history, section, categories, sideNavToggle}){
	const handleClick = (value) => {
		let path = '/member/notifications/' + value
		history.push({ pathname: path })
	}
	return (
		<div>
			<List component="nav" aria-label="main mailbox folders"
				style={{backgroundColor:'#fff'}}
				subheader={
				  <ListSubheader component="div">
						<i>Notifications Menu</i>
				  </ListSubheader>
				}
			>
				<ListItem button selected={section === 'Current'} onClick={() => handleClick('Current')}>
					<ListItemText primary="- Current" />
				</ListItem>

				<ListItem button selected={section === 'Saved'} onClick={(event) => handleClick('Saved')}>
					<ListItemText primary="- Saved" />
				</ListItem>
				<Divider/>

				{categories.map((category,i) => {
					return (
						<ListItem button key={i} 
							selected={section === category}
							onClick={(event) => handleClick(category)}
						>
							<ListItemText primary={'- ' + category.replace(/-/g, ' ')} />
						</ListItem>
					)
				})}
				<Divider />
		  		<div align='right'>
		  			<Button onClick={sideNavToggle} size='small' style={{color:'#90a4ae'}}>view full menu</Button>
		  		</div>
			</List>
		</div>
	)
}
export default withRouter(SectionSideNav)
