import React from 'react'

function Info(){
	return (
	  	<div>
			Support Desk Info Dialog<br/>
			this should be quick reference slides highlighting the features of the Support Desk.
		</div>
	)
}
export default Info
