import React from 'react'
import {  withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import MoreVertIcon from '@material-ui/icons/MoreVert';




//import Paper from '@material-ui/core/Paper'

//import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import clsx from 'clsx'
import Collapse from '@material-ui/core/Collapse'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IconButton from '@material-ui/core/IconButton'
import Text from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  root: {
    //maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}))

function Posts({posts}) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
	 
	 return (
		<div style={{width:'100%', padding:5}}>
		    <Card className={classes.root}>
		      <CardHeader
		        avatar={
		          <Avatar aria-label="recipe" className={classes.avatar}>
		            R
		          </Avatar>
		        }
		        action={
		          <IconButton aria-label="settings">
		            <MoreVertIcon />
		          </IconButton>
		        }
		        title="Shrimp and Chorizo Paella"
		        subheader="September 14, 2016"
		      />
		      <CardMedia
		        className={classes.media}
		        image="/images/groups/default-groupProfile.jpg"
		        title="Paella dish"
		      />
		      <CardContent>
		        <Text variant="body2" color="textSecondary" component="p">
		          This impressive paella is a perfect party dish and a fun meal to cook together with your
		          guests. Add 1 cup of frozen peas along with the mussels, if you like.
		        </Text>
		      </CardContent>
		      <CardActions disableSpacing>
		        <IconButton aria-label="add to favorites">
		          <FavoriteIcon />
		        </IconButton>
		        <IconButton aria-label="share">
		          <ShareIcon />
		        </IconButton>
		        <IconButton
		          className={clsx(classes.expand, {
		            [classes.expandOpen]: expanded,
		          })}
		          onClick={handleExpandClick}
		          aria-expanded={expanded}
		          aria-label="show more"
		        >
		          <ExpandMoreIcon />
		        </IconButton>
		      </CardActions>
		      <Collapse in={expanded} timeout="auto" unmountOnExit>
		        <CardContent>
		          <Text paragraph>Method:</Text>
		          <Text paragraph>
		            Heat 1/2 cup of the broth in a pot until simmering, add saffron and set aside for 10
		            minutes.
		          </Text>
		          <Text paragraph>
		            Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet over medium-high
		            heat. Add chicken, shrimp and chorizo, and cook, stirring occasionally until lightly
		            browned, 6 to 8 minutes. Transfer shrimp to a large plate and set aside, leaving chicken
		            and chorizo in the pan. Add pimentón, bay leaves, garlic, tomatoes, onion, salt and
		            pepper, and cook, stirring often until thickened and fragrant, about 10 minutes. Add
		            saffron broth and remaining 4 1/2 cups chicken broth; bring to a boil.
		          </Text>
		          <Text paragraph>
		            Add rice and stir very gently to distribute. Top with artichokes and peppers, and cook
		            without stirring, until most of the liquid is absorbed, 15 to 18 minutes. Reduce heat to
		            medium-low, add reserved shrimp and mussels, tucking them down into the rice, and cook
		            again without stirring, until mussels have opened and rice is just tender, 5 to 7
		            minutes more. (Discard any mussels that don’t open.)
		          </Text>
		          <Text>
		            Set aside off of the heat to let rest for 10 minutes, and then serve.
		          </Text>
		        </CardContent>
		      </Collapse>
		    </Card>
		</div>
	 )
}
export default withRouter(Posts)
