import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'

import SubNav from './components/Subnav'
import Summary from './Summary'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
//import Hidden from '@material-ui/core/Hidden'

class MemberScannerContainer extends React.Component {
	render() {
		return (
			<Container maxWidth='lg' style={{ padding: 10, marginBottom: 150, minHeight:700 }}>
				<Grid container justify='center' spacing={2}>
					<Grid item xs={12} sm={12} md={10} >
						<SubNav/>
					</Grid>
					<Switch>
						<Route path='/member/scanner' render={props => <Summary {...props} />} />
					</Switch>
				</Grid>
			</Container>
		)
	}
}
const mapStateToProps = (state) => ({
})
const mapDispatchToProps = {
}
export default connect(mapStateToProps, mapDispatchToProps)(MemberScannerContainer)
