import React from 'react'

import IconClose from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
//import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function ListAndFilters(props){
	const [showMessage, setShowMessage] = React.useState(true)
	
   return (
		<React.Fragment>
			{(showMessage) ? (
			<div style={{ display: 'flex', padding: 10, backgroundColor: 'rgba(255,193,7,.08)', borderLeft: '2px solid #ffc107' }}>
				<span style={{flexGrow: 1}}>
					<Text style={{marginBottom: 0, lineHeight:1.5}} variant='overline' display='block' gutterBottom>
						<div>
							View Lists and Filter Members.
						</div>
   				</Text>
				</span>
				<span>
					<IconButton aria-label='close' size='small' onClick={() => {setShowMessage(false)}}>
						<IconClose style={{color: '#c0c0c0'}} />
					</IconButton>
				</span>
			</div> 
			) : ( null )}
		</React.Fragment>
   )
}
export default ListAndFilters
