import React from 'react'

import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Text from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

const options = {
  	PUBLIC: 'Anyone can join automatically - Group can invite anyone',
  	PRIVATE: 'Anyone can request to join - Group can invite anyone',
  	CLOSED: 'Requests to join not accepted - Group can invite anyone'
}

class Step1 extends React.Component {
	state = {
		typeEl: null,
		name: this.props.group.name,
		summary: this.props.group.summary,
		type: this.props.group.type,
		message: ''
	}
	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}
	handleNext = () => {
		let {name, summary, type} = this.state
		if (name === '' || summary === '' || type === '') {
			return this.setState({ message: 'All fields are required.'})
		}
		this.props.updateInfo(name, summary, type)
	}
	render() {
		return (
			<div>
				<form noValidate autoComplete='off'>
				  	<TextField fullWidth
					 	label='Group Name'
					 	name='name'
					 	value={this.state.name}
					 	onChange={this.handleChange}
					 	margin='normal'
				  	/>
				  	<TextField fullWidth
					 	multiline
					 	rowsMax='7'
					 	label='Group Summary'
					 	name='summary'
					 	value={this.state.summary}
					 	onChange={this.handleChange}
					 	margin='normal'
				  	/>
				  	<div style={{marginTop:20}}><Text variant="caption" display="block" style={{color:'#90a4ae'}}>Select the type of group you would like to create. You can always change this in your Group Settings.</Text></div>
				  	<List component="nav" style={{backgroundColor:'#f1f1f1', padding:2}}>
					 	<ListItem button onClick={(event) => { this.setState({typeEl: event.currentTarget}) }} style={{padding:10}}>
							<ListItemText primary={'Type of Group: ' + this.state.type} secondary={options[this.state.type]} />
					 	</ListItem>
				  	</List>             
				  	<Menu 
					 	dense='true' 
					 	anchorEl={this.state.typeEl}
					 	open={Boolean(this.state.typeEl)}
					 	onClose={() => {this.setState({typeEl: null}) }}
				  	>
					 	<MenuItem value='PUBLIC' selected={this.state.type === 'PUBLIC' } onClick={() => {this.setState({type: 'PUBLIC', typeEl: null})}}>
							<ListItemText style={{whiteSpace: 'normal'}} primary='PUBLIC' secondary={options['PUBLIC']} />
					 	</MenuItem>
					 	<MenuItem value='PRIVATE' selected={this.state.type === 'PRIVATE' } onClick={() => {this.setState({type: 'PRIVATE', typeEl: null})}}>
							<ListItemText style={{whiteSpace: 'normal'}} primary='PRIVATE' secondary={options['PRIVATE']} />
					 	</MenuItem>
					 	<MenuItem value='CLOSED' selected={this.state.type === 'CLOSED' } onClick={() => {this.setState({type: 'CLOSED', typeEl: null})}}>
							<ListItemText style={{whiteSpace: 'normal'}} primary='CLOSED' secondary={options['CLOSED']} />
					 	</MenuItem>
				  	</Menu>
				</form>
				<div align='center' style={{ height: 25, padding: 10 }}>
					<Text variant='caption' style={{ color: 'red' }}>
						{this.state.message}
					</Text>
				</div>
				<div align='right'>
				 	<Button variant='contained' color='primary' onClick={this.handleNext}>
						next
				 	</Button>
				</div>				
			</div>
		)
	}
}
export default Step1
