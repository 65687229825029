import React from 'react'
import QrReader from 'react-qr-reader'

import AppBar from '@material-ui/core/AppBar'

import Button from '@material-ui/core/Button'

import Toolbar from '@material-ui/core/Toolbar'
import Dialog from '@material-ui/core/Dialog'

import IconButton from '@material-ui/core/IconButton'
import ImageIcon from '@material-ui/icons/CropOriginal'
import CloseIcon from '@material-ui/icons/Close'
import withMobileDialog from '@material-ui/core/withMobileDialog'


//import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'



class QrScanner extends React.Component {
 	state = {
 		data:''
 	}

 	handleScan = data => {
    if (data) {
    	this.setState({data:data})
      this.props.cb(data)
    }
  }
  handleError = err => {
    console.error(err)
  }

	render() {
		const {fullScreen, openUi, closeUi} = this.props
		return (
			<Dialog
				open={ openUi }
				fullScreen={ fullScreen }
				scroll = { (fullScreen) ? 'paper' : 'body' }
				disableBackdropClick={ true }
			>
				<AppBar style={{ position: 'relative' }} >
						<Toolbar>
								<ImageIcon style={{ marginRight: 5 }} />
								<Text variant='subtitle2' color='inherit' style={{ flex: 1 }}>
										Image Upload
								</Text>
								<IconButton color='inherit' onClick={() => { closeUi(false) }}>
										<CloseIcon />
								</IconButton>
						</Toolbar>
				</AppBar>
					<DialogContent>
						<div>
					      <QrReader
					          delay={300}
					          onError={this.handleError}
					          onScan={this.handleScan}
					          style={{ width: '100%' }}
					      />
					      <div>{this.state.data}</div>
					   </div>
					</DialogContent>
					<DialogActions>
							<Button onClick={() => { closeUi(false) }} variant='contained'>
									Cancel
							</Button>
							<Button onClick={() => { closeUi(false) }} variant='contained' color='primary'>
									Close
							</Button>
					</DialogActions>
			</Dialog>
		)
	}
}
export default withMobileDialog()(QrScanner)
