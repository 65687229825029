import React from 'react'
import { withRouter } from 'react-router-dom'

import Text from '@material-ui/core/Typography'

function AddMembersUpload(props) {
    return (
        <div style={{width:'100%', textAlign:'center'}}>
            <Text variant='overline' gutterBottom>
                this feature is under development
            </Text>
        </div>
    )
}
export default withRouter(AddMembersUpload)

// check out this article to upload from google sheets
// https://robkendal.co.uk/blog/reading-google-sheets-data-using-javascript-with-google-sheets-reader/