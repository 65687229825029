import React from 'react'
import { Route, Redirect, Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import LayoutPrivate from './LayoutPrivate'
import LayoutPublic from './LayoutPublic'

class App extends React.Component {
	render(){
		window.scrollTo(0, 0)
		const {loggedIn} = this.props
		return (
			<Switch>
				<Route path='/group' render={(props) => loggedIn === true
					? <LayoutPrivate {...props} />
					: <Redirect to={'/login?rURL=' + props.location.pathname} />}  // props.location.pathname = /foo/bar
				/>
				<Route path='/member' render={(props) => loggedIn === true
					? <LayoutPrivate {...props} />
					: <Redirect to={'/login?rURL=' + props.location.pathname} />} // props.location.pathname = /foo/bar
				/>
				<Route path='/' render={(props) => loggedIn === false
					? <LayoutPublic {...props} />
					: <Redirect to={'/member/dashboard' + props.location.search  } />} // props.location.search = ?foo=bar&other=thing
				/>
			</Switch>
		)
	}
}

const mapStateToProps = (state) => ({
	loggedIn: state.auth.loggedIn
})
export default connect(mapStateToProps)(withRouter(App))
