import React from 'react'
import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import Drawer from '@material-ui/core/SwipeableDrawer'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import IconButton from '@material-ui/core/IconButton'
import IconDashboard from '@material-ui/icons/Dashboard'
import IconEvent from '@material-ui/icons/Event'
import IconLiveHelp from '@material-ui/icons/LiveHelp'
import IconMessage from '@material-ui/icons/Message'
import IconMyMemberships from '@material-ui/icons/GroupAdd'
import IconPortrait from '@material-ui/icons/Portrait'
import IconSettings from '@material-ui/icons/Tune'
import IconShare from '@material-ui/icons/Share'
import IconSlideshow from '@material-ui/icons/Slideshow'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'

const useStyles = (theme) => ({
	list: {
		width: 300
	},
	active: {
		backgroundColor: '#E8E8E8'
	},
	inactive: {
		backgroundColor: '#ffffff'
	}
})

class PrivateSidenav extends React.Component {
	state = {
		openGroupSettings: false,
		openGroupMembers: false,
		openGroupSupport: false,
		openGroupCommunicate: false
	}
	componentDidUpdate = (prevProps) => {
		if (this.props !== prevProps) {
			if (this.props.location.pathname.includes('/group/settings/')) {
				this.setState({openGroupSettings:true})
			}
			if (this.props.location.pathname.includes('/group/members/')) {
				this.setState({openGroupMembers:true})
			}
			if (this.props.location.pathname.includes('/group/support/')) {
				this.setState({openGroupSupport:true})
			}
			if (this.props.location.pathname.includes('/group/communicate/')) {
				this.setState({openGroupCommunicate:true})
			}
		}	
	}
	clickLink = (link) => {
		this.props.history.push({ pathname: link })
		this.props.sideNavToggle()
		this.setState({ openGroupSettings:false, openGroupMembers:false, openGroupSupport:false, openGroupCommunicate:false })
	}
	toggleMenu = (menu) => {
		let status = this.state[menu]
		this.setState({[menu]: !status})
	}
	render() {
		const {openGroupSettings, openGroupMembers, openGroupSupport, openGroupCommunicate} = this.state
		const {classes, location, selectedOption, sideNavOpen, sideNavToggle} = this.props
		return (
			<div>
				<Drawer open={sideNavOpen} onClose={sideNavToggle} onOpen={sideNavToggle} >
				<div className={classes.list}>
					{(selectedOption.login === 'group') ? (
						<React.Fragment>
							<div align='center'><img src={ selectedOption.thumb } style={{ width: '75%', borderRadius: 20, border: '1.5px solid #ddd', margin: '10px auto' }} alt='' /></div>
							<List component="nav"
								subheader={
								<ListSubheader component="div">
								  { selectedOption.name.toUpperCase() } MENU
								</ListSubheader>
							  }
							>
								<ListItem button onClick={() => { this.clickLink('/group/dashboard') }}
									className={ (location.pathname.includes('/group/dashboard')) ? classes.active : classes.inactive }
								>
									<ListItemIcon><IconDashboard /></ListItemIcon>
									<ListItemText primary='Dashboard' />
								</ListItem>
							
								<ListItem style={{paddingTop:0, paddingBottom:0}}>
									<Button fullWidth style={{textTransform: 'none', textAlign: 'left', padding:0, margin:0}}
										onClick={() => { this.clickLink('/group/settings/Profile') }}
									>
										<ListItemIcon><IconSettings /></ListItemIcon>
										<ListItemText primary='Group Settings' />
									</Button>
									{openGroupSettings 
										? <IconButton onClick={() => {this.toggleMenu('openGroupSettings')} }><ExpandLess /></IconButton> 
										: <IconButton onClick={() => {this.toggleMenu('openGroupSettings')} }><ExpandMore/></IconButton>
									}
								</ListItem>
									<Collapse in={openGroupSettings} timeout="auto" unmountOnExit>
										<List dense={true} component="div" disablePadding style={{paddingLeft:55}}>
											<ListItem button onClick={() => { this.clickLink('/group/settings/Profile') }}
												className={ (location.pathname.includes('/group/settings/Profile')) ? classes.active : classes.inactive }
											>
												<ListItemText primary='- Profile' style={{fontStyle:'italic'}} />
											</ListItem>
											<ListItem button onClick={() => { this.clickLink('/group/settings/Plugins') }}
												className={ (location.pathname.includes('/group/settings/Plugins')) ? classes.active : classes.inactive }
											>
												<ListItemText primary='- Plugins' style={{fontStyle:'italic'}} />
											</ListItem>
											<ListItem button onClick={() => { this.clickLink('/group/settings/Payment-Options') }}
												className={ (location.pathname.includes('/group/settings/Payment-Options')) ? classes.active : classes.inactive }
											>
												<ListItemText primary='- Payment Options' style={{fontStyle:'italic'}} />
											</ListItem>
											<ListItem button onClick={() => { this.clickLink('/group/settings/Invoices') }}
												className={ (location.pathname.includes('/group/settings/Invoices')) ? classes.active : classes.inactive }
											>
												<ListItemText primary='- Invoices' style={{fontStyle:'italic'}} />
											</ListItem>
											<ListItem button onClick={() => { this.clickLink('/group/settings/Verification') }}
												className={ (location.pathname.includes('/group/settings/Verification')) ? classes.active : classes.inactive }
											>
												<ListItemText primary='- Verification' style={{fontStyle:'italic'}} />
											</ListItem>
										</List>
									</Collapse>	

								<ListItem style={{paddingTop:0, paddingBottom:0}}>
									<Button fullWidth style={{textTransform: 'none', textAlign: 'left', padding:0, margin:0}}
										onClick={() => { this.clickLink('/group/members/Summary') }}
									>
										<ListItemIcon>
										<IconMyMemberships />
										</ListItemIcon>
										<ListItemText primary='Group Members' />
									</Button>
									{openGroupMembers 
										? <IconButton onClick={() => { this.toggleMenu('openGroupMembers')} }><ExpandLess /></IconButton> 
										: <IconButton onClick={() => { this.toggleMenu('openGroupMembers')} }><ExpandMore/></IconButton>
									}
								</ListItem>

									<Collapse in={openGroupMembers} timeout="auto" unmountOnExit>
										<List dense={true} component="div" disablePadding style={{paddingLeft:55}}>
											<ListItem button onClick={() => { this.clickLink('/group/members/Members') }} 
												className={ (location.pathname.includes('/group/members/Members')) ? classes.active : classes.inactive }
											>
												<ListItemText primary='- All Members' style={{fontStyle:'italic'}} />
											</ListItem>
											<ListItem button onClick={() => { this.clickLink('/group/members/Lists+Filters') }} 
												className={ (location.pathname.includes('/group/members/List+Filters')) ? classes.active : classes.inactive }
											>
												<ListItemText primary='- Lists & Filters' style={{fontStyle:'italic'}} />
											</ListItem>
											<ListItem button onClick={() => { this.clickLink('/group/members/Add-Members') }} 
												className={ (location.pathname.includes('/group/members/Add-Members')) ? classes.active : classes.inactive }
											>
												<ListItemText primary='- Add Members' style={{fontStyle:'italic'}} />
											</ListItem>
											<ListItem button onClick={() => { this.clickLink('/group/members/Team') }} 
												className={ (location.pathname.includes('/group/members/Team')) ? classes.active : classes.inactive }
											>
												<ListItemText primary='- Team' style={{fontStyle:'italic'}} />
											</ListItem>
											<ListItem button onClick={() => { this.clickLink('/group/members/Crews') }} 
												className={ (location.pathname.includes('/group/members/Crews')) ? classes.active : classes.inactive }
											>
												<ListItemText primary='- Crews' style={{fontStyle:'italic'}} />
											</ListItem>
											<ListItem button onClick={() => { this.clickLink('/group/members/Notes') }} 
												className={ (location.pathname.includes('/group/members/Notes')) ? classes.active : classes.inactive }
											>
												<ListItemText primary='- Notes' style={{fontStyle:'italic'}} />
											</ListItem>
										</List>
									</Collapse>

								<ListItem style={{paddingTop:0, paddingBottom:0}}>
									<Button fullWidth style={{textTransform: 'none', textAlign: 'left', padding:0, margin:0}}
										onClick={() => { this.clickLink('/group/support/Unassigned') }}
									>
										<ListItemIcon><IconLiveHelp /></ListItemIcon>
										<ListItemText primary='Group Support' />
									</Button>
									{openGroupSupport 
										? <IconButton onClick={() => { this.toggleMenu('openGroupSupport')} }><ExpandLess /></IconButton> 
										: <IconButton onClick={() => { this.toggleMenu('openGroupSupport')} }><ExpandMore/></IconButton>
									}
								</ListItem>
									<Collapse in={openGroupSupport} timeout="auto" unmountOnExit>
										<List dense={true} component="div" disablePadding style={{paddingLeft:55}}>
											<ListItem button onClick={() => { this.clickLink('/group/support/Unassigned') }}
												className={ (location.pathname.includes('/group/support/Unassigned')) ? classes.active : classes.inactive }
											>
												<ListItemText primary='- Unassigned' style={{fontStyle:'italic'}} />
											</ListItem>
											<ListItem button onClick={() => { this.clickLink('/group/support/Open') }}
												className={ (location.pathname.includes('/group/support/Open')) ? classes.active : classes.inactive }
											>
												<ListItemText primary='- Open' style={{fontStyle:'italic'}} />
											</ListItem>
											<ListItem button onClick={() => { this.clickLink('/group/support/Closed') }}
												className={ (location.pathname.includes('/group/support/Closed')) ? classes.active : classes.inactive }
											>
												<ListItemText primary='- Closed' style={{fontStyle:'italic'}} />
											</ListItem>
											<ListItem button onClick={() => { this.clickLink('/group/support/Archived') }}
												className={ (location.pathname.includes('/group/support/Archived')) ? classes.active : classes.inactive }
											>
												<ListItemText primary='- Archived' style={{fontStyle:'italic'}} />
											</ListItem>
											<ListItem button onClick={() => { this.clickLink('/group/support/Notes') }}
												className={ (location.pathname.includes('/group/support/Notes')) ? classes.active : classes.inactive }
											>
												<ListItemText primary='- Notes' style={{fontStyle:'italic'}} />
											</ListItem>
										</List>
									</Collapse>	

								<ListItem style={{paddingTop:0, paddingBottom:0}}>
									<Button fullWidth style={{textTransform: 'none', textAlign: 'left', padding:0, margin:0}}
										onClick={() => { this.clickLink('/group/communicate') }}
									>
										<ListItemIcon><IconShare /></ListItemIcon>
										<ListItemText primary='Communicate' />
									</Button>
									{openGroupCommunicate
										? <IconButton onClick={() => {this.toggleMenu('openGroupCommunicate')} }><ExpandLess /></IconButton> 
										: <IconButton onClick={() => {this.toggleMenu('openGroupCommunicate')} }><ExpandMore/></IconButton>
									}
								</ListItem>
									<Collapse in={openGroupCommunicate} timeout="auto" unmountOnExit>
										<List dense={true} component="div" disablePadding style={{paddingLeft:55}}>
											<ListItem button onClick={() => { this.clickLink('/group/communicate/List/Posts') }}
												className={ (location.pathname.includes('/group/communicate/List/Posts')) ? classes.active : classes.inactive }
											>
												<ListItemText primary='- Posts' style={{fontStyle:'italic'}} />
											</ListItem>
											<ListItem button onClick={() => { this.clickLink('/group/communicate/List/Polls') }}
												className={ (location.pathname.includes('/group/communicate/List/Polls')) ? classes.active : classes.inactive }
											>
												<ListItemText primary='- Polls' style={{fontStyle:'italic'}} />
											</ListItem>
											<ListItem button onClick={() => { this.clickLink('/group/communicate/List/Surveys') }}
												className={ (location.pathname.includes('/group/communicate/List/Surveys')) ? classes.active : classes.inactive }
											>
												<ListItemText primary='- Surveys' style={{fontStyle:'italic'}} />
											</ListItem>
										</List>
									</Collapse>	

								<ListItem button onClick={() => { this.clickLink('/group/events') }}
									className={ (location.pathname.includes('/group/events')) ? classes.active : classes.inactive }
								>
									<ListItemIcon>
									<IconEvent />
									</ListItemIcon>
									<ListItemText primary='Events' />
								</ListItem>
							</List>
						</React.Fragment>
					) : (null)}

					{(selectedOption.login === 'member') ? (
						<React.Fragment>
							<div align='center'><img src={ selectedOption.thumb } style={{ width: '75%', borderRadius: 20, border: '1.5px solid #ddd', margin: '10px auto' }} alt='' /></div>
							<List>                
							<ListItem button onClick={() => { this.clickLink('/member/dashboard') }}
								className={ (location.pathname.includes('/member/dashboard')) ? classes.active : classes.inactive }
							>
								<ListItemIcon>
								<IconDashboard />
								</ListItemIcon>
								<ListItemText primary='Dashboard' />
							</ListItem>

							<ListItem button onClick={() => { this.clickLink('/member/profile') }}
								className={ (location.pathname.includes('/member/profile')) ? classes.active : classes.inactive }
							>
								<ListItemIcon>
								<IconPortrait />
								</ListItemIcon>
								<ListItemText primary='Profile Settings' />
							</ListItem>

							<ListItem button onClick={() => { this.clickLink('/member/memberships') }}
								className={ (location.pathname.includes('/member/memberships')) ? classes.active : classes.inactive }
							>
								<ListItemIcon>
								<IconMyMemberships />
								</ListItemIcon>
								<ListItemText primary='Memberships' />
							</ListItem>

							<ListItem button onClick={() => { this.clickLink('/member/notifications') }}
								className={ (location.pathname.includes('/member/notifications')) ? classes.active : classes.inactive }
							>
								<ListItemIcon>
								<IconMessage />
								</ListItemIcon>
								<ListItemText primary='Notifications' />
							</ListItem>

							<ListItem button onClick={() => { this.clickLink('/member/support') }}
								className={ (location.pathname.includes('/member/support')) ? classes.active : classes.inactive }
							>
								<ListItemIcon>
								<IconLiveHelp />                                       
								</ListItemIcon>                            
								<ListItemText primary='Support Desk' />
							</ListItem>

							<ListItem button onClick={() => { this.clickLink('/member/loyaltt') }}
								className={ (location.pathname.includes('/member/loyaltt')) ? classes.active : classes.inactive }
							>
								<ListItemIcon>
								<IconSlideshow />
								</ListItemIcon>
								<ListItemText primary='LoyalTT Explained' />
							</ListItem>

							<ListItem button onClick={() => { this.clickLink('/member/share') }}
								className={ (location.pathname.includes('/member/share')) ? classes.active : classes.inactive }
							>
								<ListItemIcon>
								<IconShare />
								</ListItemIcon>
								<ListItemText primary='Share LoyalTT' />
							</ListItem>

							</List>
						</React.Fragment>
					) : (null)}
				</div>
				</Drawer>
			</div>
		)
	}
}

export default withRouter(withStyles(useStyles)(PrivateSidenav))
