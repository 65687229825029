import React from 'react'

function Info(){
   return (
      <div>
         Scanner Dialog<br/>
         this should be quick reference slides highlighting the features of the Scanner.
      </div>
   )
}
export default Info
