import React from 'react'
import { withRouter } from 'react-router-dom'
import MemberCard from '../../../components/MemberCard'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Text from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
	thumb: {
		width: 75,
		height: 75, 
		verticalAlign: 'top', 
		marginRight: 10,
		border: '1px solid #ccc', 
		borderRadius: 7
	},
	linkGroupName: {
		color: 'red', 
		textDecoration: 'none',
		lineHeight: 500,
		padding: 0

	},
	linkCreateGroupName: {
		color: '#b3b3b3', 
		textDecoration: 'none',
		padding: 0

	},
	cardContent: {
		display: 'flex',
		padding: 5,
		
	},
	cardActions: {
		backgroundColor: '#f7f8fa',
		padding: 0
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: '33.33%',
		flexShrink: 0,
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary
	}
}))

function TeamListMembers({history, selectedRole, teamMembers}){
	const classes = useStyles()
	const [expanded, setExpanded] = React.useState(selectedRole)

	const handleOpenMember = (memberId) => {
	  	let url = '/group/members/Team/List/' + memberId + '/?r=' + selectedRole
		history.push({ pathname: url })
   }
	const handleNewMember = (r) => {
		let path = '/group/members/Team/New//?r=' + r
		history.push({ pathname: path })
	} 
	const handleChangePanel = (panel) => {
		let action = (expanded === panel) ? false : panel
		setExpanded(action)
   }

	return (
		<React.Fragment>
		 	<div style={{marginTop:20, width: '100%'}}>
				<ExpansionPanel expanded={expanded === 'Owner'} onChange={() => {handleChangePanel('Owner')}}>
				   <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
					  	<Text className={classes.heading}>Owner</Text>
					  	<div style={{flexGrow: 1, textAlign:'right'}}>
						 	<Text className={classes.secondaryHeading}>{teamMembers['Owner'].activeCount} / {teamMembers['Owner'].totalCount}</Text>
					  	</div>
				   </ExpansionPanelSummary>
					{(Object.keys(teamMembers['Owner'].active).length) ? (
						<div style={{margin:10}}><MemberCard members={teamMembers['Owner'].active} handleOpenMember={handleOpenMember} /></div>
					) : (null)}  
					{(Object.keys(teamMembers['Owner'].pending).length) ? (
						<div style={{margin:10}}><MemberCard members={teamMembers['Owner'].pending} handleOpenMember={handleOpenMember} /></div>
					) : (null)} 
				</ExpansionPanel>

				<ExpansionPanel expanded={expanded === 'Admin'} onChange={() => {handleChangePanel('Admin')}}>
					<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
					   <Text className={classes.heading}>Admins</Text>
					   <div style={{flexGrow: 1, textAlign:'right'}}>
							<Text className={classes.secondaryHeading}>{teamMembers['Admin'].activeCount} / {teamMembers['Admin'].totalCount}</Text>
					   </div>
					</ExpansionPanelSummary>
					{(Object.keys(teamMembers['Admin'].active).length) ? (
						<div style={{margin:10}}><MemberCard members={teamMembers['Admin'].active} handleOpenMember={handleOpenMember} /></div>
					) : (null)}  
					{(Object.keys(teamMembers['Admin'].pending).length) ? (
						<div style={{margin:10}}><MemberCard members={teamMembers['Admin'].pending} handleOpenMember={handleOpenMember} /></div>
					) : (null)}
					<div style={{textAlign: 'right', paddingRight: 2}}><Button onClick={() => {handleNewMember('Admin')}}>Add a Admin</Button></div>
				</ExpansionPanel>

				<ExpansionPanel expanded={expanded === 'Manager'} onChange={() => {handleChangePanel('Manager')}}>
					<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
					   <Text className={classes.heading}>Managers</Text>
					   <div style={{flexGrow: 1, textAlign:'right'}}>
						  	<Text className={classes.secondaryHeading}>{teamMembers['Manager'].activeCount} / {teamMembers['Manager'].totalCount}</Text>
					   </div>
					</ExpansionPanelSummary>
					{(Object.keys(teamMembers['Manager'].active).length) ? (
						<div style={{margin:10}}><MemberCard members={teamMembers['Manager'].active} handleOpenMember={handleOpenMember} /></div>
					) : (null)}  
					{(Object.keys(teamMembers['Manager'].pending).length) ? (
						<div style={{margin:10}}><MemberCard members={teamMembers['Manager'].pending} handleOpenMember={handleOpenMember} /></div>
					) : (null)}
					<div style={{textAlign: 'right', paddingRight: 2}}><Button onClick={() => {handleNewMember('Manager')}}>Add a Manager</Button></div>
				</ExpansionPanel>

				<ExpansionPanel expanded={expanded === 'Editor'} onChange={() => {handleChangePanel('Editor')}}>
					<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
					   <Text className={classes.heading}>Editors</Text>
					   <div style={{flexGrow: 1, textAlign:'right'}}>
						  	<Text className={classes.secondaryHeading}>{teamMembers['Editor'].activeCount} / {teamMembers['Editor'].totalCount}</Text>
					   </div>
					</ExpansionPanelSummary>
					{(Object.keys(teamMembers['Editor'].active).length) ? (
						<div style={{margin:10}}><MemberCard members={teamMembers['Editor'].active} handleOpenMember={handleOpenMember} /></div>
					) : (null)}  
					{(Object.keys(teamMembers['Editor'].pending).length) ? (
						<div style={{margin:10}}><MemberCard members={teamMembers['Editor'].pending} handleOpenMember={handleOpenMember} /></div>
					) : (null)}
					<div style={{textAlign: 'right', paddingRight: 2}}><Button onClick={() => {handleNewMember('Editor')}}>Add a Editor</Button></div>
				</ExpansionPanel>

				<ExpansionPanel expanded={expanded === 'CSR'} onChange={() => {handleChangePanel('CSR')}}>
					<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
					   <Text className={classes.heading}>CSRs</Text>
					   <div style={{flexGrow: 1, textAlign:'right'}}>
						  	<Text className={classes.secondaryHeading}>{teamMembers['CSR'].activeCount} / {teamMembers['CSR'].totalCount}</Text>
					   </div>
					</ExpansionPanelSummary>
					{(Object.keys(teamMembers['CSR'].active).length) ? (
						<div style={{margin:10}}><MemberCard members={teamMembers['CSR'].active} handleOpenMember={handleOpenMember} /></div>
					) : (null)}  
					{(Object.keys(teamMembers['CSR'].pending).length) ? (
						<div style={{margin:10}}><MemberCard members={teamMembers['CSR'].pending} handleOpenMember={handleOpenMember} /></div>
					) : (null)}
					<div style={{textAlign: 'right', paddingRight: 2}}><Button onClick={() => {handleNewMember('CSR')}}>Add a CSR</Button></div>
				</ExpansionPanel>

				<ExpansionPanel expanded={expanded === 'Attendant'} onChange={() => {handleChangePanel('Attendant')}}>
					<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
						<Text className={classes.heading}>Attendants</Text>
						<div style={{flexGrow: 1, textAlign:'right'}}>
						   <Text className={classes.secondaryHeading}>{teamMembers['Attendant'].activeCount} / {teamMembers['Attendant'].totalCount}</Text>
						</div>
					</ExpansionPanelSummary>
					{(Object.keys(teamMembers['Attendant'].active).length) ? (
						<div style={{margin:10}}><MemberCard members={teamMembers['Attendant'].active} handleOpenMember={handleOpenMember} /></div>
					) : (null)}  
					{(Object.keys(teamMembers['Attendant'].pending).length) ? (
						<div style={{margin:10}}><MemberCard members={teamMembers['Attendant'].pending} handleOpenMember={handleOpenMember} /></div>
					) : (null)}
					<div style={{textAlign: 'right', paddingRight: 2}}><Button onClick={() => {handleNewMember('Attendant')}}>Add a Attendant</Button></div>
				</ExpansionPanel>

				<ExpansionPanel expanded={expanded === 'Sales_Rep'} onChange={() => {handleChangePanel('Sales_Rep')}}>
					<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
					   <Text className={classes.heading}>Sales Reps.</Text>
					   <div style={{flexGrow: 1, textAlign:'right'}}>
						  	<Text className={classes.secondaryHeading}>{teamMembers['Sales_Rep'].activeCount} / {teamMembers['Sales_Rep'].totalCount}</Text>
					   </div>
					</ExpansionPanelSummary>
					{(Object.keys(teamMembers['Sales_Rep'].active).length) ? (
						<div style={{margin:10}}><MemberCard members={teamMembers['Sales_Rep'].active} handleOpenMember={handleOpenMember} /></div>
					) : (null)}  
					{(Object.keys(teamMembers['Sales_Rep'].pending).length) ? (
						<div style={{margin:10}}><MemberCard members={teamMembers['Sales_Rep'].pending} handleOpenMember={handleOpenMember} /></div>
					) : (null)}
					<div style={{textAlign: 'right', paddingRight: 2}}><Button onClick={() => {handleNewMember('Sales_Rep')}}>Add a Sales Rep</Button></div>
				</ExpansionPanel>
		 	</div>
		 	<div style={{marginTop:10, textAlign:'right'}}>
				<Button onClick={() => { handleNewMember('') }}>Add Team Member</Button>
			</div>
		</React.Fragment>
	)
}
export default withRouter(TeamListMembers)
