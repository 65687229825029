import React from 'react'
import { withRouter } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
//import InboxIcon from '@material-ui/icons/Inbox'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
//import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'

function SectionSideNav(props){
   const handleClick = (value) => {
    	let path = '/member/profile/' + value
      props.history.push({ pathname: path })
   }

   const section = props.section
	return (
		<div>
			<List component="nav" aria-label="main mailbox folders"
				style={{backgroundColor:'#fff'}}
				subheader={
		        <ListSubheader component="div">
		          <i>My Profile Menu</i>
		        </ListSubheader>
		      }
			>
		   	<ListItem button selected={section === 'View-Profile'} onClick={() => handleClick('View-Profile')}>
			 		<ListItemText primary="- View Profile" />
		  		</ListItem>

		  		<ListItem button selected={section === 'Edit-Profile'} onClick={(event) => handleClick('Edit-Profile')}>
			 		<ListItemText primary="- Edit Profile" />
		  		</ListItem>

		  		<ListItem button selected={section === 'Photos'} onClick={(event) => handleClick('Photos')}>
			 		<ListItemText primary="- Photos" />
		  		</ListItem>

		  		<ListItem button selected={section === 'Payments'} onClick={(event) => handleClick('Payments')}>
			 		<ListItemText primary="- Payments" />
		  		</ListItem>
		  		<Divider />
		  		<div align='right'>
		  			<Button onClick={props.sideNavToggle} size='small' style={{color:'#90a4ae'}}>view full menu</Button>
		  		</div>
		  	</List>
		</div>
	)
}
export default withRouter(SectionSideNav)
