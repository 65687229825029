import React from 'react'
import QrScanner from '../../components/QrScanner'

import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function MemberScannerSummary() {
	const [openScanner, setOpenScanner] = React.useState(false)
	const handleScan = (scan) => {
		console.log(scan)
	}

	return (
		<React.Fragment>
		<QrScanner openUi={openScanner} closeUi={setOpenScanner} cb={handleScan}/>
		<Container maxWidth='xl' style={{ padding: 10 }}>
		<Grid container justify='center' spacing={2}>
			<Grid item xs={12} sm={8} md={8} >
				<Paper style={{ padding: 20 }} >
					<Text variant='h5' gutterBottom>
						MEMBER SCANNER
					</Text>
					<Text variant='caption' gutterBottom>
						This is the MEMBER SCANNER page.
						<input type="file" accept="image/*" capture="camera" />
					</Text>
					 <Button onClick={() => {setOpenScanner(true)}}>open scanner</Button>
				</Paper>
			</Grid>
		</Grid>
		</Container>
		</React.Fragment>
	)
}

export default MemberScannerSummary
