import React from 'react'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function DashboardPosts(){
   return (
      <Paper style={{ padding: 20, marginTop:20, textAlign:'center'}} >
       	<Text style={{lineHeight:.8}} variant='caption' display='block' color='textSecondary'>
           	Posts
         </Text>
      </Paper>
   )
}
export default DashboardPosts
