import React from 'react'
import { withRouter } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'

function SectionSideNav({history, section, filter, sideNavToggle}){
	const handleClick = (value) => {
		let path = '/member/dashboard/' + value + '/' + filter
		history.push({ pathname: path })
	}
	return (
		<div>
			<List component="nav" aria-label="main mailbox folders"
				style={{backgroundColor:'#fff'}}
				subheader={
				  <ListSubheader component="div" id="nested-list-subheader">
					 <i>Welcome to LoyalTT</i>
				  </ListSubheader>
				}
			>
				<ListItem button selected={section === 'Summary'} onClick={() => handleClick('')}>
					<ListItemText primary="- Summary" />
				</ListItem>

				<ListItem button selected={section === 'Posts'} onClick={(event) => handleClick('Posts')}>
					<ListItemText primary="- Groups Posts" />
				</ListItem>

				<ListItem button selected={section === 'Savings'} onClick={(event) => handleClick('Savings')}>
					<ListItemText primary="- Savings" />
				</ListItem>

				<ListItem button selected={section === 'Points'} onClick={(event) => handleClick('Points')}>
					<ListItemText primary="- LoyalTT Points" />
				</ListItem>
				<Divider />
		  		<div align='right'>
		  			<Button onClick={sideNavToggle} size='small' style={{color:'#90a4ae'}}>view full menu</Button>
		  		</div>
			</List>
		</div>
	)
}
export default withRouter(SectionSideNav)
