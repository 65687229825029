import React from 'react'

import Text from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'

function SectionTopNav({login}){
	return (
		<Toolbar variant="dense" style={{ paddingLeft:5, display: 'flex', borderBottom: '2px solid #ccc'}}>
			<Text variant='h6' color='primary' style={{paddingLeft:5}}>
				{login.name.toUpperCase()}
			</Text>
		</Toolbar>
	)
}
export default SectionTopNav
