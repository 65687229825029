export const selectorSortNotes = (notifications) => {
	let sorted = {}
	function compare(a, b) {
	  	const bandA = notifications[a].timestamp.seconds
	  	const bandB = notifications[b].timestamp.seconds
	  	let comparison = 0
	  	if (bandA > bandB) {
	    	comparison = -1
	  	} else if (bandA < bandB) {
	    	comparison = 1
	  	}
	  	return comparison
	}
	Object.keys(notifications).sort(compare).forEach(function(key) {
	  	sorted[key] = notifications[key]
	})
	return sorted
}

export const selectorFilterCategories = (notifications) => {
 	let noteArray = Object.values(notifications) 
 	let list = []
 	noteArray.map(note => {
   	return list.push(note.category.replace(/ /g, '-'))
 	})
 	list = [...new Set(list)]
 	return list
}
// https://zellwk.com/blog/looping-through-js-objects/
// https://alligator.io/js/filter-array-method/