import React from 'react'
import { withRouter } from 'react-router-dom'
import swal from 'sweetalert2'
import Loader from '../../../components/Loader'
import { chkEmail }  from '../../../../includes/functions'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

const roles = {
    Admin: 'This is an admin. Admin have alot of power so choose whisely and even ;more if i mae it wider',
    Manager: 'this is ah manager',
    Editor:'this is ah editor',
    CSR:'this is a csr',
    Attendant:'attendant',
    Sales_Rep:'watta sales rep'
}

function TeamNewMember({history, selectedRole, addTeamMember, setPage}){
	const [roleEl, setRoleEl] = React.useState(null)
	const [loading, setLoading] = React.useState(false)
   const [firstName, setFirstName] = React.useState('')
   const [lastName, setLastName] = React.useState('')
   const [email, setEmail] = React.useState('')
   const [role, setRole] = React.useState(selectedRole)
   const [message, setMessage] = React.useState('')
  	const [complete, setComplete] = React.useState(false)
   
   const handleChange = (set,value) => {
   	setMessage('')
   	set(value)
   }
   const handleChangeRole = (role) => {
   	setMessage('')
   	setRoleEl(null)
   	setRole(role)
   }
   const handleAddMember = () => {
   	if(firstName === '' || lastName === '' || role === '') {
         setMessage('Sorry, all fields are required.')
         return false
      }
      if (!chkEmail(email)) {
			setMessage('Please enter a valid email address.')
			return false 
		}
       
      setLoading(true)
		addTeamMember(firstName,lastName,email,role)
      .then(() => {
      	setLoading(false)
      	setComplete(true)
      })
      .catch((error) => {
      	setLoading(false)
      	setMessage('Error (' + error + ') Please try Again.')
         swal.fire({
            position: 'top-end',
            type: 'error',
            title: 'Error:'+ error,
            showConfirmButton: false,
            timer: 3000
         })
      })
   }
   const addAnotherTeamMember = () => {
   	setFirstName('')
   	setLastName('')
   	setEmail('')
   	setRole('')
   	setMessage('')
   	setComplete(false)
   }
   const handleGoToList = () => {
    	let path = '/group/members/Team/List//?r=' + role
      history.push({ pathname: path })
   }
   return (
   	<React.Fragment>
			<Loader loading={loading} />
	      {(complete) ? (
	      	<Paper style={{ padding: 20, marginTop:20, textAlign:'center'}} >
					<Text variant='h6' gutterBottom>
						TEAM MEMBER ADDED
					</Text>				
					<div>
						<Text style={{padding:25, lineHeight:1.25}} variant='subtitle1' display='block' color='textSecondary'>
							An email was sent to {email} inviting {firstName} {lastName} to join your team as a {role}.
						</Text>
					</div>
					<Button color='primary' variant='outlined' onClick={() => {handleGoToList()}} style={{margin:25}}>
						view team		
					</Button>
					<Button color='secondary' variant='contained' onClick={() => {addAnotherTeamMember()}} style={{margin:25}}>
						add another team members		
					</Button>
				</Paper>
			) : (
	      <div>	
	      	<Paper style={{ marginTop:20, padding: 20 }} >
	            <div style={{ display: 'flex' }}>
	                 <Text variant='h5'>Add Team Member</Text>
	             </div>
	             <Grid container justify='center'>
	             <Grid item xs={12} sm={6} style={{ padding: 5 }}>
	                 <TextField fullWidth
	                     label='First Name'
	                     name='firstName'
	                     value={firstName}
	                     onChange={(e) => {handleChange(setFirstName,e.target.value)}}
	                     margin='normal'
	                 />
	             </Grid>
	             <Grid item xs={12} sm={6} style={{ padding: 5 }}>
	                 <TextField fullWidth
	                     label='Last Name'
	                     name='lastName'
	                     value={lastName}
	                     onChange={(e) => {handleChange(setLastName,e.target.value)}}
	                     margin='normal'
	                 />
	             </Grid>
	             <Grid item xs={12} sm={12} style={{ padding: 5 }}>
	                 <TextField fullWidth
	                     label='Email Address'
	                     name='email'
	                     value={email}
	                     onChange={(e) => {handleChange(setEmail,e.target.value)}}
	                     margin='normal'
	                 />
	             </Grid>
	             <Grid item xs={12} sm={12} style={{ padding: 5 }}>
	                 <List component="nav" style={{backgroundColor:'#f1f1f1', padding:2}}>
	                     <ListItem button onClick={(event) => { setRoleEl(event.currentTarget) }} style={{padding:10}}>
	                         <ListItemText primary={(role) ? 'Role: ' + role.replace(/_/g, ' ') : 'Select Role'} secondary={roles[role] || 'Select Team Member Role'} />
	                     </ListItem>
	                 </List>             
	                 <Menu 
	                     dense='true' 
	                     anchorEl={roleEl}
	                     open={Boolean(roleEl)}
	                     onClose={() => { setRoleEl(null) }}
	                 >
	                     <MenuItem value='Admin' selected={role === 'Admin' } onClick={() => {handleChangeRole('Admin')}}>
	                       <ListItemText style={{whiteSpace: 'normal', maxWidth:400}} primary='Admin' secondary={roles['Admin']} />
	                     </MenuItem>
	                     <MenuItem value='Manager' selected={role === 'Manager' } onClick={() => {handleChangeRole('Manager')}}>
	                       <ListItemText style={{whiteSpace: 'normal'}} primary='Manager' secondary={roles['Manager']} />
	                     </MenuItem>
	                     <MenuItem value='Editor' selected={role === 'Editor' } onClick={() => {handleChangeRole('Editor')}}>
	                       <ListItemText style={{whiteSpace: 'normal'}} primary='Editor' secondary={roles['Editor']} />
	                     </MenuItem>
	                     <MenuItem value='CSR' selected={role === 'CSR' } onClick={() => {handleChangeRole('CSR')}}>
	                       <ListItemText style={{whiteSpace: 'normal'}} primary='CSR' secondary={roles['CSR']} />
	                     </MenuItem>
	                     <MenuItem value='Attendant' selected={role === 'Attendant' } onClick={() => {handleChangeRole('Attendant')}}>
	                       <ListItemText style={{whiteSpace: 'normal'}} primary='Attendant' secondary={roles['Attendant']} />
	                     </MenuItem>
	                     <MenuItem value='Sales_Rep' selected={role === 'Sales_Rep' } onClick={() => {handleChangeRole('Sales_Rep')}}>
	                       <ListItemText style={{whiteSpace: 'normal'}} primary='Sales Rep' secondary={roles['Sales_Rep']} />
	                     </MenuItem>
	                 </Menu>
	            </Grid>
	         </Grid>
	         <div align='center' style={{ height: 25 }}>
	             <Text variant='caption' style={{ color: 'red' }}>
	                 {message}
	             </Text>
	         </div>
	         <div align='right'>
	             <Button variant='contained' color='primary' onClick={() => { handleAddMember() }}>
	                 add team member
	             </Button>
	         </div>
	         </Paper>
	         <div style={{ textAlign:'right', width:'100%', marginTop:10 }}>
	             <Button onClick={() => {handleGoToList()}}>Back to Team</Button>
	         </div>
         </div>
         )}
      </React.Fragment>
   )
}
export default withRouter(TeamNewMember)
