import React from 'react'
import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function DashboardPoints({points}){
   return (
      <Paper style={{ padding: 20, marginTop:20, textAlign:'center'}} >
        	<Text style={{lineHeight:.8}} variant='caption' display='block' color='textSecondary'>
            LoyalTT Points 
         </Text>
      </Paper>
   )
}
export default DashboardPoints
