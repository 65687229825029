import React from 'react'
import { withRouter } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'

function SectionSideNav(props){
   const handleClick = (value) => {
    	let path = '/group/settings/' + value
      props.history.push({ pathname: path })
   }

   const section = props.section
	return (
		<div>
			<List component="nav" 
				style={{backgroundColor:'#fff'}}
				subheader={
		        <ListSubheader component="div">
		          	<i>Group Settings Menu</i>
		        </ListSubheader>
		      }
			>
		   	<ListItem button selected={section === 'Profile'} onClick={() => handleClick('Profile')} >
			 		<ListItemText primary="- Profile" />
		  		</ListItem>
		  		<ListItem button selected={section === 'Plugins'} onClick={(event) => handleClick('Plugins')} >
			 		<ListItemText primary="- Plugins" />
		  		</ListItem>
		  		<ListItem button selected={section === 'Payment-Options'} onClick={(event) => handleClick('Payment-Options')} >
			 		<ListItemText primary="- Payment Options" />
		  		</ListItem>
		  		<ListItem button selected={section === 'Invoices'} onClick={(event) => handleClick('Invoices')} >
			 		<ListItemText primary="- Invoices" />
		  		</ListItem>
		  		<ListItem button selected={section === 'Verification'} onClick={(event) => handleClick('Verification')} >
			 		<ListItemText primary="- Verification" />
		  		</ListItem>
		  		<Divider />
		  		<div align='right'>
		  			<Button onClick={props.sideNavToggle} size='small' style={{color:'#90a4ae'}}>view full menu</Button>
		  		</div>
		  	</List>
		</div>
	)
}
export default withRouter(SectionSideNav)
