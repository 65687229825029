import React from 'react'
import Loader from '../../../components/Loader'
import ImageSelector from '../../../components/ImageSelector'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'

class NewPost extends React.Component {
	state = {
		loading:false,
		name: '',
		message: '',
		currentImage: '/images/posts/default.jpg', // ImageSelector variable (default image)
		images: [] // ImageSelector variable
	}
	
	// [START]: ImageSelector Function :::::::::
	handleSelectImage = (images) => {
		//let images = []
		//if(Array.isArray(src)) {
		//	images = src.filter(image => image !== '/images/posts/default.jpg')
	   //}
		//this.setState({images:images})



		console.log(images)



		
	   // remove default image here first

	   /*
	   let {changed, actionButtonDisabled, currentImage} = this.state
		let changeStatus = changed
		let disabledStatus = actionButtonDisabled
		if (src !== currentImage) {
			changeStatus = true
			disabledStatus = false
		}
		*/
		//console.log(src)
	}
	getImages = (images) => {
		console.log(images)
	}
	// [END]: ImageSelector Function :::::::::::


	handleChange = (e) => {
		this.setState({ [e.target.name]:e.target.value, message:'' })
	}
	handleSubmit = () => {
		this.props.addEditPost(this.state.name)
	}

	render(){
		const {loading, name, images, currentImage} = this.state
//console.log(this.state)
		return (
			<React.Fragment>
				<Loader loading={loading} />
			   <Paper square style={{marginTop:20, padding:10}}>
					NewPost
	
					{/** ImageSelector Starts Here */}
					<div align='center' style={{ maxWidth: 500, margin:'0 auto'}}>
					   <ImageSelector 
							imgBorder='1px solid #ccc'
							imgBorderRadius={10}
							imgPadding={5}
							imgRatio='2:1' 
							images={images}
							currentImage={currentImage}
							selectImage={this.handleSelectImage}
							returnAllImages={true} // set to true for all photos, false for selected photo (not necessary if set to false)
							deleteButton={true} // set to true if you want to show delete button (not necessary if set to false)
						/>
					</div>
					{/** ImageSelector Ends Here */}
					 


					<TextField fullWidth
						label='Post Name'
						name='name'
						value={name}
						onChange={this.handleChange}
						margin='normal'
					/>


					<Button onClick={() => { this.handleSubmit() }}>Submit Post</Button>
				</Paper>
			</React.Fragment>
		)
	}
}
export default NewPost
