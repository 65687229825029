import React from 'react'
import { withRouter } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'

function SectionSideNav(props){
   const handleClick = (value) => {
    	let path = '/group/members/' + value
      props.history.push({ pathname: path })
   }

   const section = props.section
	return (
		<div>
			<List component="nav" style={{backgroundColor:'#fff'}}
				subheader={
		        <ListSubheader component="div" id="nested-list-subheader">
		          	<i>Group Members Menu</i>
		        </ListSubheader>
		      }
			>
		   	<ListItem button selected={section === 'Summary'} onClick={() => handleClick('Summary')}>
			 		<ListItemText primary="- Summary" />
		  		</ListItem>

		  		<ListItem button selected={section === 'Members'} onClick={(event) => handleClick('Members')}>
			 		<ListItemText primary="- Members" />
		  		</ListItem>

				<ListItem button selected={section === 'Lists+Filters'} onClick={(event) => handleClick('Lists+Filters')}>
			 		<ListItemText primary="- Lists & Filters" />
		  		</ListItem>

		  		<ListItem button selected={section === 'Add-Members'} onClick={(event) => handleClick('Add-Members')}>
			 		<ListItemText primary="- Add Members" />
		  		</ListItem>
		  		<Divider style={{height:3}}/>

		  		<ListItem button selected={section === 'Team'} onClick={(event) => handleClick('Team')}>
			 		<ListItemText primary="- Team" />
		  		</ListItem>

		  		<ListItem button selected={section === 'Crews'} onClick={(event) => handleClick('Crews')}>
			 		<ListItemText primary="- Crews" />
		  		</ListItem>

		  		<ListItem button selected={section === 'Notes'} onClick={(event) => handleClick('Notes')}>
			 		<ListItemText primary="- Notes" />
		  		</ListItem>
		  		<Divider />
		  		<div align='right'>
		  			<Button onClick={props.sideNavToggle} size='small' style={{color:'#90a4ae'}}>view full menu</Button>
		  		</div>
		  	</List>
		</div>
	)
}
export default withRouter(SectionSideNav)
