import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import queryString from 'query-string'
import { connect } from 'react-redux'
import { logOut, changeOption, monitorLoginStatus, stopMonitoringLoginStatus } from '../redux/auth/actions'

import PrivateSidenav from './components/PrivateSidenav'
import PrivateTopnav from './components/PrivateTopnav'
import BottomNav from './components/BottomNav'

import MemberAccountSetup from './views-member/account-setup/_Container'
import MemberDashboard from './views-member/dashboard/_Container'
import MemberJoin from './views-member/join/_Container'
import MemberLoyaltt from './views-member/loyaltt-explained/_Container'
import MemberMemberships from './views-member/memberships/_Container'
import MemberNotifications from './views-member/notifications/_Container'
import MemberProfile from './views-member/profile/_Container'
import MemberScanner from './views-member/scanner/_Container'
import MemberSettings from './views-member/settings/_Container'
import MemberShare from './views-member/share/_Container'
import MemberSupport from './views-member/support/_Container'

import GroupCommunicate from './views-group/communicate/_Container'
import GroupDashboard from './views-group/dashboard/_Container'
import GroupEvents from './views-group/events/_Container'
import GroupMembers from './views-group/members/_Container'
import GroupScanner from './views-group/scanner/_Container'
import GroupSettings from './views-group/settings/_Container'
import GroupSupport from './views-group/support/_Container'

class LayoutPrivate extends React.Component {
	state = { sideNavOpen: false }
		
	componentDidMount = () => {
		this.props.monitorLoginStatus()
	}
	componentWillUpdate = (prevProps) => {
		if (this.props !== prevProps) {
			let location = this.props.location.pathname
			let loginId = this.props.loginOptionID
			if(location.startsWith('/group') && loginId === 0){
				this.props.history.push({ pathname: '/member/dashboard' })
			}
			if(location.startsWith('/member') && loginId !== 0){
				this.props.changeOption(0)
			}
		}
	}
	componentWillUnmount = () => {
		this.props.stopMonitoringLoginStatus()
	}
	sideNavToggle = () => {
		this.setState({sideNavOpen: !this.state.sideNavOpen})
	}
	handleOptionChange = (option) => {
		this.props.changeOption(option)
		let page = (option > 0) ? '/group/dashboard' : '/member/dashboard'
		this.props.history.push({ pathname: page })
	}
	render() {
		let qs = queryString.parse(this.props.location.search)
		// rURL = the url to return to after signin
		let rURL = (qs.rURL) ? qs.rURL : null
		// setup = user login/register through email, so take them to setup page to set password
		let setup = (qs.lttSetup) ? true : false

		if (setup) {
			return <Redirect to={'/member/setup?nextURL=' + rURL} />
		}
		if (rURL) {
			return <Redirect to={rURL} />
		}

		// if data not loaded return null
		if (!this.props.selectedOption) {
			return null
		}

		// data loaded so show site
		return (
			<React.Fragment>
				<PrivateTopnav 
					options={ this.props.loginOptions}
					selectedOption={ this.props.selectedOption }
					sideNavToggle={ this.sideNavToggle }
					handleOptionChange={ this.handleOptionChange }
					logOut={ this.props.logOut }
				/>
				<nav>
					<PrivateSidenav 
						sideNavToggle={ this.sideNavToggle } 
						sideNavOpen={ this.state.sideNavOpen } 
						selectedOption={ this.props.selectedOption } 
					/>
				</nav>
				<main>
					<Switch>
						<Route path='/member/loyaltt/:page?/:count?/:sort?/:filter?/:docId?' component={MemberLoyaltt}/>
						<Route path='/member/settings' component={MemberSettings}/>
						<Route path='/member/setup' component={MemberAccountSetup}/>
						<Route path='/member/share' component={MemberShare}/>
						<Route path='/member/profile/:section?' render={props => <MemberProfile {...props} sideNavToggle={ this.sideNavToggle } />}/>
						<Route path='/member/memberships/:section?/:groupId?' render={props => <MemberMemberships {...props} sideNavToggle={ this.sideNavToggle } />}/>
						<Route path='/member/support/:section?/:ticketId?' render={props => <MemberSupport {...props} sideNavToggle={ this.sideNavToggle } />}/>
						<Route path='/member/notifications/:section?/:notificationId?' render={props => <MemberNotifications {...props} sideNavToggle={ this.sideNavToggle } />}/>
						<Route path='/member/dashboard/:section?/:filter?/:groupId?' render={props => <MemberDashboard {...props} sideNavToggle={ this.sideNavToggle } />}/>
						<Route path='/member/scanner' component={MemberScanner}/>
						<Route path='/member/join/:page/:id?' component={MemberJoin}/>
						<Redirect from='/member' to='/member/dashboard' />
						
						<Route path='/group/dashboard' component={GroupDashboard}/>                 
						<Route path='/group/settings/:section?' render={props => <GroupSettings {...props} sideNavToggle={ this.sideNavToggle } />} />
						<Route path='/group/members/:section?/:filter?/:memberId?' render={props => <GroupMembers {...props} sideNavToggle={ this.sideNavToggle } />}/>
						<Route path='/group/support/:section?/:filter?/:ticketId?' render={props => <GroupSupport {...props} sideNavToggle={ this.sideNavToggle } />}/>
						<Route path='/group/communicate/:section?/:filter?/:id?' render={props => <GroupCommunicate {...props} sideNavToggle={ this.sideNavToggle } />}/>
						<Route path='/group/scanner' component={GroupScanner}/>

						<Route path='/group/events/new-event' component={GroupEvents}/>
						<Route path='/group/events/:page?/:count?/:sort?/:eventId?' component={GroupEvents}/>
						<Redirect from='/group' to='/group/dashboard' />
					</Switch>
				</main>
				<BottomNav selectedOption={ this.props.selectedOption } />
			</React.Fragment>
		)
	}
}
const mapStateToProps = (state) => ({
	loginOptions: state.auth.loginOptions,
	loginOptionID: state.auth.loginOptionID,
	selectedOption: state.auth.loginOptions[state.auth.loginOptionID]
})
const mapDispatchToProps = {
	logOut,
	changeOption,
	monitorLoginStatus,
	stopMonitoringLoginStatus
}
export default connect(mapStateToProps, mapDispatchToProps)(LayoutPrivate)
