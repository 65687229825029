import React from 'react'

import Paper from '@material-ui/core/Paper'
import Text from '@material-ui/core/Typography'

function GroupSettingsEdit() {
	return (
		<Paper style={{ padding: 20, marginTop:20, marginBottom:150 }} >
			<Text variant='h6' gutterBottom>
				PLUGINS
			</Text>
			<Text variant='caption' gutterBottom>
				<b>Installed Plugins</b><br/>
				- list of available plugins and a summary of each<br/>
				- also install or uninstall plugins
			</Text>
		</Paper>
	)
}
export default GroupSettingsEdit
